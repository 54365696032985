import React, { useState } from 'react'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  ordersAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { BORDER_INPUT, SECOND_SURFACE, TEXT_GRAY_LIGHT, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, valueForTable, roundToSigFigs } from '../../logic/u'
import { EELog } from '../../types/user_types'
import { LogModalWatchlistsTab } from './log_modal_components/LogModalWatchlistsTab'
import { LogModalConsoleOutputTab } from './log_modal_components/LogModalConsoleOutputTab'
import { LogModalStateTab } from './log_modal_components/LogModalStateTab'
import { LogModalCodeTab } from './log_modal_components/LogModalCodeTab'
import { PositionsTable } from '../tables/PositionsTable'
import { BalancesTable } from '../tables/BalancesTable'

const TOP_SUBTABS = [
  {display: 'Console output', value: 'console_output'},
  {display: 'Code', value: 'code'},
]

const SUBTABS = [
  {display: 'Positions', value: 'positions'},
  {display: 'Balances', value: 'balances'},
  {display: 'Watchlists', value: 'watchlists'},
]

interface LogModalProps {
  log: EELog
}

export const LogModal = (props: LogModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ topSubTab, setTopSubTab ] = useState('console_output')
  const [ subTab, setSubTab ] = useState('positions')

  const { log } = props


  let topSubTabContent: any = null
  switch (topSubTab) {
    case 'console_output':
      topSubTabContent = <LogModalConsoleOutputTab log={log} />
      break
    case 'code':
      topSubTabContent = <LogModalCodeTab log={log} />
      break          
  }

  let subTabContent: any = null
  switch (subTab) {
    case 'positions':
      subTabContent = <PositionsTable log={log} />
      break
    case 'balances':
      subTabContent = <BalancesTable log={log} />
      break          
    case 'watchlists':
      subTabContent = <LogModalWatchlistsTab log={log} />
      break
  }

  if (!user || !uld) return null

  const date = new Date(log.time_iso)

  return (
    <>
      <div
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />      
      <div
        className='modal'
        style={{
          height: 675,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        <div className='top'>
          <div className='title-row'>
            <div className='row'>
              <div>{'Log'}</div>              
              <div
                className='row font-size-10'
                style={{
                  marginTop: 6.5,
                  marginLeft: 25,
                  color: TEXT_GRAY_LIGHT
                }}
              >
                <div>
                  {formatDateForTable(date)}
                </div>                
              </div>
            </div>           
          </div>
          <div className='modal-body'>
            <div className='row width-full' style={{height: 340, gap: 10}}>
              <div className='row flex-grow'>
                <div className='column width-full'>
                  <div className='modal-tab-container top-section'>
                    {TOP_SUBTABS.map((s) => {
                      let className = 'modal-tab'
                      if (s.value === topSubTab) {
                        className += ' selected'
                      }
                      return (
                        <div
                          className={className}
                          onClick={() => setTopSubTab(s.value)}
                        >
                          {s.display}
                        </div>
                      )
                    })}
                  </div>
                  {topSubTabContent}
                </div>
              </div>
              <div className='column' style={{width: 250}}>
                <div className='white-text bold margin-bottom-10'>State</div>
                <LogModalStateTab log={log} />
              </div>
            </div>
            <div className='column flex-grow'> 
              <div className='modal-tab-container'>
                {SUBTABS.map((s) => {
                  let className = 'modal-tab'
                  if (s.value === subTab) {
                    className += ' selected'
                  }
                  return (
                    <div
                      className={className}
                      onClick={() => setSubTab(s.value)}
                    >
                      {s.display}
                    </div>
                  )
                })}
              </div>
              <div className='column' style={{height: 215}}>
                {subTabContent}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}