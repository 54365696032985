import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../../types/global_types'
import { getConfig } from '../../../config'
import { TickerDropdown } from '../../dropdowns/TickerDropdown'
import { TickerModal } from '../TickerModal'
import { CustomGridOverlay } from '../../tabs/CustomGridOverlay'
import { EELog } from '../../../types/user_types'
import { LogRow } from '../../reusable/LogRow'
const config = getConfig() as any

interface LogModalConsoleOutputTabProps {
  log: EELog
}

export const LogModalConsoleOutputTab = (props: LogModalConsoleOutputTabProps) => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const {log} = props

  if (!user || !uld) return null

  return <>
    <div className='generic-content-container modal-version'
      style={{        
        overflowY: 'scroll'
      }}
    >
      <LogRow log={log} forModal={true} />
    </div>
  </>
}