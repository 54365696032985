

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { returnPaneContextObj, set_open_pane, updateLiveData, uuid } from '../../logic/u'
import { liveDataAtom, loggedInUserAtom, openModalAtom, strategiesAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { db } from '../../firebase'
import { Strategy } from '../../types/user_types'

interface DeleteStrategyModalProps {
  strategy: Strategy
}

export const DeleteStrategyModal = (props: DeleteStrategyModalProps) => {
  const {strategy} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(liveDataAtom)
  const [strategies, setStrategies] = useAtom(strategiesAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  if (!user || !strategy) return null

  return (
    <Modal
      title={'Delete strategy?'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className=''>
            The strategy <span className='bold'>{strategy.name}</span> will be permanently deleted.
          </div>
        </div>
      ]}
      yesButtonText={'Delete'}
      twoStepConfirmation={true}
      isDanger
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)
          const userId = user.uid
          const strategyId = strategy.id

          // Delete the strategy
          await db.collection('users')
            .doc(userId)
            .collection('strategies')
            .doc(strategyId)
            .delete()
            .then(() => {
              console.log('Strategy document successfully deleted!');
            })
            .catch((error) => {
              setOpenModal(<ErrorModal errorMessage={error}/>)
              console.error('Error deleting strategy document:', error);
            });

          // Remove corresponding watchlist
          let correspdondingWatchlist = user.watchlists.find(w => w.strategyId === strategyId)
          let currentWatchlists = user.watchlists || []
          const newWatchlists = currentWatchlists.filter(w => w.strategyId !== strategyId)
          await db.collection('users').doc(user.uid).update({ watchlists: newWatchlists })

          // Set tabs to new watchlist
          const targetId = newWatchlists[0].id || newWatchlists.find(w => w.isDefault)?.id || ''
          if (user.selectedWatchlistIdHomeTab === correspdondingWatchlist?.id) {
            await db.collection('users').doc(user.uid).update({ selectedWatchlistIdHomeTab: targetId })
          }          

          const paneThing = 'strategy.' + strategyId
          const paneContextObj = returnPaneContextObj(user, paneThing)  
          for (const paneNo of paneContextObj?.panesWherePaneThingIsVisible || []) {
            set_open_pane(user, uld, paneNo, null)
          }   

          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

