import { useAtom } from 'jotai'
import {
  deploymentsAtom,
  loggedInUserAtom,
  openModalAtom,
  strategiesAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
  Checkbox,
  Button,
} from '../reusable'
import React, { useState, useEffect, useMemo } from 'react'
import { DividerPaneIcon } from '../reusable/DividerPaneIcon'
import { TEXT_GRAY, updateLiveData } from '../../logic/u'
import { Deployment, Strategy } from '../../types/user_types'
import { ErrorModal } from '../modals/ErrorModal'
import { CreateDeploymentModal } from '../modals/CreateDeploymentModal'
import { TakeDownDeploymentModal } from '../modals/TakeDownDeploymentModal'

interface DeploymentDropdown {
  top: number
  left: number
  onClose: () => void
}

export const DeploymentDropdown = (props: DeploymentDropdown) => {
  const [user ] = useAtom(loggedInUserAtom)
  const {left, top, onClose} = props
  const [deployments, setDeployments] = useAtom(deploymentsAtom)
  const [strategies, setStrategies] = useAtom(strategiesAtom)
  const [strategiesToDeploy, setStrategiesToDeploy] = useState<{[key: Strategy['id']]: boolean}>({})
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const liveCode = deployments.find(d => d.id === user?.liveDeploymentId)?.liveCode || {}

  if (!user) return null

  const contents = useMemo(() => {
    const contentsArr = [
      {
        display: (
          <div className='font-size-10 gray-text'>
            Choose strategies to deploy
          </div>
        )
      },
      ...strategies.map(s => {

        const includedInLiveCode = Object.keys(liveCode).includes(s.id)
        const outOfSync = includedInLiveCode && liveCode[s.id] !== strategies.find(s => s.id)?.code
        return {
          display: (
            <div className={'left space-between'}>
              <div 
                className='row pointer'
                onClick={() => {
                  setStrategiesToDeploy({
                    ...strategiesToDeploy,
                    [s.id]: !strategiesToDeploy[s.id],
                  });
                }}
              >
                <Checkbox 
                  // checkMarginTop={1}
                  value={strategiesToDeploy[s.id]}                  
                  onChange={() => {
                    setStrategiesToDeploy({
                      ...strategiesToDeploy,
                      [s.id]: !strategiesToDeploy[s.id],
                    });
                  }}
                />
                <div
                  style={{
                    marginLeft: 7,
                    fontSize: 11
                  }}
                  className='add-ellipses-cutoff'
                >
                  {s.name}
                </div>
              </div>
              <div className='row'>
                {outOfSync && <Icon 
                  hoverText='Deployed code is out of sync from current code.'
                  icon='rotate-exclamation'
                  size={14}
                  style={{marginRight: 5}}
                  onClick={() => {
                    // for effect
                  }}
                  />}
                {includedInLiveCode && <Icon 
                  hoverText='Included in latest deployment.'
                  icon='circle-check'
                  size={14}
                  onClick={() => {
                    // for effect
                  }}
                  style={{}}
                />}
              </div>
            </div>
          )
        }
      }),
      (user.liveDeploymentId && Object.keys(strategiesToDeploy)?.filter(s => strategiesToDeploy[s])?.length ? {
        display: (
          <div className='font-size-10 red-text'>
            This will replace your live deployment.
          </div>
        )
      } : null),
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginBottom: 0}}
          ></div>
        )
      },       
      {
        display: (
          <div className={'left'}>
            <Button 
              display='Deploy selected strategies'
              isDisabled={!Object.keys(strategiesToDeploy)?.filter(s => strategiesToDeploy[s])?.length}
              onClick={() => {
                setOpenModal(<CreateDeploymentModal strategiesToDeploy={strategiesToDeploy} />)
              }}
            />              
          </div>
        )
      },
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginBottom: 0}}
          ></div>
        )
      },         
      {
        display: (
          <div className='left space-between'>
            <div className='font-size-10 gray-text add-ellipses-cutoff'>
              Live: {deployments.find(d => d.id === user.liveDeploymentId)?.name || 'No live deployment.'}
            </div>
            {user.liveDeploymentId && <div 
              className='text-button gray font-size-10'
              onClick={() => {
                setOpenModal(<TakeDownDeploymentModal />)
              }}
            >
              TAKE DOWN
            </div>}
          </div>
        )
      },              
    ]
    return contentsArr.filter(Boolean)
  }, [user, strategiesToDeploy])

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={300}
      height={500}
      onClose={() => {onClose()}}
      noArrowKeys={true}
      contents={contents}
    />
  )
}