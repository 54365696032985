import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { TickerModal } from '../modals/TickerModal'
import { Backtest } from '../../types/backtest_types'
import { CustomGridOverlay } from '../tabs/CustomGridOverlay'
const config = getConfig() as any

interface BacktestWatchlistsTabProps {
  backtest: Backtest | null
}
export const BacktestWatchlistsTab = (props: BacktestWatchlistsTabProps) => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'symbol',
      sort: 'asc' as GridSortDirection
    },
  ]);

  const { backtest } = props

  if (!user || !uld || !backtest) return null

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  }

  const watchlists = backtest.watchlists || []

  return (
    <div className='tab-contents has-subtab'>
      {openDropdown}
      
      <div className='table-container'>
        <DataGridPro
          // className='clickable-grid'
          rows={watchlists}          
          columns={[
            {
              field: 'name',
              headerName: 'name',
              flex: .4,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'left'
                  }}                  
                >
                  {params.row.name}
                </div>
              )
            },
            {
              field: 'tickers',
              headerName: 'tickers',
              flex: 1,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    padding: 10,
                    textAlign: 'left'
                  }}                  
                >
                  {params.row.tickers.join(', ')}
                </div>
              )
            },      
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          components={{
            NoRowsOverlay: () => {              
              return (
                <CustomGridOverlay text='No watchlists.' />
              )
            },
          }}
          sx={{
            ...tableSX,
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { p: '0px' },
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total watchlists:',
          }}
        />
      </div>
    </div>
  )
}