import { useAtom } from 'jotai'
import {
  liveDataAtom,
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { TEXT_GRAY, returnPaneContextObj, set_open_pane } from '../../logic/u'
import { db } from '../../firebase'

interface TableDropdownProps {
  left: number
  top: number
  width: number
  onClose: () => void
  paneIndex?: number
  table?: string
}

export const TableDropdown = (props: TableDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const {left, top, width, onClose, paneIndex} = props
  const [uld] = useAtom(liveDataAtom)


  if (!user) return null

  interface DropdownItem {
    display: JSX.Element
    isDisabled?: boolean
    onClick?: () => void
  }
  let contents: DropdownItem[] = []

  if (user) {
    if (returnPaneContextObj(user, 'table.' + props.table)?.paneThingIsVisible) {
      contents.push(        
        {
          display: (
            <div className='left'>
              <Icon
                icon='circle-x'
                set='sharp-solid'
                size={9}
                style={{marginRight: 10}}
              />
              <div>Clear pane</div>
            </div>
          ),
          onClick: () => {
            if (paneIndex) {
              set_open_pane(user, uld, paneIndex, null);
            }
          }
        },
      )
    }
  }
  
  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}