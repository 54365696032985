// Probably improve this at some point

export const format_date_for_minichart = (timestamp: number, min_interval: string): string => {
  const date = new Date(timestamp);

  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    month: 'numeric',
    day: 'numeric',
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    hour: 'numeric',
    hour12: true
  };

  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
  const timeFormatter = new Intl.DateTimeFormat('en-US', timeOptions);

  const [{ value: month }, , { value: day }] = dateFormatter.formatToParts(date);
  const [{ value: hour }, , { value: period }] = timeFormatter.formatToParts(date);

  const a_p = period.toLowerCase() === 'am' ? 'a' : 'p';

  if (min_interval === '1m') {
    return `${month}/${day} ${hour}${a_p}`;
  } else if (min_interval === '5m') {
    return `${month}/${day} ${hour}${a_p}`;
  } else if (min_interval === '30m') {
    return `${month}/${day} ${hour}${a_p}`;
  } else if (min_interval === '1h') {
    return `${month}/${day} ${hour}${a_p}`;
  }
  return `${month}/${day}`;
};
