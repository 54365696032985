import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../../types/global_types'
import { EELog } from '../../../types/user_types'

interface LogModalStateTabProps {
  log: EELog
}
export const LogModalStateTab = (props: LogModalStateTabProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  if (!user || !uld) return null

  const { log } = props

  if (!log._liveData || !log._liveData.STATE) return null

  const variableKeys = Object.keys(log._liveData.STATE).sort((a, b) => log._liveData.STATE[a].index - log._liveData.STATE[b].index) || []

  return (
    <div className='generic-content-container modal-version'>
      {/* <div className='generic-title'>State</div> */}
      <div className='row'>
        <div className='generic-content-column narrower'>
          {variableKeys.length ? variableKeys.map(v => {
            const dispaly = v
            const value = log._liveData.STATE[v]
            return <div className='generic-content-item'>
              <div
                style={{
                  maxWidth: 155,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {dispaly}
              </div>
              <div>{formatNumberForTable(value)}</div>
            </div>
          }) : <div>-</div>}
        </div>
      </div>
    </div>
  )
}