import axios  from 'axios'
import React, { useState, useEffect } from 'react'
import { currentUser } from '../../firebase'
import { Modal, showModal, Checkbox, Switch } from '../reusable'

import { getConfig } from '../../config'
import { ErrorModal } from './ErrorModal'
import { useAtom } from 'jotai'
import {
  openModalAtom, 
} from '../../types/global_types'
const config = getConfig() as any

export const DeleteAchRelationshipModal = ({fetchBankingRelationshipData}) => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)

  return (
    <Modal
      title='Delete banking relationship?'
      contents={[
        <div className='column width-full font-size-13 orange-text'>          
          <div className=''>
            Please note that existing transfers to your current banking relationship will process unless they are canceled, and you will not be able to initiate transfers into or out of Tickerbot until a new relationship is established.      
          </div>
        </div>
      ]}
      yesButtonText='Delete relationship'
      isDanger
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const deleteAchRelationshipUrl = `${config.api_root_url}deleteAchRelationship`
          await axios.delete(deleteAchRelationshipUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          await fetchBankingRelationshipData()
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (err) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={err}/>)
        }
      }}
    />
  )
}