import React, { useState, useEffect } from 'react'
import { Modal, Input, SliderToggle, Icon, showModal, DropdownSelect, CurrencyInput } from '../reusable'
import axios  from 'axios'
import { currentUser } from '../../firebase'
import { getConfig } from '../../config'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  backtestsAtom,
  strategiesAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { ErrorModal } from './ErrorModal'
import firebase from 'firebase/app';
import { BORDER_INPUT, INTERVALS, TEXT_GRAY, updateLiveData, valueForTable } from '../../logic/u'
import { get_empty_bars } from '../TBChart/chart_utils'
import { db } from '../../firebase'
import { Backtest } from '../../types/backtest_types'
import { Strategy } from '../../types/user_types'
import { min } from 'lodash'
const config = getConfig() as any

const EVALUATIONS_LIMIT = 20000

interface CreateBacktestModalProps {
  strategyId?: Strategy['id']
}

export const CreateBacktestModal = (props: CreateBacktestModalProps) => {
  const { strategyId } = props
  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  const [name, setName] = useState<string>('')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [initialCapital, setInitialCapital] = useState<number>(0)
  const [estimatedEvals, setEstimatedEvals] = useState<number>(0)

  const [strategies, ] = useAtom(strategiesAtom)
  const [backtests, setBacktests] = useAtom(backtestsAtom)
  const [selectedStrategy, setSelectedStrategy] = useState<Strategy['id']>(strategyId ? strategyId : '')

  const strategyOptions = strategies.map(s => {
    return {
      display: s.name,
      value: s.id
    }
  })

  // // Trigger type and interval
  // useEffect(() => {
  //   const initialTriggerTypeObj = {}
  //   const initialIntervalObj = {}
  //   user?.functions.forEach(f => {
  //     initialTriggerTypeObj[f.id] = 'interval'
  //     initialIntervalObj[f.id] = '1m'
  //   })
  //   // setTriggerTypeObj(initialTriggerTypeObj)
  //   setIntervalObj(initialIntervalObj)
  // }, [])

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if name is unique
    let conflictingBacktests: Backtest[] = []
    conflictingBacktests = backtests?.filter(b => b.name === name) || []
    if (conflictingBacktests.length) {
      errorMessage = 'Backtest name is being used'
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Backtest name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Backtest name must not start with a number'
    }
  }

  // Button enabled
  useEffect(() => {
    setButtonEnabled(!!name && !!selectedStrategy && !!startDate && !!endDate && !errorMessage)
  }, [name, selectedStrategy, startDate, endDate, estimatedEvals, errorMessage]);


  // Estimated evaluations
  useEffect(() => {
    // let est_evaluations_formatted = '-'
    if (startDate && endDate) {
      const start_iso = new Date(startDate).toISOString()
      const end_iso = new Date(endDate).toISOString()

      // let min_timeframe = '1d'
      // let min_timeframe_ms = 1000 * 60 * 60 * 24

      // Find the minimum interval from function_metadata
      const strategyObj = strategies.find(s => s.id === selectedStrategy)
      if (!strategyObj) return
      // strategyObj.function_metadata.forEach(f => {
      //   const interval = f.params.interval
      //   const int_ms = INTERVALS[interval]
      //   if (int_ms < min_timeframe_ms) {
      //     min_timeframe = interval
      //     min_timeframe_ms = int_ms
      //   }
      // })

      const min_timeframe = strategyObj.min_interval

      // Get and count empty bars, given start, end, and timeframe
      const empty_bars = get_empty_bars({
        timeframe: min_timeframe,
        limit: 1000000,
        start: start_iso,
        end: end_iso,
      })
      const num_bars = empty_bars.length
      setEstimatedEvals(num_bars)

      if (estimatedEvals > EVALUATIONS_LIMIT) {
        errorMessage = (`Backtests are limited to ${EVALUATIONS_LIMIT.toLocaleString()} evaluations. Please select a larger interval or a shorter time period.`)
      } else if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
          errorMessage = 'End date must be later than start date'
        } else {
          errorMessage = ''
        }
      } else {
        errorMessage = ''
      }
    }
  // }, [startDate, endDate, intervalObj])
  }, [startDate, endDate])

  if (!user || !uld) return null


  // Estimated evaluations display
  let est_evaluations_formatted = '-'
  if (estimatedEvals > 0) {
    est_evaluations_formatted = estimatedEvals.toLocaleString()
  }

  return (
    <Modal
      title={'Run backtest'}
      contents={[
        <div className='column width-full'>
          {<div className='row space-between'>
            <DropdownSelect
              label='Strategy'
              options={strategyOptions}
              value={strategyId}
              onChange={(val) => {
                setSelectedStrategy(val)
              }}
              width={362}
              autoFocus={strategyId ? false : true}
            />
          </div>}

          <div className={'row space-between margin-top-20'}>
            <Input
              label='Name'
              type='text'
              onChange={(val) => {
                let modified = val.replace(/\s/g, '_').toLowerCase().trim();
                modified = modified.slice(0, 50);
                setName(modified);
              }}
              style={{
                width: '100%'
              }}
              autoFocus={strategyId ? true : false}
            />
          </div>


          {name && <div className='under-input-label'>
            {'Compiled: ' + name}
          </div>}


          <div className='row space-between margin-top-20'>
            <Input
              label={'Start date'}
              type={'date'}
              maxDate={new Date().toISOString().split('T')[0]}
              onChange={(val) => setStartDate(val)}
              style={{
                width: 'calc(50% - 5px)'
              }}
            />
            <Input
              label={'End date'}
              type={'date'}
              minDate={startDate ? startDate : undefined}
              key={`end-date-${startDate}`}     // associated with above
              maxDate={new Date().toISOString().split('T')[0]}
              onChange={(val) => {
                if (new Date(val) > new Date()) {
                  val = new Date().toISOString().split('T')[0]
                }
                setEndDate(val)
              }}
              style={{
                width: 'calc(50% - 5px)',
              }}
            />
          </div>

          <CurrencyInput
            className={'margin-top-20'}
            label='Initial capital'
            width={380}
            onChange={(val) => setInitialCapital(val ? val : 0)}
          />
          <div className='divider-green margin-bottom-20' style={{marginTop: 22}}></div>
          <div className='column width-full font-size-11 orange-text'>
            <div className='row space-between'>
              <div className=''>Est. evaluations</div>
              <div className=''>{est_evaluations_formatted}</div>
            </div>

            {errorMessage && <div className='row space-between margin-top-10 red-text'>
              {errorMessage}
            </div>}
          </div>
        </div>
      ]}
      yesButtonText='Run'
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={true}
      onYes={async () => {
        try {
          setButtonIsLoading(true)

          // Find the relevant strategy object
          const strategyObj = strategies.find(s => s.id === selectedStrategy)
          if (!strategyObj) {
            throw new Error('Strategy not found')
          }
          await create_and_run({
            user,
            name,
            startDate,
            endDate,
            strategyObj,
            initialCapital,
            backtests
          })

          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal( <ErrorModal errorMessage={error.message}/> )
        }
        updateLiveData(user)
      }}
    />
  )
}

interface CreateAndRunParams {
  user: any
  name: string
  startDate: string
  endDate: string
  strategyObj: Strategy
  initialCapital: number
  backtests: Backtest[]
}
async function create_and_run(params: CreateAndRunParams) {

  // Start and end dates default to the beginning and end of the day
  const { name, startDate, endDate, strategyObj, initialCapital, user } = params
  const start_time_iso = new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString()
  const end_time_iso = new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString()

  let highestIndex = 0
  params.backtests.forEach(b => {
    if (b.index > highestIndex) {
      highestIndex = b.index
    }
  })

  // const min_interval = get_min_interval_from_strategy(strategyObj)
  const min_interval = strategyObj.min_interval


  // Identify timeframes from calls to get_bars
  const two_part_symbols = get_two_part_symbols(strategyObj)

  // Select relevant watchlists: the one for this strategy, any referenced directly

  //


  // Create a backtest object under the user
  try {
    const backtest = {
      name,
      userId: user.uid,
      start_time_iso,
      end_time_iso,
      strategyObj,
      min_interval,
      tickers: strategyObj.tickers,
      symbols: two_part_symbols,
      watchlists: get_relevant_watchlists(strategyObj.id, user.watchlists, strategyObj.code),
      initialCash: initialCapital,
      status: 'new',
      index: highestIndex + 1
      // createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    const newBacktestRef = await db.collection('users').doc(user.uid).collection('backtests').add(backtest)
    const backtest_id = newBacktestRef.id

    // Now update the strategy object with the generated id
    const backtestWithId = {
      ...backtest,
      id: backtest_id,
    }

    await db.collection('users')
      .doc(user.uid)
      .collection('backtests')
      .doc(backtest_id)
      .set(backtestWithId) // if you need to store the id


    db.collection('users').doc(user.uid).update({selectedBacktestId: backtest_id})

    // Call initializeBacktest
    const token = await currentUser()?.getIdToken();
    await axios.post(`${config.api_root_url}initializeBacktest`, {
      uid: user.uid,
      backtestId: backtest_id
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

  } catch (error) {
    console.log(error)
  }
}


/*
Produces relevant array like "NVDA-1h" for all tickers, based on `tickers`,
`min_interval`, and calls to `get_bars`
*/
function get_two_part_symbols(strategyObj) {
  const result: string[] = [];

  // 1. Initialize with TICKER-MIN_INTERVAL and TICKER-1d for all tickers
  strategyObj.tickers.forEach(ticker => {
    result.push(`${ticker}-${strategyObj.min_interval}`);
    result.push(`${ticker}-1d`); // We need day bars for everything
  });

  // 2. Build a mapping from variables to tickers
  const variableToTickerMap = {};

  // Pattern to find assignments like: const VAR_NAME = tb.ticker('SYMBOL')
  const variableAssignmentRegex = /const\s+(\w+)\s*=\s*tb\.ticker\(\s*['"](\w+)['"]\s*\)/g;

  let match;
  while ((match = variableAssignmentRegex.exec(strategyObj.code)) !== null) {
    const variableName = match[1];
    const tickerSymbol = match[2];
    variableToTickerMap[variableName] = tickerSymbol;
  }

  // 3. Find direct calls: tb.ticker('SYMBOL').get_bars('INTERVAL', ...)
  const directGetBarsRegex = /tb\.ticker\(\s*['"](\w+)['"]\s*\)\.get_bars\(\s*['"]([\w\d]+)['"]/g;

  while ((match = directGetBarsRegex.exec(strategyObj.code)) !== null) {
    const tickerSymbol = match[1];
    const interval = match[2];
    const symbolInterval = `${tickerSymbol}-${interval}`;
    if (!result.includes(symbolInterval)) {
      result.push(symbolInterval);
    }
  }

  // 4. Find variable calls: VARIABLE.get_bars('INTERVAL', ...)
  const variableGetBarsRegex = /(\w+)\.get_bars\(\s*['"]([\w\d]+)['"]/g;

  while ((match = variableGetBarsRegex.exec(strategyObj.code)) !== null) {
    const variableName = match[1];
    const interval = match[2];
    if (variableToTickerMap[variableName]) {
      const tickerSymbol = variableToTickerMap[variableName];
      const symbolInterval = `${tickerSymbol}-${interval}`;
      if (!result.includes(symbolInterval)) {
        result.push(symbolInterval);
      }
    }
  }

  return result;
}



const get_relevant_watchlists = (
  strategyId: string,
  watchlists: any[],
  code: string
): string[] => {
  const ret: string[] = [];

  // Add the watchlist for this strategy
  watchlists.forEach((wl: any) => {
    if (wl.strategyId === strategyId) {
      ret.push(wl);
    }
  });

  // Define the regex to match tb.watchlists.get('WATCHLIST_NAME')
  const regex = /tb\.watchlists\.get\(['"`]([^'"`]+)['"`]\)/g;

  // Extract all watchlist names from the code
  const matches = code.matchAll(regex);

  for (const match of matches) {
    const watchlistName = match[1];

    // Check if the watchlist exists in the watchlists array
    const watchlist = watchlists.find((wl) => wl.name === watchlistName);

    if (watchlist) {
      // Avoid adding duplicates
      if (!ret.includes(watchlist.name)) {
        ret.push(watchlist);
      }
    }
  }

  return ret;
};
