import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import {
  formatPercent,
  formatNumberForTable,
  get_contextmenu_off,
  formatDateForTable,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { Backtest } from '../../types/backtest_types'
import { DropdownSelect } from '../reusable'
import { BacktestLineChart } from '../minichart/BacktestLineChart'
import { BacktestCandlestickChart } from '../minichart/BacktestCandlestickChart'
import { db } from '../../firebase'
const config = getConfig() as any

const BUILTINS = [
  'equity',
  'cash',
  'mkt_value',
  'unrealized_pl',
  'realized_pl',
  'pl',
  'unrealized_pl_pc',
  'realized_pl_pc',
  'pl_pc',
  'net_deposits',
  'cost_basis'
]

interface BacktestSummaryTabProps {
  backtest?: Backtest | null
}
export const BacktestSummaryTab = (props: BacktestSummaryTabProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedChart, setSelectedChart] = useState<string>('equity')
  const [chartData, setChartData] = useState<any>([])

  if (!user || !uld) return null

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData

  const {backtest} = props

  if (!backtest) return

  useEffect(() => {
    load_chart_data(
      backtest,
      selectedChart,
      (d: any) => setChartData(d)
    )
  }, [selectedChart, backtest])

  const CHART_OPTIONS: any = []


  BUILTINS.forEach((symbol) => {
    CHART_OPTIONS.push({display: symbol, value: symbol})
  })
  backtest.symbols.forEach((symbol) => {
    CHART_OPTIONS.push({display: symbol, value: symbol})
  })

  const is_line_chart = BUILTINS.includes(selectedChart)

  return (
    <div className='tab-contents has-subtab'>

      {/* BacktestChart */}
      <div
        style={{
          height: 400,
          width: '100%',
          // backgroundColor: '#444444',
          marginBottom: 10,
          position: 'relative',
        }}
      >
        <DropdownSelect
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 100,
          }}
          width={200}
          menuHeight={500}
          options={CHART_OPTIONS}
          value={selectedChart}
          onChange={(v) => {
            setSelectedChart(v)
          }}
        />

        {is_line_chart ? <BacktestLineChart data={chartData} /> : null}
        {!is_line_chart ?
          <BacktestCandlestickChart
            data={chartData.data}
            entries={chartData.entries}
            exits={chartData.exits}
          />
        : null}

      </div>


      <div className='generic-content-container'>
        <div className='generic-title'>Overview</div>
        <div className='generic-content-column'>
          <div className='generic-content-item'>
            <div>strategy</div>
            <div>{backtest?.strategyObj?.name || '-'}</div>
          </div>
          <div className='generic-content-item'>
            <div>start_time</div>
            <div>{formatDateForTable(backtest?.start_time_iso) || '-'}</div>
          </div>
          <div className='generic-content-item'>
            <div>end_time</div>
            <div>{formatDateForTable(backtest?.end_time_iso) || '-'}</div>
          </div>
          <div className='generic-content-item'>
            <div>min_interval</div>
            <div>{backtest?.strategyObj?.min_interval || '-'}</div>
          </div>
          <div className='generic-content-item'>
            <div>created_at</div>
            <div>{formatDateForTable(backtest?.createdAt) || '-'}</div>
          </div>
        </div>
      </div>
      <div className='generic-content-container'>
        <div className='generic-title'>Balances</div>
        <div className='generic-content-column'>
          <div className='generic-content-item'>
            <div>cash</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.cash)}</div>
          </div>
          <div className='generic-content-item'>
            <div>mkt_value</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.mkt_value)}</div>
          </div>
          <div className='generic-content-item'>
            <div>equity</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.equity)}</div>
          </div>
          <div className='generic-content-item'>
            <div>unrealized_pl</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.unrealized_pl)}</div>
          </div>
          <div className='generic-content-item'>
            <div>realized_pl</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.realized_pl)}</div>
          </div>
          <div className='generic-content-item'>
            <div>pl</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.pl)}</div>
          </div>
          <div className='generic-content-item'>
            <div>unrealized_pl_pc</div>
            <div>{formatPercent(LD_DATA_SOURCE.BALANCES.unrealized_pl_pc)}</div>
          </div>
          <div className='generic-content-item'>
            <div>realized_pl_pc</div>
            <div>{formatPercent(LD_DATA_SOURCE.BALANCES.realized_pl_pc)}</div>
          </div>
          <div className='generic-content-item'>
            <div>pl_pc</div>
            <div>{formatPercent(LD_DATA_SOURCE.BALANCES.pl_pc)}</div>
          </div>
          <div className='generic-content-item'>
            <div>net_deposits</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.net_deposits)}</div>
          </div>
          <div className='generic-content-item'>
            <div>cost_basis</div>
            <div>{formatNumberForTable(LD_DATA_SOURCE.BALANCES.cost_basis)}</div>
          </div>
        </div>
      </div>
      <div className='generic-content-container'>
        <div className='generic-title'>State</div>
        <div className='generic-content-column'>
          {Object.keys(LD_DATA_SOURCE.STATE).length ? Object.keys(LD_DATA_SOURCE.STATE).sort((a, b) => LD_DATA_SOURCE.STATE[a].index - LD_DATA_SOURCE.STATE[b].index).map(v => {
            const dispaly = v
            const value = LD_DATA_SOURCE.STATE[v]
            return <div className='generic-content-item'>
              <div>{dispaly}</div>
              <div>{formatNumberForTable(value)}</div>
            </div>
          }) : <div className='generic-content-item'>N/A</div>}
        </div>
      </div>
      <div className='generic-content-container'>
        <div className='generic-title'>Misc</div>
        <div className='generic-content-column'>
          <div className='generic-content-item'>
            <div>Positions</div>
            <div>{formatNumberForTable(Object.keys(LD_DATA_SOURCE.POSITIONS).length).split('.')[0]}</div>
          </div>
          <div className='generic-content-item'>
            <div>Orders</div>
            <div>{formatNumberForTable(backtest?.orders?.length).split('.')[0]}</div>
          </div>
          <div className='generic-content-item'>
            <div>Transfers</div>
            <div>{formatNumberForTable(backtest?.transfers?.length).split('.')[0]}</div>
          </div>
          <div className='generic-content-item'>
            <div>Logs</div>
            <div>{formatNumberForTable(backtest.logs?.length).split('.')[0]}</div>
          </div>
          <div className='generic-content-item'>
            <div>Ticks processed</div>
            <div>{formatNumberForTable(backtest.ticks_processed).split('.')[0]}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const load_chart_data = async (backtest: Backtest, selectedChart: string, cb: any) => {

  // Load seriesData from BacktestLogs
  if (BUILTINS.includes(selectedChart)) {
    const snapshot = await db.collection('users')
      .doc(backtest.userId)
      .collection('backtests')
      .doc(backtest.id)
      .collection('BacktestLogs')
      .get()
    const logs: any[] = []
    snapshot.forEach(doc => {
      const log_data = doc.data()
      log_data.ts = new Date(log_data.time_iso).getTime()
      logs.push(log_data)

      // console.log(new Date(log_data.time_iso))
    })

    // Sort by timestamp
    logs.sort((a, b) => a.ts - b.ts)

    const data = logs.map((log) => {
      return [log.ts, log.liveData?.BALANCES[selectedChart]]
    })
    cb(data)
  }

  // Load bars from market_data
  else {
    const snapshot = await db.collection('users')
      .doc(backtest.userId)
      .collection('backtests')
      .doc(backtest.id)
      .collection('market_data')
      .doc(selectedChart)
      .get()

    const data = snapshot.data()
    const processed_bars: any[] = []

    data?.bars.forEach((bar: any) => {
      processed_bars.push([new Date(bar.t).getTime(), bar.o, bar.h, bar.l, bar.c])
    })

    // Place entries and exits
    const entries: any = []
    const exits: any = []
    for (const order of backtest.orders) {
      if (order.status === 'filled') {
        if (order.side === 'buy') {
          const entry = Number(order.filled_avg_price)
          const ts = new Date(order.created_at).getTime()
          // const entry_index = processed_bars.findIndex((bar) => bar[0] === ts)
          // processed_bars[entry_index].push({entry})
          entries.push([ts, entry])
        } else if (order.side === 'sell') {
          const exit = Number(order.filled_avg_price)
          const ts = new Date(order.created_at).getTime()
          exits.push([ts, exit])
        }
      }
    }
    cb({
      data: processed_bars,
      entries,
      exits
    })
  }

}

// RESUME: make absolutely certain that the x axis and the crosshairs are aligned