

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { loggedInUserAtom, openModalAtom, strategiesAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { Strategy } from '../../types/user_types'
import { db } from '../../firebase'

interface DuplicateStrategyModalProps {
  strategy: Strategy
}

export const DuplicateStrategyModal = (props: DuplicateStrategyModalProps) => {
  const {strategy} = props

  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)
  const [strategies, setStrategies] = useAtom(strategiesAtom)
  const [name, setName] = useState<string>(strategy?.name.replace(/\s/g, '_') + '_COPY' || '')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if name is unique
    let conflictingStrategies: Strategy[] = []
    conflictingStrategies = strategies?.filter(s => s.name === name) || []
    if (conflictingStrategies.length) {
      errorMessage = 'Strategy name is being used'
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Strategy name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Strategy name must not start with a number'
    }
  }

  useEffect(() => {
    setButtonEnabled(!!name && !errorMessage)
  }, [name, errorMessage]);

  if (!user || !strategy) return null

  const content = (
    <div className='column width-full'>
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_');
          modified = modified.slice(0, 50);
          setName(modified);
        }}
        autoFocus={true}
      />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}

      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title={'Duplicate strategy'}
      contents={[content]}
      yesButtonText={'Duplicate'}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)

          // New codepath
          let highestIndex = 0
          strategies.forEach(s => {
            if (s.index > highestIndex) {
              highestIndex = s.index
            }
          })
          const newStrategy: Omit<Strategy, 'id'> = { // omit 'id' for now
            ...strategy,            
            createdAt: new Date(),
            updatedAt: new Date(),
            name: name,
            index: highestIndex + 1 // assuming this is your logic for index
          }
          const newStrategyRef = await db.collection('users')
            .doc(user.uid)
            .collection('strategies')
            .add(newStrategy)              

          const strategyId = newStrategyRef.id

          // Now update the strategy object with the generated id
          const strategyWithId: Strategy = {
            ...newStrategy,
            id: strategyId,
          }
          await db.collection('users')
            .doc(user.uid)
            .collection('strategies')
            .doc(strategyId)
            .set(strategyWithId) // if you need to store the id
          
          // Update user's selected strategy
          await db.collection('users')
            .doc(user.uid)
            .update({ selectedStrategyId: strategyId })

          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

