import { useAtom } from 'jotai'
import { loggedInUserAtom, openModalAtom, strategiesWithUnsavedChangesAtom } from '../../types/global_types'
import React, { useState, useEffect, useRef } from 'react'
import { Strategy } from '../../types/user_types'
import { Editor, loader } from '@monaco-editor/react'
import * as monaco from 'monaco-editor'
import { TestFunctionModal } from '../modals/TestFunctionModal'
import { RunFunctionOnceModal } from '../modals/RunFunctionOnceModal'
import { libSource } from '../../logic/lib_source_monaco'

interface CodeEditorProps {
  strategy: Strategy,
}

export const CodeEditor = (props: CodeEditorProps) => {
  const { strategy } = props
  const [editorValue, setEditorValue] = useState<string>(strategy.code)
  const [user] = useAtom(loggedInUserAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const containerRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)
  const viewZoneIdsRef = useRef<string[]>([])
  const [, setOpenModal] = useAtom(openModalAtom)

  useEffect(() => {
    loader.init().then(monacoInstance => {
      // Configure JavaScript validation settings
      monacoInstance.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: false,
        noSyntaxValidation: false,
        diagnosticCodesToIgnore: [], // Add any error codes you want to ignore
      });

      monacoInstance.languages.typescript.javascriptDefaults.addExtraLib(
        libSource,
        'ts:filename/tb.d.ts'
      );

      // Configure JavaScript compilation settings
      monacoInstance.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monacoInstance.languages.typescript.ScriptTarget.ES2020,
        allowNonTsExtensions: true,
        moduleResolution: monacoInstance.languages.typescript.ModuleResolutionKind.NodeJs,
        module: monacoInstance.languages.typescript.ModuleKind.CommonJS,
        noEmit: true,
        esModuleInterop: true,
        jsx: monacoInstance.languages.typescript.JsxEmit.React,
        allowJs: true,
        checkJs: true
      });

      // NEXT UP: do this
      // const obj = {asdasd: 'sdfsdf'}
      // console.log(obj.dne)

      // Filter out error on import line: `import { tb, strategy, portfolio } from 'tb'`
      monacoInstance.editor.onDidChangeMarkers((uris) => {
        uris.forEach(uri => {
          const markers = monacoInstance.editor.getModelMarkers({ resource: uri });
          const filteredMarkers = markers.filter(marker =>
            !marker.message.includes("Cannot find module 'tb'")
            // && !marker.message.includes("Cannot find module 'utils'")
          );

          if (markers.length !== filteredMarkers.length) {
            monacoInstance.editor.setModelMarkers(
              monacoInstance.editor.getModel(uri)!,
              'typescript',
              filteredMarkers
            );
          }
        });
      })

      // Define theme
      monacoInstance.editor.defineTheme('my-theme', {
        base: 'vs-dark',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': '#00000000',
          'minimap.background': '#00000000',
          'minimapSlider.background': '#55555533',
          'minimapSlider.hoverBackground': '#66666633',
          'minimapSlider.activeBackground': '#88888833',
        },
      })
    })

    return () => {
      setEditorValue('')
    }
  }, [])

  useEffect(() => {
    setEditorValue(strategy.code)
    if (editorRef.current) {
      addViewZonesToIntervalCalls(editorRef.current, strategy.code);
    }
  }, [strategy])

  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined) {
      setEditorValue(value)
      setStrategiesWithUnsavedChanges({ ...strategiesWithUnsavedChanges, [strategy.id]: value })
    }
  }

  const addViewZonesToIntervalCalls = (editor: monaco.editor.IStandaloneCodeEditor, code: string) => {
    const model = editor.getModel()
    if (!model) return

    editor.changeViewZones(accessor => {
      viewZoneIdsRef.current.forEach(id => accessor.removeZone(id))
      viewZoneIdsRef.current = []
    })

    const intervalRegex = /\bstrategy\.(on_interval|on_trigger)\(\s*/g
    let match: RegExpExecArray | null

    while ((match = intervalRegex.exec(code)) !== null) {
      const funcType = match[1]
      const startPos = model.getPositionAt(match.index)
      const line = startPos.lineNumber
      if (model.getLineContent(line).trim().startsWith('//') || line < 1) continue

      const domNode = document.createElement('div')
      domNode.style.display = 'flex'
      domNode.style.alignItems = 'center'
      domNode.style.height = '20px'

      const btn = (text: string, xPos: number, onClick: () => void) => {
        const button = document.createElement('button')
        button.innerText = text
        button.style.marginRight = '8px'
        button.style.marginLeft = '0px'
        button.style.paddingLeft = '0px'
        button.style.fontSize = '10px'
        button.style.background = 'transparent'
        button.style.color = '#507b95'
        button.style.border = 'none'
        button.style.cursor = 'pointer'
        button.style.zIndex = '10'
        button.style.position = 'absolute'
        button.style.top = '7px'
        button.style.left = `${xPos}px`
        button.onclick = onClick

        return button
      }

      domNode.appendChild(btn('TEST', 0, () => {
        const fname = model.getLineContent(line).match(/'([^']*)'/)?.[1] || ''
        setOpenModal(<TestFunctionModal strategyObj={strategy} function_name={fname} />)
      }))

      const dot = document.createElement('span')
      dot.innerText = '•'
      dot.style.left = '32px'
      dot.style.top = '3px'
      dot.style.position = 'absolute'
      domNode.appendChild(dot)

      domNode.appendChild(btn('RUN', 48, () => {
        const fname = model.getLineContent(line).match(/'([^']*)'/)?.[1] || ''
        setOpenModal(<RunFunctionOnceModal strategyObj={strategy} function_name={fname} />)
      }))

      const viewZone: monaco.editor.IViewZone = {
        afterLineNumber: line - 1,
        heightInPx: 20,
        domNode,
      }

      editor.changeViewZones(accessor => {
        const id = accessor.addZone(viewZone)
        viewZoneIdsRef.current.push(id)
      })
    }
  }

  if (!user || !strategy) return null

  return (
    <div className="code-editor-container" ref={containerRef}>
      <Editor
        height="100%"
        width="100%"
        theme="my-theme"
        language="javascript"
        value={editorValue}
        onChange={handleEditorChange}
        onMount={(editor) => {
          editorRef.current = editor
          addViewZonesToIntervalCalls(editor, strategy.code)
          editor.onDidChangeModelContent(() => {
            addViewZonesToIntervalCalls(editor, editor.getValue())
          })
        }}
        options={{
          fontSize: 11.5,
          minimap: { enabled: true },
          padding: { top: 0 },
          quickSuggestions: true,
          suggestOnTriggerCharacters: true,
          parameterHints: { enabled: true },
          formatOnPaste: true,
          formatOnType: true,
          // Enable additional validation features
          autoIndent: 'full',
        }}
      />
    </div>
  )
}