

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { formatNumberForTable, set_phantom_log, updateLiveData, uuid, valueForTable } from '../../logic/u'
import {
  loggedInUserAtom,
  openModalAtom,
  transfersAtom,
  phantomLogAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import axios from 'axios'
const config = getConfig() as any

export const CancelAllPendingTransfersModal = () => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ , setPhantomLog ] = useAtom(phantomLogAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [transfers] = useAtom(transfersAtom)

  if (!user || !uld) return null

  return (
    <Modal
      title={'Cancel all transfers?'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className=''>
            All pending transfers will be canceled.
          </div>
        </div>
      ]}
      yesButtonText={'Cancel all'}
      isDanger
      twoStepConfirmation={true}
      isButtonLoading={buttonIsLoading}
      onYes={ async () => {
        try {
          // setButtonIsLoading(true)
          set_phantom_log('Canceling all transfers...', uld, setPhantomLog)
          setOpenModal(null)
          const token = await currentUser()?.getIdToken()
          const pendingTransfers = transfers?.filter(t => ['QUEUED', 'APPROVAL_PENDING', 'PENDING'].includes(t.status)) || []
          for (const transferId of pendingTransfers) {
            const deleteTransferUrl = `${config.api_root_url}deleteTransfer?transferId=${transferId}&create_ee_log=true`
            await axios.delete(deleteTransferUrl, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
          }
          // setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          // setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

