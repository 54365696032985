import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatDateForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { CreateVariableModal } from '../modals/CreateVariableModal'
import { VariableDropdown } from '../dropdowns/VariableDropdown'
import { CustomGridOverlay } from '../tabs/CustomGridOverlay'
import { Backtest } from '../../types/backtest_types'
import { TableDropdown } from '../dropdowns/TableDropdown'
import { Icon } from '../reusable'


interface StateTableProps {
  backtest?: Backtest | null
  width?: string
  height?: string
  paneIndex?: number

}
export const StateTable = (props: StateTableProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'last_set',
      sort: 'desc' as GridSortDirection
    },
  ]);
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)


  if (!user || !uld) return null

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData
  const VARIABLES_DATA_SOURCE = props.backtest ? props.backtest.state : user.variables
  const variableRows: any[] = Object.keys(VARIABLES_DATA_SOURCE || [])?.sort().map(v => {
    const variable = {...VARIABLES_DATA_SOURCE[v], id: uuid()}
    return variable
  })

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  }

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    if (get_contextmenu_off()) return
    event.preventDefault()
    const width = 220
    const clickX = openLeft? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    setOpenDropdown(
      <VariableDropdown
        variableObj={params.row}
        left={clickX}
        top={clickY}
        width={width}
        onClose={() => setOpenDropdown(null)}
      />
    )
  }

  let className = 'table-tab'  

  return (
    <div className={className} style={{width: props.width, height: props.height, minHeight: 0, position: 'relative'}}>
      {openDropdown}
      <div className='tab-header'>
        <div>State table</div>
        <Icon 
          icon='gear'
          set='regular'
          size={14}
          style={{marginLeft: 12, marginTop: 1}}
          onClick={(e) => {      
            const rect = e.target.getBoundingClientRect()
            if (rect) {
              const width= 175
              const left = rect.left - width + 10
              const top = rect.bottom
              setOpenDropdown(<TableDropdown 
                left={left}
                top={top}
                width={width}
                onClose={() => setOpenDropdown(null)}
                paneIndex={props.paneIndex}
                table='state'
              />)
            }
          }}
        />
      </div>
      <div className='table-container'>
        <DataGridPro
          // className='clickable-grid'
          rows={variableRows}
          // getRowHeight={() => 'auto'}
          // getEstimatedRowHeight={() => 36}
          columns={[
            {
              field: 'symbol',
              headerName: 'name',
              flex: 1,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => {
                let full_key = params.row.symbol
                if (params.row.strategy_name) {
                  full_key = `${params.row.strategy_name}.${params.row.symbol}`
                }
                return (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {full_key}
                </div>
              )
              }
            },
            {
              field: 'value',
              headerName: 'value',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => {
                let full_key = params.row.symbol
                if (params.row.strategy_name) {
                  full_key = `${params.row.strategy_name}.${params.row.symbol}`
                }
                const value = LD_DATA_SOURCE.STATE[full_key]
                const formatted_value = valueForTable(full_key, value)
                return (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'right'
                    }}
                    onContextMenu={(event) => {
                      handleContextMenuClick(event, params)
                    }}
                  >
                    {formatted_value}
                  </div>
                )
              }
            },
            {
              field: 'last_set',
              headerName: 'last_set',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row?.last_set?.toDate())}
                </div>
              )
            },
            {
              field: 'created_at',
              headerName: 'created_at',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params, true)
                  }}
                >
                  {formatDateForTable(params.row?.created_at?.toDate())}
                </div>
              )
            }
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          components={{
            NoRowsOverlay: () => (
              <CustomGridOverlay text={variableRows.length === 0 ? 'No variables.' : 'Loading variables...'} />
            ),
          }}
          sx={{
            ...tableSX,
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total variables:',
          }}
          hideFooter={true}
          // onRowClick={(params) => setOpenModal(<CreateVariableModal variable={params.row} />)}
        />
      </div>

    </div>
  )
}


