import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'
import { v4 as uuidv4 } from 'uuid';
import {
  tableSX,
  formatPercent,
  formatNumberForTable,
  BACKGROUND,
  TEXT_GRAY,
  TEXT_GRAY_LIGHT,
  uuid,
  tableSX_Modal
} from '../../logic/u'
import {
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { CustomGridOverlay } from '../tabs/CustomGridOverlay';
import { Backtest } from '../../types/backtest_types';
import { EELog } from '../../types/user_types';
import { TableDropdown } from '../dropdowns/TableDropdown'
import { Icon } from '../reusable';

interface BalancesTableProps {
  backtest?: Backtest | null
  log?: EELog
  width?: string
  height?: string
  paneIndex?: number
}

export const BalancesTable = (props: BalancesTableProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)

  if (!user) return null
  if (!uld) return null

  let LD_DATA_SOURCE = uld.liveData
  if (props.backtest) {
    LD_DATA_SOURCE = props.backtest.liveData
  }
  if (props.log) {
    LD_DATA_SOURCE = props.log._liveData
  }

  const balances = LD_DATA_SOURCE.BALANCES

  const balancesRows = [
    {
      balance: 'cash',
      last_close: formatNumberForTable(balances.last_cash),
      current: formatNumberForTable(balances.cash),
      id: uuid()
    },
    {
      balance: 'cost_basis',
      last_close: formatNumberForTable(balances.last_cost_basis),
      current: formatNumberForTable(balances.cost_basis),
      id: uuid()
    },
    // {
    //   balance: 'long_mkt_value',
    //   last_close: formatNumberForTable(balances.last_long_mkt_value),
    //   current: formatNumberForTable(balances.long_mkt_value),
    //   id: uuid()
    // },
    {
      balance: 'mkt_value',
      last_close: formatNumberForTable(balances.last_mkt_value),
      current: formatNumberForTable(balances.mkt_value),
      id: uuid()
    },
    {
      balance: 'equity',
      last_close: formatNumberForTable(balances.last_equity),
      current: formatNumberForTable(balances.equity),
      id: uuid()
    },
    {
      balance: 'day_equity_change',
      last_close: (row) => (
        <div className={balances.last_day_equity_change >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.last_day_equity_change)}
        </div>
      ),
      current: (row) => (
        <div className={balances.day_equity_change >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.day_equity_change)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'day_equity_change_pc',
      last_close: (row) => (
        <div className={balances.last_day_equity_change_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.last_day_equity_change_pc)}
        </div>
      ),
      current: (row) => (
        <div className={balances.day_equity_change_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.day_equity_change_pc)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'pl',
      last_close: (row) => (
        <div className={balances.last_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.last_pl)}
        </div>
      ),
      current: (row) => (
        <div className={balances.pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.pl)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'pl_pc',
      last_close: (row) => (
        <div className={balances.last_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.last_pl_pc)}
        </div>
      ),
      current: (row) => (
        <div className={balances.pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.pl_pc)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'day_pl',
      last_close: (row) => (
        <div className={balances.last_day_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.last_day_pl)}
        </div>
      ),
      current: (row) => (
        <div className={balances.day_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.day_pl)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'day_pl_pc',
      last_close: (row) => (
        <div className={balances.last_day_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.last_day_pl_pc)}
        </div>
      ),
      current: (row) => (
        <div className={balances.day_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.day_pl_pc)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: (row) => {
        return (
          <div
            style={{
              fontSize: 9,
              color: TEXT_GRAY_LIGHT,
              fontStyle: 'italic',
            }}
          >
            {'Unrealized return'}
          </div>
        )
      },
      id: uuid()
    },
    {
      balance: 'unrealized_pl',
      last_close: (row) => (
        <div className={balances.last_unrealized_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.last_unrealized_pl)}
        </div>
      ),
      current: (row) => (
        <div className={balances.unrealized_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.unrealized_pl)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'unrealized_pl_pc',
      last_close: (row) => (
        <div className={balances.last_unrealized_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.last_unrealized_pl_pc)}
        </div>
      ),
      current: (row) => (
        <div className={balances.unrealized_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.unrealized_pl_pc)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'day_unrealized_pl',
      last_close: (row) => (
        <div className={balances.last_day_unrealized_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.last_day_unrealized_pl)}
        </div>
      ),
      current: (row) => (
        <div className={balances.day_unrealized_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.day_unrealized_pl)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'day_unrealized_pl_pc',
      last_close: (row) => (
        <div className={balances.last_day_unrealized_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.last_day_unrealized_pl_pc)}
        </div>
      ),
      current: (row) => (
        <div className={balances.day_unrealized_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.day_unrealized_pl_pc)}
        </div>
      ),
      id: uuid()
    },      
    {
      balance: (row) => {
        return (
          <div
            style={{
              fontSize: 9,
              color: TEXT_GRAY_LIGHT,
              fontStyle: 'italic',
            }}
          >
            {'Realized return'}
          </div>
        )
      },
      id: uuid()
    },
    {
      balance: 'realized_pl',
      last_close: (row) => (
        <div className={balances.last_realized_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.last_realized_pl)}
        </div>
      ),
      current: (row) => (
        <div className={balances.realized_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.realized_pl)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'realized_pl_pc',
      last_close: (row) => (
        <div className={balances.last_realized_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.last_realized_pl_pc)}
        </div>
      ),
      current: (row) => (
        <div className={balances.realized_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.realized_pl_pc)}
        </div>
      ),
      id: uuid()
    },
    {
      balance: 'day_realized_pl',
      last_close: (row) => (
        <div className={balances.last_day_realized_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.last_day_realized_pl)}
        </div>
      ),
      current: (row) => (
        <div className={balances.day_realized_pl >= 0 ? ' pos' : ' neg'}>
          {formatNumberForTable(balances.day_realized_pl)}
        </div>
      ),
      id: uuid()
    },    
    {
      balance: 'day_realized_pl_pc',
      last_close: (row) => (
        <div className={balances.last_day_realized_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.last_day_realized_pl_pc)}
        </div>
      ),
      current: (row) => (
        <div className={balances.day_realized_pl_pc >= 0 ? ' pos' : ' neg'}>
          {formatPercent(balances.day_realized_pl_pc)}
        </div>
      ),
      id: uuid()
    },        
    {
      balance: (row) => {
        return (
          <div
            style={{
              fontSize: 9,
              color: TEXT_GRAY_LIGHT,
              fontStyle: 'italic',
            }}
          >
            {'Misc'}
          </div>
        )
      },
      id: uuid()
    },                               
    {
      balance: 'pend_transfer_in',
      last_close: formatNumberForTable(balances.last_pend_transfer_in),
      current: formatNumberForTable(balances.pend_transfer_in),
      id: uuid()
    },
    {
      balance: 'pend_transfer_out',
      last_close: formatNumberForTable(balances.last_pend_transfer_out),
      current: formatNumberForTable(balances.pend_transfer_out),
      id: uuid()
    },
    {
      balance: 'cash_withdrawable',
      last_close: formatNumberForTable(balances.last_cash_withdrawable),
      current: formatNumberForTable(balances.cash_withdrawable),
      id: uuid()
    },
    {
      balance: 'net_deposits',
      last_close: formatNumberForTable(balances.last_net_deposits),
      current: formatNumberForTable(balances.net_deposits),
      id: uuid()
    },
    {
      balance: 'cash_on_orders',
      last_close: formatNumberForTable(balances.last_cash_on_orders),
      current: formatNumberForTable(balances.cash_on_orders),
      id: uuid()
    },
    
    {
      balance: 'buying_pwr',
      last_close: formatNumberForTable(balances.last_buying_pwr),
      current: formatNumberForTable(balances.buying_pwr),
      id: uuid()
    },
    {
      balance: 'daytrading_buying_pwr',
      last_close: formatNumberForTable(balances.last_daytrading_buying_pwr),
      current: formatNumberForTable(balances.daytrading_buying_pwr),
      id: uuid()
    },
    {
      balance: 'daytrade_count',
      last_close: formatNumberForTable(balances.last_daytrade_count),
      current: formatNumberForTable(balances.daytrade_count),
      id: uuid()
    },
  ]

  let parentClassName = 'table-tab'
  let tableClassName = 'table-container'
  if (props.log) {
    parentClassName += ' for-modal'
    tableClassName += ' for-modal'
  }

  return (
    <div className={parentClassName} style={{width: props.width, height: props.height, minHeight: 0, position: 'relative'}}>
      {openDropdown}
      {!props.log && <div className='tab-header'>
        <div>Balances table</div>
        <Icon 
          icon='gear'
          set='regular'
          size={14}
          style={{marginLeft: 12, marginTop: 1}}
          onClick={(e) => {      
            const rect = e.target.getBoundingClientRect()
            if (rect) {
              const width= 175
              const left = rect.left - width + 10
              const top = rect.bottom
              setOpenDropdown(<TableDropdown 
                left={left}
                top={top}
                width={width}
                onClose={() => setOpenDropdown(null)}
                paneIndex={props.paneIndex}
                table='balances'
              />)
            }
          }}
        />
      </div>}
      <div className={tableClassName}>
        <DataGridPro
          rows={balancesRows}
          columns={[
            {
              field: 'balance',
              headerName: 'balance',
              flex: 3,
              sortable: false,
              renderCell: (params) => params.value instanceof Function ? params.value(params.row) : params.value,
            },
            {
              field: 'last_close',
              headerName: 'last_close',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              sortable: false,
              renderCell: (params) => params.value instanceof Function ? params.value(params.row) : params.value,
            },
            {
              field: 'current',
              headerName: 'current',
              flex: 1,
              headerAlign: 'right',
              align: 'right',
              sortable: false,
              renderCell: (params) => params.value instanceof Function ? params.value(params.row) : params.value,
            },
          ]}
          hideFooter
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          components={{
            NoRowsOverlay: () => {
              if (!uld.liveData) {
                // 15 Feb 24 - probably unnecessary
                return (
                  <CustomGridOverlay text='Loading balances...' />
                )
              }
              return (
                <CustomGridOverlay text='No balances.' />
              )
            },
          }}
          sx={{
            ...tableSX,
            ...(props.log ? tableSX_Modal : null)
            
          }}          
        />
      </div>
    </div>
  )
}