/*
DIY dropdown select, based on DropdownMenu.

Can theoretically be autocomplete; as if 29 Jan this is broken.

VARIANTS
  - default: bulky label, maybe for settings tab?
  - compact: used in PE
  - ce-ds: context editor, datasource
  - ce-ds-arg: context editor, datasource argument
  - ce-op: context editor, operator
*/


import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { DropdownMenu } from './DropdownMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCaretDown, faChevronDown, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
//@ts-ignore
import { cumulativeOffset } from '../../logic/u.ts'
import './DropdownSelect.scss';

interface DropdownSelectProps {
  options: any[]
  onChange?: (val: any) => void
  className?: string
  isAutocomplete?: boolean
  variant?: 'default' | 'compact' | 'ce-ds' | 'borderless'
  label?: string
  placeholder?: string
  value?: any
  nullValue?: any         // div/text to display if nothing is selected
  hoverText?: string
  errorText?: string
  width?: any
  height?: number             // probably remove
  menuHeight?: number
  style?: any
  noLabel?: boolean
  isDriven?: boolean
  chevronMarginTop?: number // hack
  fontSize?: number // hack
  dropDownMenuWidth?: number // hack
  top?: number
  getValue?: (val: any) => any
  noArrowKeys?: boolean
  autoFocus?: boolean
}
export const DropdownSelect = (props: DropdownSelectProps) => {
  // Find full initial value
  let initialDisplay
  props.options.forEach((opt) => {
    if (opt.value === props.value) {
      initialDisplay = opt.display
      if (opt.display_control) initialDisplay = opt.display_control
    }
  })
  if (!initialDisplay && props.nullValue) {
    initialDisplay = props.nullValue
  }

  // State
  const [display, setDisplay] = useState(initialDisplay)
  const [dropdownMenu, setDropdownMenu] = useState<ReactElement | null>(null)
  const [typeaheadValue, setTypeaheadValue] = useState('')
  const controlRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef();

  const width = props.width || 200
  let dropdownWidth = props.dropDownMenuWidth
  if (!dropdownWidth && props.width !== 'auto') dropdownWidth = props.width + 4
  else if (!dropdownWidth) dropdownWidth = 200

  useEffect(() => {
    if (props.autoFocus && controlRef.current) {
      controlRef.current.focus();
    }
  }, [props.autoFocus]);

  // Listen for any sort of keystroke while this is open
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [])

  useEffect(() => {
    // Add onClick methods when `props.options` changes
    props.options.forEach((option) => {
      option.onClick = () => {
        let to_show = option.display;
        if (option.display_control) to_show = option.display_control;
        setDisplay(to_show);
        if (props.onChange) {
          props.onChange(option.value);
        }
      };
    });
  }, [props.options, props.onChange]);

  const toggleDropdown = (e) => {
    if (dropdownMenu) {
      setDropdownMenu(null)
    } else {
      // if (e.type !== 'click') {return}
      const coords = cumulativeOffset(controlRef.current)
      const dropdownMenu = (
        <DropdownMenu
          contents={props.options}
          onSelect={(val) => {
            onSelect(val)
          }}
          posLeft={coords.left}
          posTop={props.variant === 'borderless' ? coords.top + 22 : coords.top + 36}
          width={dropdownWidth}
          height={props.menuHeight || 220}
          onClose={() => {setDropdownMenu(null)}}
          //@ts-ignore
          ref={dropdownRef}
          noArrowKeys={props.noArrowKeys}
        />
      )
      setDropdownMenu(dropdownMenu)
    }
  }

  const onSelect = (val) => {
    setDropdownMenu(null)
    let to_show = val
    if (typeof(val) === 'object') {
      to_show = val.display
      if (val.display_control) to_show = val.display_control
    }
    setDisplay(to_show)
    //@ts-ignore
    controlRef.current.blur()
  }

  const getLabel = () => {
    let label
    let infoIcon
    let errorIcon
    let labelClassName = 'label'
    if (props.variant === 'compact') {
      labelClassName += ' compact'
    }
    if (props.label) {
      label = <div className={labelClassName}>{props.label}</div>
      if (props.hoverText) {
        infoIcon = <FontAwesomeIcon
          className={'info-icon'}
          icon={faInfoCircle}
          title={props.hoverText}
          style={{marginTop: 1}}
        />
      }
      if (props.errorText) {
        errorIcon = <FontAwesomeIcon
          className={'error-icon'}
          icon={faExclamationCircle}
          title={props.errorText}
          style={{marginTop: 1}}
        />
      }
    }

    let labelStyle
    if (props.noLabel) {
      labelStyle = {display: 'none'}
    }

    return (
      <div className={'label-row'} style={labelStyle}>
        {label}
        {infoIcon}
        {errorIcon}
      </div>
    )
  }

  const handleKeyPress = (e) => {

    if (e.key === 'Enter') {
      // e.preventDefault()
    }
    if (['ArrowUp', 'ArrowDown', 'Escape', 'Enter', 'Tab'].includes(e.key)) {
      //@ts-ignore
      // dropdownRef.current.onKeyDown(e)
    }

    // if (dropdownMenu) {
    //   if (e.key === 'Escape') {
    //     setDropdownMenu(null)
    //   }
    // }
  }  

  // Render
  let classList = 'dropdown-select'
  if (props.className) {
    classList += ' ' + props.className
  }

  // If menu is open, add class
  let dropdownControlClassName = 'dropdown-control'
  if (dropdownMenu) {
    dropdownControlClassName += ' menu-open'
  }

  // Within the context editor, we use an entirely different style
  if (props.variant === 'ce-ds') {
    dropdownControlClassName = ' ce-ds'
  } else if (props.variant === 'borderless') {
    dropdownControlClassName += ' borderless'
  }

  return (
    <div className={classList} style={props.style}>
      {getLabel()}
      <div
        className={dropdownControlClassName}
        tabIndex={0}
        // contentEditable={props.isAutocomplete}
        // contentEditable={true}
        // suppressContentEditableWarning={true}
        //@ts-ignore
        ref={controlRef}
        style={{
          width,
          height: props.height,
          fontSize: props.fontSize ? props.fontSize : ''
        }}
        onClick={(e) => {toggleDropdown(e)}}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            toggleDropdown(e);
          }
        }}
        onInput={(e) => {
          //@ts-ignore
          dropdownRef.current.filterOptions(e.target.innerText)
        }}
        // onFocus={(e) => {toggleDropdown(e)}}        
      >
        <div
          className='dd-ctrl-inner'
          contentEditable={props.isAutocomplete}
          suppressContentEditableWarning={true}
          style={{marginRight: 7}}
        >
          {/* <div>{display || initialDisplay}</div> */}
          {props.isDriven ? <div>{initialDisplay}</div> : <div>{display || initialDisplay}</div>}

          {/* Caret, only on non-CE variant */}
          {props.variant !== 'ce-ds' ?
            <FontAwesomeIcon
              className={'dropdown-btn'}
              style={{
                marginTop: props.chevronMarginTop || 3,
              }}
              icon={faChevronDown}
            />
          : null
          }
        </div>
      </div>
      {dropdownMenu}
    </div>
  )




}
