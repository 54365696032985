import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal'
import { TEXT_GRAY } from '../../logic/u'
import { AddTickersModal } from '../modals/AddTickersModal'
import { DeleteWatchlistModal } from '../modals/DeleteWatchlistModal'

interface WatchlistSelectorDropdownProps {
  left: number
  top: number
  onClose: () => void
}

export const WatchlistSelectorDropdown = (props: WatchlistSelectorDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const {left, top, onClose} = props

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = [
    {
      display: (
        <div className='font-size-10 gray-text'>
          Watchlists
        </div>
      )
    },
  ]
  user?.watchlists?.forEach(watchlistObj => {    

    contents.push(
      {
        display: (
          <div className='left space-between'>
            <div className='row width-full'>
              <div style={{width: 15, marginRight: 15}}>
                {user.selectedWatchlistIdHomeTab === watchlistObj.id ? <Icon
                  icon={'check'}
                  set='sharp-solid'
                  size={13}
                  style={{marginRight: 20, marginTop: 1.5}}
                  className={''}
                /> : null}
              </div>
              <div style={{width: 220}} className='add-ellipses-cutoff'>{watchlistObj.name}</div>
            </div>

            <Icon
              icon={'xmark'}
              set='sharp-solid'
              size={11}
              className={'key-icon'}
              style={{marginTop: .75, marginRight: 10}}
              onClick={(e) => {
                e.stopPropagation()
                setOpenModal(<DeleteWatchlistModal watchlistId={watchlistObj.id} />)
              }}
            />
            <Icon
              icon={'square-plus'}
              set='sharp-solid'
              size={14}
              className={'key-icon'}
              style={{marginTop: 1.5}}
              onClick={(e) => {
                e.stopPropagation()
                setOpenModal(<AddTickersModal watchlistId={watchlistObj.id} />)
              }}
            />
          </div>
        ),
        onClick: async () => {
          await db.collection('users').doc(user.uid).update({ selectedWatchlistIdHomeTab: watchlistObj.id })
        }
      },
    )
  })
  if (user?.watchlists.length) {
    contents.push(
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
    )
  }
  contents.push(
    {
      display: (
        <div className='left'>
          <div style={{width: 15, marginRight: 15}}>
            <Icon
              icon={'circle-plus'}
              set='sharp-solid'
              size={13}
              style={{marginRight: 20, marginTop: 1.5}}
              className={''}
            />
          </div>
          <div>{'Create watchlist'}</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<CreateWatchlistModal fromHome={true} />)
      }
    },
  )

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={300}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}