import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export const BacktestCandlestickChart = ({ data, entries, exits }) => {
  const entriesData = entries.map(([ts, price]) => ({ x: ts, y: price }));
  const exitsData = exits.map(([ts, price]) => ({ x: ts, y: price }));

  const chartOptions = {
    series: [
      {
        type: 'candlestick',
        name: '',
        data,
        color: '#d75442', // Color for falling candles
        upColor: '#6ba583', // Color for rising candles
        zIndex: 1,
      },
      {
        type: 'scatter',
        name: 'Entries',
        data: entriesData,
        marker: {
          symbol: 'triangle',
          fillColor: '#00cc66',
          lineWidth: 1,
          lineColor: 'black',
          radius: 5,
        },
        tooltip: {
          pointFormat: 'Entry: {point.y}',
        },
        zIndex: 2,
      },
      {
        type: 'scatter',
        name: 'Exits',
        data: exitsData,
        marker: {
          symbol: 'triangle-down',
          fillColor: '#ff5555',
          lineWidth: 1,
          lineColor: 'black',
          radius: 5,
        },
        tooltip: {
          pointFormat: 'Exit: {point.y}',
        },
        zIndex: 2,
      },
    ],
    xAxis: {
      type: 'datetime',
      title: { text: '' },
      labels: {
        format: '{value:%e %b %Y}',
        style: { color: 'white' },
      },
    },
    yAxis: {
      title: { text: '' },
      labels: {
        style: { color: 'white' },
      },
      gridLineWidth: 0,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const date = Highcharts.dateFormat('%e %b %Y', this.x);
        let s = `Date: ${date}<br/>`;
        if (this.points) {
          this.points.forEach(function (point) {
            if (point.series.type === 'candlestick') {
              const o = point.point.open.toFixed(2);
              const h = point.point.high.toFixed(2);
              const l = point.point.low.toFixed(2);
              const c = point.point.close.toFixed(2);
              s += `Open: ${o}<br/>
                    High: ${h}<br/>
                    Low: ${l}<br/>
                    Close: ${c}<br/>`;
            } else if (point.series.name === 'Entries') {
              s += `Entry: ${point.point.y}<br/>`;
            } else if (point.series.name === 'Exits') {
              s += `Exit: ${point.point.y}<br/>`;
            }
          });
        }
        return s;
      },
    },
    chart: {
      height: 400,
      backgroundColor: 'transparent',
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
  };

  return (
    <div
      style={{
        height: 400,
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
      />
    </div>
  );
};
