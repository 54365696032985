import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  tableSX_Modal,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../../types/global_types'
import { getConfig } from '../../../config'
import { TickerDropdown } from '../../dropdowns/TickerDropdown'
import { TickerModal } from '../TickerModal'
import { CustomGridOverlay } from '../../tabs/CustomGridOverlay'
import { EELog } from '../../../types/user_types'
const config = getConfig() as any

interface LogModalWatchlistsTabProps {
  log: EELog
}
export const LogModalWatchlistsTab = (props: LogModalWatchlistsTabProps) => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'symbol',
      sort: 'asc' as GridSortDirection
    },
  ]);

  const { log } = props

  if (!user || !uld) return null

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  }

  const watchlists = log.watchlists || []

  return (
    <div className='table-container for-modal'>
      <DataGridPro          
        rows={watchlists}          
        columns={[
          {
            field: 'name',
            headerName: 'name',
            flex: .4,
            type: 'string',
            sortComparator: handleMUISorting,
            renderCell: (params) => (
              <div
                style={{
                  width: '100%',                    
                  textAlign: 'left'
                }}                  
              >
                {params.row.name}
              </div>
            )
          },
          {
            field: 'tickers',
            headerName: 'tickers',
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            type: 'string',
            sortComparator: handleMUISorting,
            renderCell: (params) => (
              <div
                style={{
                  width: '100%',                    
                  textAlign: 'left'
                }}                  
              >
                {params.row.tickers.join(', ')}
              </div>
            )
          },      
        ]}
        disableColumnResize
        disableColumnMenu
        disableColumnPinning
        disableSelectionOnClick
        density='compact'
        components={{
          NoRowsOverlay: () => {              
            return (
              <CustomGridOverlay text='No watchlists.' />
            )
          },
        }}
        sx={{
          ...tableSX,
          ...tableSX_Modal
        }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        localeText={{
          footerTotalRows: 'Total watchlists:',
        }}
        hideFooter
      />
    </div>
  )
}