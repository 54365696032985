import "./TBChart.scss";
import React, { useRef, useEffect, useState, useCallback } from "react";
import { useAtom } from "jotai";
import {
  loggedInUserAtom,
  userLiveDocAtom,
  toolModeAtom,
  isDraggingAtom,
  refreshChartAtom,
  selectedAnnotationAtom,
} from "../../types/global_types";
import { db } from "../../firebase";
import { Icon } from "../reusable/Icon";
import { Icon2 } from "../reusable/Icon2";
import { DropdownSelect } from "../reusable";
import { TBChartInner } from "./TBChartInner";
// import { load_bars } from "./chart_utils";
import { load_bars } from "./chart_utils/load_bars";
import { getYBounds } from "./chart_utils/getYBounds";
import { compensate_for_stock_splits } from "./chart_utils/compensate_for_stock_splits";

import {
  INTERVALS,
  TEXT_GRAY,
  is_internal,
} from "../../logic/u";
import { get_max_and_min } from "./get_start_end_index";
import { getConfig } from "../../config";
import { ChartSettingsDropdown } from "../dropdowns/ChartSettingsDropdown";

const config: any = getConfig();

interface BoundingParamsType {
  startIndex: number;
  endIndex: number;
  yStart: number;
  yRange: number;
}



export const TBChart = ({ symbol, chartIndex, width, height }) => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom);
  const [chartMode, setChartMode] = useState<'candles' | 'line'>('candles');
  const [bars, setBars] = useState<any[]>([]);
  const [interval, setIntervalState] = useState<string>('1m');
  // const [__endDate, set__endDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(true);       // internal, related to API
  const [headerDivWidth, setHeaderDivWidth] = useState<number>(10000000);
  const [previousSymbol, setPreviousSymbol] = useState(symbol);
  const [previousInterval, setPreviousInterval] = useState(interval);
  // const [toolMode, setToolMode] = useAtom(toolModeAtom);
  // const [selectedAnnotation, setSelectedAnnotation] = useAtom(selectedAnnotationAtom);
  const [isDragging, setIsDragging] = useAtom(isDraggingAtom);
  // const [refreshCounter] = useAtom(refreshChartAtom);
  // const [startIndex, setStartIndex] = useState<number>(-200);
  // const [endIndex, setEndIndex] = useState<number>(10);
  // const [yParams, setYParams] = useState<any>({ start: null, range: null });
  const [boundingParams, setBoundingParams] = useState({
    startIndex: -200,
    endIndex: 10,
    yStart: 0,
    yRange: 100
  });
  const [autofitMode, setAutofitMode] = useState(true);
  const [ openDropdown, setOpenDropdown ] =useState<any>(null)
  const [switchingScrim, setSwitchingScrim] = useState(false);    // used for switches within a chart
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [isChangingDataParams, setIsChangingDataParams] = useState(false);


  const prevParamsRef = useRef<any>();


  const [firstDataFetchParams, setFirstDataFetchParams] = useState({
    interval: '',
    preset: '1M'
  });



  if (!user) return null;


  // UE #1 - reset everything on new symbol
  useEffect(() => {
    if (symbol !== previousSymbol || chartIndex !== previousInterval) {
      setBars([]);
      setPreviousSymbol(symbol);
      setPreviousInterval(chartIndex);
      setFirstDataFetchParams({
        interval: '',
        preset: '1M'
      })
    }
  }, [symbol, chartIndex]);


  const pan = (deltaX) => {
    setUserHasInteracted(true);
    const num_visible_bars = boundingParams.endIndex - boundingParams.startIndex;

    // Adjusted bars_factor calculation
    const base_num_bars = 250; // Midpoint of 200-300 range
    const zoom_factor = Math.log(num_visible_bars / base_num_bars) / Math.log(2);
    const bars_factor = Math.pow(2, zoom_factor);

    const delta = deltaX * 0.18 * bars_factor;

    let newStartIndex = Math.round(boundingParams.startIndex + delta);
    let newEndIndex = Math.round(boundingParams.endIndex + delta);

    const maxLoadedIndex = bars[bars.length - 1]?.relative_index;
    const minLoadedIndex = bars[0]?.relative_index;
    const extraScrollSpace = num_visible_bars * 0.7;

    if (newEndIndex > maxLoadedIndex + extraScrollSpace) {
      const overflow = newEndIndex - (maxLoadedIndex + extraScrollSpace);
      newEndIndex -= overflow;
      newStartIndex -= overflow;
    }

    if (newStartIndex < minLoadedIndex) {
      const underflow = minLoadedIndex - newStartIndex;
      newStartIndex += underflow;
      newEndIndex += underflow;
    }

    if (newEndIndex - newStartIndex < 30) {
      return;
    }

    if (autofitMode) {
      const visibleCandles = bars.filter((bar) => bar.relative_index >= newStartIndex && bar.relative_index <= newEndIndex);
      const newYParams = getYBounds(visibleCandles);
      setBoundingParams((prevParams) => ({
        ...prevParams,
        yStart: newYParams.start,
        yRange: newYParams.range,
        startIndex: newStartIndex,
        endIndex: newEndIndex
      }));
    } else {
      setBoundingParams(prevParams => ({
        ...prevParams,
        startIndex: newStartIndex,
        endIndex: newEndIndex
      }));
    }

    if (newStartIndex < bars[0]?.relative_index + 100) {
      load_more_if_necessary(newStartIndex);
    }
  };

  const zoom = (deltaX) => {
    setUserHasInteracted(true);
    const num_visible_bars = boundingParams.endIndex - boundingParams.startIndex;
    const deviation_from_200 = num_visible_bars - 200;
    const bars_factor = 1 + (deviation_from_200 / 200) * 0.8;

    const newStartIndex = Math.floor(boundingParams.startIndex + (deltaX * 0.25 * bars_factor));

    // Calculate the number of visible bars
    const visibleBars = bars.filter((bar) =>
      bar.relative_index >= newStartIndex && bar.relative_index <= boundingParams.endIndex
    );

    // Check if the number of visible bars exceeds 800
    if ((visibleBars.length > 800) && (deltaX < 0)) {
      return;
    }

    // Ensure we don't zoom in too far
    if (visibleBars.length < 30) {
      return;
    }

    if (autofitMode) {
      const newYParams = getYBounds(visibleBars);
      setBoundingParams((prevParams: any) => ({
        ...prevParams,
        yStart: newYParams.start,
        yRange: newYParams.range,
        startIndex: newStartIndex
      }));
    } else {
      setBoundingParams(prevParams => ({
        ...prevParams,
        startIndex: newStartIndex
      }));
    }

    load_more_if_necessary(newStartIndex);
  };


  const minWidthToShowMostElements = 680;
  const headerDivRef = useRef<HTMLDivElement>(null);

  // UE #2 - resize
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const divWidth = entry.contentRect.width;
        //@ts-ignore
        window.HEADER_DIV_WIDTH = divWidth;
        setHeaderDivWidth(divWidth);
      }
    });

    if (headerDivRef.current) {
      resizeObserver.observe(headerDivRef.current);
    }
    return () => {
      resizeObserver.disconnect();
      setHeaderDivWidth(10000000);
    };
  }, []);

  // UE #3 - inital bar load
  useEffect(() => {
    const loadBars = async () => {
      setIsLoading(true);
      try {
        let _startDate, _endDate, _bufferedStartDate, _interval;

        // Determine which property changed
        const symbolChanged = symbol !== prevParamsRef.current?.symbol;
        const presetChanged = !!firstDataFetchParams.preset
        const intervalChanged = !!firstDataFetchParams.interval

        // New preset, symbol change, or initial load case
        if (presetChanged || symbolChanged || !prevParamsRef.current) {
          _endDate = new Date();
          _interval = '30m'
          _startDate = new Date(_endDate);
          _bufferedStartDate = new Date(_endDate);
          switch (firstDataFetchParams.preset) {
            case '1D': {
              let start_days_ago = 1
              if (_endDate.getDay() === 6) start_days_ago = 2
              else if (_endDate.getDay() === 0) start_days_ago = 3
              else if (_endDate.getDay() === 1) start_days_ago = 4
              _startDate.setDate(_startDate.getDate() - start_days_ago);
              // _bufferedStartDate.setDate(_endDate.getDate() - 2)
              _interval = '1m';
              break;
            }
            case '1W':
              _startDate.setDate(_startDate.getDate() - 7);
              _bufferedStartDate.setDate(_endDate.getDate() - 14)
              _interval = '5m';
              break;
            case '1M':
              _startDate.setMonth(_startDate.getMonth() - 1);
              _bufferedStartDate.setDate(_endDate.getMonth() - 2)
              _interval = '30m';
              break;
            case '1Y':
              _startDate.setFullYear(_startDate.getFullYear() - 1);
              _bufferedStartDate.setDate(_endDate.getFullYear() - 2)
              _interval = '1d';
              break;
            case 'All':
              _startDate = new Date(0); // Beginning of time
              _interval = '1w';
              break;
          }
        }

        // New interval case
        else if (intervalChanged) {
          // setSwitchingScrim(true)
          _interval = firstDataFetchParams.interval
          _endDate = new Date();
          _startDate = new Date(_endDate);

          switch (_interval) {
            case '1m': {
              let start_days_ago = 1
              if (_endDate.getDay() === 6) start_days_ago = 2
              else if (_endDate.getDay() === 0) start_days_ago = 3
              else if (_endDate.getDay() === 1) start_days_ago = 4
              _startDate.setDate(_startDate.getDate() - start_days_ago);
              break;
            }
            case '5m':
              _startDate.setDate(_startDate.getDate() - 7);
              break;
            case '30m':
              _startDate.setMonth(_startDate.getMonth() - 1);
              break;
            case '1h':
              _startDate.setMonth(_startDate.getMonth() - 1);
              break;
            case '2h':
              _startDate.setMonth(_startDate.getMonth() - 2);
              break;
            case '4h':
              _startDate.setMonth(_startDate.getMonth() - 4);
              break;
            case '1d':
              _startDate.setFullYear(_startDate.getFullYear() - 1);
              break;
            case '1w':
              _startDate.setFullYear(_startDate.getFullYear() - 2);
              break;
          }
        }

        // Error case, take this out when feeling confident
        else {
          setIsLoading(false);
          return;
        }

        setIntervalState(_interval);

        const loadedBars = await load_bars({
          symbol,
          interval: _interval,
          // start: _bufferedStartDate,
          start: _startDate,
          end: _endDate,
          user,
          uld,
        });

        // setYParams(getYBounds(visibleCandles))
        const bars_w_ri = loadedBars.map((bar, i) => ({
          ...bar,
          relative_index: i - loadedBars.length + 1,
        }))
        const adjustedBars = bars_w_ri;
        // const adjustedBars = compensate_for_stock_splits(bars_w_ri);     // was causing trouble
        setBars(adjustedBars)

        let { maxPrice, minPrice } = get_max_and_min(loadedBars)

        const range = maxPrice - minPrice
        maxPrice = maxPrice + (range * 0.05)
        minPrice = minPrice - (range * 0.05)
        if (range === 0 && minPrice === 0) {
          minPrice = -5
          maxPrice = 105
        }

        // Handle incoming live data: only reset the view if this is actually appropriate
        if (!userHasInteracted || isChangingDataParams) {
          setBoundingParams({
            yStart: minPrice,
            yRange: maxPrice - minPrice,
            startIndex: -loadedBars.length + 1,
            endIndex: 0
          });
          setUserHasInteracted(false);
          setIsChangingDataParams(false);
        } else {
          setBars(adjustedBars);
        }

      } catch (err) {
        console.error(err)
        setBars([]);
      } finally {
        setIsLoading(false);
        setSwitchingScrim(false)
      }
    };

    loadBars();

    // Update the ref with the current params
    prevParamsRef.current = { ...firstDataFetchParams, symbol };
  }, [firstDataFetchParams, symbol, user, uld, userHasInteracted, isChangingDataParams]);



  const handleIntervalChange = useCallback((newInterval) => {
    setIsChangingDataParams(true);
    setFirstDataFetchParams({
      preset: '',
      interval: newInterval
    });
    setBoundingParams(prevParams => ({
      ...prevParams,
      startIndex: -200,
      endIndex: 10
    }));
    setBars([]);
  }, []);

  async function load_more_if_necessary (new_start_index) {
    if (new_start_index >= bars[0]?.relative_index + 100) return;
    //@ts-ignore
    if (window.TBChartLoadingInProgress) return;
    //@ts-ignore
    window.TBChartLoadingInProgress = true;

    const end = new Date(bars[0]?.t);
    end.setMilliseconds(end.getMilliseconds() - 1);

    const new_bars = await load_bars({
      symbol,
      interval,
      start: undefined,
      end,
      user,
      uld,
    });

    const new_bars_filtered = new_bars.filter(bar => !bars.some(existingBar => existingBar.t === bar.t));

    const previous_relative_index = bars[0]?.relative_index;
    new_bars_filtered.forEach((bar, index) => {
      bar.relative_index = previous_relative_index - (new_bars_filtered.length - index);
    });

    const adjustedBars = compensate_for_stock_splits([...new_bars_filtered, ...bars]);
    setBars(adjustedBars);
    //@ts-ignore
    window.TBChartLoadingInProgress = false;

    // Adjust indexes if necessary
    if (boundingParams.startIndex < adjustedBars[0].relative_index) {
      setBoundingParams(prevParams => ({
        ...prevParams,
        startIndex: adjustedBars[0].relative_index,
        endIndex: prevParams.startIndex + (prevParams.endIndex - prevParams.startIndex)
      }));
    }
  }

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.ctrlKey || e.metaKey || e.type === "gesturestart") {
        e.preventDefault();
      }
    };

    window.addEventListener("wheel", disablePinchZoom, { passive: false });
    window.addEventListener("gesturestart", disablePinchZoom, { passive: false });

    return () => {
      window.removeEventListener("wheel", disablePinchZoom);
      window.removeEventListener("gesturestart", disablePinchZoom);
    };
  }, []);

  if (!symbol) {
    return (
      <div
        className='tb-chart'
        style={{
          cursor: isDragging ? 'grabbing' : 'crosshair',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: -40,
            }}
          >
            No chart loaded
          </div>
        </div>
      </div>
    );
  }

  // let _startIndex = boundingParams.startIndex;
  // let _endIndex = boundingParams.endIndex;

  // let _yRange = boundingParams.yRange;
  // let _yStartPrice = boundingParams.yStart;

  // if (_endIndex !== undefined && __endDate) {
  //   const finalBarDate = new Date(bars[bars.length - 1]?.t);
  //   const endDateTimestamp = __endDate;
  //   const intervalMs = INTERVALS[interval];
  //   const numEmptyBars = getMarketOpenTimeDiffCandles(finalBarDate, endDateTimestamp, intervalMs);
  //   _endIndex += numEmptyBars;
  // }

  const isInternal = is_internal(symbol)
  const _chartMode = isInternal ? 'line' : chartMode;

  const selectPresetTimeframe = (timeframe: string) => {
    setIsChangingDataParams(true);
    setSwitchingScrim(true)
    setFirstDataFetchParams({
      preset: timeframe,
      interval: ''
    })
  }

  const startIndex = bars.findIndex(bar => bar.relative_index >= boundingParams.startIndex);
  const endIndex = bars.findIndex(bar => bar.relative_index > boundingParams.endIndex);

  let relevant_bars = bars.slice(
    startIndex >= 0 ? startIndex : 0,
    endIndex >= 0 ? endIndex : bars.length
  );

  // If we're panning past the last real bar, add future bars
  const lastRealBar = bars[bars.length - 1];
  if (lastRealBar && relevant_bars.length < boundingParams.endIndex - boundingParams.startIndex + 1) {
    let lastBar = relevant_bars.length > 0 ? relevant_bars[relevant_bars.length - 1] : lastRealBar;
    while (relevant_bars.length < boundingParams.endIndex - boundingParams.startIndex + 1) {
      const nextBarTime = new Date(lastBar.t).getTime() + INTERVALS[interval];
      const newBar = {
        relative_index: lastBar.relative_index + 1,
        t: new Date(nextBarTime).toISOString(),
        o: lastRealBar.c,
        h: lastRealBar.c,
        l: lastRealBar.c,
        c: lastRealBar.c,
        v: 0,
        isFutureCandle: true
      };
      relevant_bars.push(newBar);
      lastBar = newBar;
    }
  }

  return (
    <div className='tb-chart' style={{ cursor: isDragging ? 'grabbing' : 'crosshair', width, height, minHeight: 0, position: 'relative' }}>
      {openDropdown}
      <div className='header' ref={headerDivRef}>
        <div className='left'>
          <div>{symbol}</div>
        </div>

        <div className='right' style={{ display: 'flex' }}>
          {headerDivWidth > minWidthToShowMostElements ? (
            <>
              <div className='interval-select'>
                {!isInternal ? (
                  <div
                    onClick={() => {selectPresetTimeframe('1D')}}
                    className='hov-text-btn'
                    title='Reset time horizon to 1 day'
                  >
                    1D
                  </div>
                ) : null}
                {!isInternal ? (
                  <div
                    onClick={() => {selectPresetTimeframe('1W')}}
                    className='hov-text-btn'
                    title='Reset time horizon to 1 week'
                  >
                    1W
                  </div>
                ) : null}
                <div
                  onClick={() => {selectPresetTimeframe('1M')}}
                  className='hov-text-btn'
                  title='Reset time horizon to 1 month'
                >
                  1M
                </div>
                <div
                  onClick={() => {selectPresetTimeframe('1Y')}}
                  className='hov-text-btn'
                  title='Reset time horizon to 1 year'
                >
                  1Y
                </div>
                <div
                  onClick={() => {selectPresetTimeframe('All')}}
                  className='hov-text-btn'
                  title='Reset time horizon to all time'
                >
                  All
                </div>
              </div>

              {!isInternal ? (
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: TEXT_GRAY,
                  }}
                >
                  •
                </div>
              ) : null}
            </>
          ) : null}

          {!isInternal ? (
            <DropdownSelect
              variant={'borderless'}
              width={'auto'}
              dropDownMenuWidth={80}
              value={interval}
              isDriven={true}
              chevronMarginTop={4}
              onChange={(val) => {handleIntervalChange(val)}}
              options={[
                { display: '1m', value: '1m' },
                { display: '5m', value: '5m' },
                { display: '30m', value: '30m' },
                { display: '1h', value: '1h' },
                { display: '2h', value: '2h' },
                { display: '4h', value: '4h' },
                { display: '1d', value: '1d' },
                { display: '1w', value: '1w' },
              ]}
            />
          ) : null}

          <Icon
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12}}
            onClick={(e) => {
              const rect = e.target.getBoundingClientRect()
              if (rect) {
                const left = rect.left - 80
                const top = rect.bottom
                setOpenDropdown(
                  <ChartSettingsDropdown
                    left={left}
                    top={top}
                    ticker={symbol}
                    autoFitMode={autofitMode}
                    setAutoFitMode={() => {
                      if (!autofitMode) {
                        const visibleCandles = bars.filter((bar) =>
                          bar.relative_index >= boundingParams.startIndex &&
                          bar.relative_index <= boundingParams.endIndex
                        );
                        const newYParams = getYBounds(visibleCandles);
                        setBoundingParams(prevParams => ({
                          ...prevParams,
                          yStart: newYParams.start,
                          yRange: newYParams.range
                        }));
                      }
                      setAutofitMode(!autofitMode);
                    }}
                    onClose={() => setOpenDropdown(null)}
                    chartMode={chartMode}
                    setChartMode={setChartMode}
                    paneIndex={chartIndex}
                    isInternal={isInternal}
                  />
                )
              }
            }}
          />
        </div>
      </div>

      {/* Dimming effect */}
      <div className={`switching-scrim ${switchingScrim ? 'visible' : ''}`} />

      {bars.length ? (
        <TBChartInner
          symbol={symbol}
          interval={interval}
          isInternal={isInternal}
          bars={relevant_bars}
          xStartIndex={boundingParams.startIndex}
          xEndIndex={boundingParams.endIndex}
          yStartPrice={boundingParams.yStart}
          yRange={boundingParams.yRange}
          stepBack={0}
          setStepBack={() => {console.log('no op')}}
          chartMode={_chartMode}
          zoom={zoom}
          pan={pan}
          panVertical={(deltaY: number) => {
            if (autofitMode) return;
            const delta = deltaY * 0.8;
            setBoundingParams((prevParams: any) => ({
              ...prevParams,
              yStart: prevParams.yStart + delta,
            }));
          }}
          zoomVertical={(deltaY: number) => {
            setUserHasInteracted(true);
            const delta = deltaY * 0.2;
            setBoundingParams((prevParams: any) => ({
              ...prevParams,
              yRange: prevParams.yRange + delta * 0.4,
              yStart: prevParams.yStart + delta * -0.2,
            }));
          }}
          isLoading={isLoading}
          // isLoadingNewTimeframe={isLoadingNewTimeframe}
          isLoadingNewTimeframe={false}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: -40,
              color: '#FF8F0E',
            }}
          >
            {isLoading ? `Loading ${symbol}...` : 'No data'}
          </div>
        </div>
      )}
    </div>
  );
};


