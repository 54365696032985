import React, { useState } from 'react'
import { Icon } from './Icon'

export function Spinner() {
  return (
    <Icon
      icon='spinner'
      set='sharp-solid'
      size={7}
      spin={true}
    />
  )
}