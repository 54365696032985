import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Input, Button, DropdownSelect, SSNInput, Checkbox } from '../../reusable'
import { db, currentUser } from '../../../firebase'
import { loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  SignUpStage,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import {
  usStates,
} from '../../../logic/u'
import axios from 'axios'
import { getConfig } from '../../../config'
import { ErrorModal } from '../../modals/ErrorModal'
const config: any = getConfig()

export const TaxInfoStep = () => {
  const [ loggedInUser ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const [ streetAddress, setStreetAddress ] = useState<string>('')
  const [ unit, setUnit ] = useState<string>('')
  const [ city, setCity ] = useState<string>('')
  const [ state, set_state ] = useState<string>('')
  const [ postalCode, setPostalCode ] = useState<string>('')
  const [ countryOfTaxResidence, setCountryOfTaxResidence] = useState<string>('')
  const [ countryOfCitizenship, setCountryOfCitizenship] = useState<string>('')
  const [ taxId, setTaxId ] = useState<string>('')
  const [ checkBox1Val, setCheckBox1Val ] = useState<boolean>(false)
  const [ checkBox2Val, setCheckBox2Val ] = useState<boolean>(false)

  const previousSignUpStage: SignUpStage = 'TrustedContact'

  useEffect(() => {
    setButtonEnabled(!!streetAddress && !!city && !!state && !!postalCode && !!taxId && !!checkBox1Val && !!checkBox2Val);
  }, [streetAddress, unit, city, state, postalCode, taxId, checkBox1Val, checkBox2Val]);

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title'>
          Tax information
        </div>
        <div className='input-container'>
          <Input
            label={'Residential street address'}
            type={'text'}
            // placeholder={'45 Pioneer Rd'}
            className={'margin-top-20'}
            style={{width: 330}}
            initialValue={streetAddress}
            onChange={(val) => {setStreetAddress(val)}}
          />
          <Input
            label={'Unit (optional)'}
            type={'text'}
            className={'margin-top-20'}
            style={{width: 160}}
            initialValue={unit}
            onChange={(val) => {setUnit(val)}}
          />
        </div>
        <div className='input-container'>
          <Input
            label={'City'}
            type={'text'}
            // placeholder={'Ann Arbor'}
            className={'margin-top-20'}
            style={{width: 160}}
            initialValue={city}
            onChange={(val) => {setCity(val)}}
          />
          <DropdownSelect
            label={'State'}
            options={usStates}
            className={'margin-top-20'}
            width={154}
            height={30}
            value={state}
            onChange={(val) => {set_state(val)}}
          />
          <Input
            label={'Postal code'}
            type={'text'}
            // placeholder={'83759'}
            className={'margin-top-20'}
            style={{width: 160}}
            initialValue={postalCode}
            max={5}
            onChange={(val) => {
              setPostalCode(val)}}
          />
        </div>
        <div className='input-container'>
          <DropdownSelect
            label={'Country of tax residence'}
            options={[{display: 'USA', value: 'USA'}]}
            className={'margin-top-20'}
            width={239}
            height={30}
            value={countryOfTaxResidence}
            onChange={(val) => {setCountryOfTaxResidence(val)}}
          />
          <DropdownSelect
            label={'Country of citizenship'}
            options={[{display: 'USA', value: 'USA'}]}
            className={'margin-top-20'}
            width={239}
            height={30}
            value={countryOfCitizenship}
            onChange={(val) => {setCountryOfCitizenship(val)}}
          />
        </div>
        <SSNInput
          label={'Social security number'}
          className={'margin-top-20'}
          onBlur={(val) => {setTaxId(val)}}
          style={{width: '100%'}}
        />
        <div className='row'>
          <Checkbox
            className='margin-top-25 margin-right-5'
            checkMarginTop={-3}
            value={checkBox1Val}
            onChange={(val) => {
              setCheckBox1Val(val)
            }}

          />
          <div className='signup-print signup-context'>
            I have read, understood, and agree to be bound by Alpaca Securities LLC and Tickerbot, LLC account terms, and all other terms, disclosures and disclaimers applicable to me, as referenced in the
            <span
              className='text-button margin-left-10'
              onClick={() => window.open('/alpaca_docs/AlpacaCustomerAgreement.pdf', '_blank')}
            >
              Customer Agreement
            </span>
            . I also acknowledge that the Alpaca Customer Agreement contains a pre-dispute arbitration clause in Section 43.
          </div>
        </div>
        <div className='row'>
          <Checkbox
            className='margin-top-25 margin-right-5'
            checkMarginTop={-3}
            value={checkBox2Val}
            onChange={(val) => {
              setCheckBox2Val(val)
            }}

          />
          <div className='signup-print signup-context'>
            I understand I am signing this agreement electronically, and that my electronic signature will have the same effect as physically signing and returning the Application Agreement.
          </div>
        </div>

        <Button
          display={'Submit'}
          className={'margin-top-25'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          isDisabled={!buttonEnabled || buttonIsLoading}
          isLoading={buttonIsLoading}
          onClick= {async () => {

            try {
              setButtonIsLoading(true)
              // Get the current date and IP address of client
              const timestamp = new Date()
              const timestampFormatted = timestamp.toISOString()
              const ipifyRes = await axios.get('https://api.ipify.org?format=json')
              const ipAddress = ipifyRes.data.ip

              // Create the agreement object for alpaca
              const agreementObj = {
                agreement: 'customer_agreement',
                signed_at: timestampFormatted,
                ip_address: ipAddress,
              }
              const agreementObj2 = {
                agreement: 'account_agreement',
                signed_at: timestampFormatted,
                ip_address: ipAddress,
              }
              const agreements = [agreementObj, agreementObj2]

              const data = {
                streetAddress: [streetAddress],
                unit: unit,
                city: city,
                state: state,
                postalCode: postalCode,
                countryOfTaxResidence: countryOfTaxResidence,
                countryOfCitizenship: countryOfCitizenship,
                taxIdType: 'USA_SSN',
                taxId: taxId,
                agreements: agreements
              }

              console.log(`TESTING: createAccount payload`)
              console.log(data)

              // Send the data to Alpaca (note the rest of the user object is pulled in the cloud function)
              const token = await currentUser()?.getIdToken()
              const url = `${config.api_root_url}createAccount`
              await axios.post(url, data, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
              })

              setButtonIsLoading(false)
            } catch (err) {
              setButtonIsLoading(false)
              setOpenModal( <ErrorModal errorMessage={err.response.data}/> )
            }

          }}
        />
        <Button
          display={'Back'}
          className={'margin-top-15'}
          variant={'secondary'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          onClick= {async () => { await db.collection('users').doc(loggedInUser.uid).update({signUpStage: previousSignUpStage}) }}
        />
      </div>
    </div>
  )

}