import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  formatNumberForTable,
  formatDateForTable,
  BACKGROUND,
  valueForTable,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  openModalAtom,
  transfersAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { TransferDetailsModal } from '../modals/TransferDetailsModal'
import { TransferDropdown } from '../dropdowns/TransferDropdown'
import { CustomGridOverlay } from '../tabs/CustomGridOverlay'
import { Backtest } from '../../types/backtest_types'
import { TableDropdown } from '../dropdowns/TableDropdown'
import { Icon } from '../reusable'


interface Transfer {
  id: string;
  type: string;
  direction: string;
  amount: number;
  fee: number;
  created_at: string;
  updated_at: string;
  status: string;
}

const SCROLL_THRESHOLD = 200;
const DEFAULT_PAGE_SIZE = 100;

interface TransfersTableProps {
  backtest?: Backtest | null
  width?: string
  height?: string
  paneIndex?: number

}
export const TransfersTable = (props: TransfersTableProps) => {
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [transfers, setTransfers] = useAtom(transfersAtom)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'created_at',
      sort: 'desc' as GridSortDirection
    },
  ]);
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)

  const containerRef = useRef<HTMLDivElement>(null);

  const loadMoreTransfers = useCallback(async () => {
    if (isLoadingMore || !hasMore || !uld) return;
    setIsLoadingMore(true);

    try {
      // TODO: Replace this with actual API call
      const response = await fetch(`/api/transfers?userId=${uld.id}&offset=${transfers?.length}&limit=${pageSize}`);
      const newTransfers: Transfer[] = await response.json();

      if (newTransfers.length === 0) {
        setHasMore(false);
      } else {
        setTransfers(prevTransfers => [...prevTransfers || [], ...newTransfers]);
      }
    } catch (error) {
      console.error('Error fetching more transfers:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, hasMore, uld, transfers?.length, pageSize, setTransfers]);

  useEffect(() => {
    const handleScroll = (params: any) => {
      const { scrollTop, scrollHeight, clientHeight } = params.currentTarget;

      if (scrollHeight - scrollTop - clientHeight < SCROLL_THRESHOLD) {
        loadMoreTransfers();
      }
    };

    const gridWrapperElement = containerRef.current?.querySelector('.MuiDataGrid-virtualScroller');
    if (gridWrapperElement) {
      gridWrapperElement.addEventListener('scroll', handleScroll);
      return () => gridWrapperElement.removeEventListener('scroll', handleScroll);
    }
  }, [loadMoreTransfers]);

  useEffect(() => {
    if (transfers?.length === 0 && !isLoadingMore) {
      loadMoreTransfers();
    }
  }, [transfers, isLoadingMore, loadMoreTransfers]);

  if (!uld) return null;

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
    // TODO: Implement server-side sorting if needed
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    if ((newPage + 1) * pageSize > (transfers || []).length && hasMore) {
      loadMoreTransfers();
    }
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
    if (newPageSize > (transfers || []).length && hasMore) {
      loadMoreTransfers();
    }
  }

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    if (get_contextmenu_off()) return
    event.preventDefault()
    const width = 220
    const clickX = openLeft? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    const symbol = params.row.symbol
    setOpenDropdown(
      <TransferDropdown
        transferObj={params.row}
        left={clickX}
        top={clickY}
        width={width}
        onClose={() => setOpenDropdown(null)}
      />
    )
  }

  const transferRows: Transfer[] = props.backtest ? props.backtest.transfers : transfers || []

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData

  let className = 'table-tab'  

  return (
    <div className={className} style={{width: props.width, height: props.height, minHeight: 0, position: 'relative'}} ref={containerRef}>
      {openDropdown}
      <div className='tab-header'>
        <div>Transfers table</div>
        <Icon 
          icon='gear'
          set='regular'
          size={14}
          style={{marginLeft: 12, marginTop: 1}}
          onClick={(e) => {      
            const rect = e.target.getBoundingClientRect()
            if (rect) {
              const width= 175
              const left = rect.left - width + 10
              const top = rect.bottom
              setOpenDropdown(<TableDropdown 
                left={left}
                top={top}
                width={width}
                onClose={() => setOpenDropdown(null)}
                paneIndex={props.paneIndex}
                table='transfers'
              />)
            }
          }}
        />
      </div>
      <div className='table-container'>
        <DataGridPro
          rows={transferRows}
          className='clickable-grid uppercase-grid'
          columns={[
            {
              field: 'type',
              headerName: 'type',
              width: 100,
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.type}
                </div>
              )
            },
            {
              field: 'direction',
              headerName: 'direction',
              width: 100,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.direction}
                </div>
              )
            },
            {
              field: 'status',
              headerName: 'status',
              width: 100,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params, true)
                  }}
                >
                  {params.row.status}
                </div>
              )
            },
            {
              field: 'amount',
              headerName: 'amount',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {valueForTable('amount', params.row.amount)}
                </div>
              )
            },            
            {
              field: 'fee',
              headerName: 'fee',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {valueForTable('fee', params.row.fee)}
                </div>
              )
            },
            {
              field: 'created_at',
              headerName: 'created_at',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row.created_at)}
                </div>
              )
            },
            {
              field: 'updated_at',
              headerName: 'updated_at',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row.updated_at)}
                </div>
              )
            },
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: () => (
              <CustomGridOverlay text={transferRows.length === 0 ? 'No transfers.' : 'Loading transfers...'} />
            ),
          }}
          sx={{
            ...tableSX,
          }}
          onRowClick={(params) => {
            const transferObj = params.row
            setOpenModal(<TransferDetailsModal transferObj={transferObj} />)}
          }
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          pagination
          paginationMode="client"
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          rowCount={transferRows?.length}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          localeText={{
            footerTotalRows: 'Total transfers:',
          }}
          hideFooter={true}
        />
      </div>
      {isLoadingMore && (
        <div style={{ padding: 10, textAlign: 'center' }}>
          Loading more...
        </div>
      )}
      {!hasMore && (transfers || []).length >= DEFAULT_PAGE_SIZE && (
        <div style={{ padding: 10, textAlign: 'center' }}>
          No more transfers.
        </div>
      )}
    </div>
  )
}