import axios  from 'axios'
import { useAtom } from 'jotai'
import {
  Icon,
  Input,
  Switch,
  showModal,
  DropdownSelect,
  CustomNoRowsOverlay,
  Button,
} from '../reusable'
import {
  activityTypes,
  tableSX,
  fundingSourceOptions,
  returnFinancialProfileMinMax,
  formatDateForTable
} from '../../logic/u'
import { User } from '../../types/user_types'
import { currentUser, logout } from '../../firebase'
import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { getConfig } from '../../config'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from '../modals/ErrorModal'

import { db } from '../../firebase'
import { DeleteAchRelationshipModal } from '../modals/DeleteAchRelationshipModal'
import { DocumentsTab } from './DocumentsTab'
// import { BasicInfoTab } from './SettingsTab'
import { SettingsTab } from './SettingsTab'

const config = getConfig() as any


export function SubscriptionTab() {
  return (
    <div className='tab-contents has-subtab'>
      <div className='bold header-text-size margin-bottom-10'>Your plan</div>
      <div className='row space-between body-text-size'>
        <div className='orange-text'>
          Formatted boxes for each plan; highlight the subscribed-to plan; cta's for each
        </div>
      </div>
      <div className='bold header-text-size margin-bottom-10 margin-top-40'>Billing method</div>
      <div className='row space-between body-text-size'>
        <div className='orange-text'>
          Active payment method with ability to change. Note this is not used for transfers.
        </div>
      </div>
      <div className='bold header-text-size margin-bottom-10 margin-top-40'>Invoices</div>
      <div className='table-container'>
        <DataGridPro
          rows={[]}
          columns={[
            { field: 'date', headerName: 'Date', flex: 1},
            { field: 'link', headerName: 'Link', flex: 1},
            { field: 'amount', headerName: 'Amount', flex: 2, headerAlign: 'right', align: 'right'},
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          hideFooter
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={tableSX}
        />
      </div>
    </div>
  )
}