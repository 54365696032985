import './BottomPane.scss';

import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { LogsPane } from './LogsPane'
import { loggedInUserAtom, openModalAtom, selectedEmptyPaneAtom, userLiveDocAtom } from '../../types/global_types'
import { Icon } from '../reusable';
import { db } from '../../firebase';
import { Variable } from '../../types/user_types';
import { returnPaneContextObj, set_open_pane, valueForTable } from '../../logic/u';
import { CreateVariableModal } from '../modals/CreateVariableModal';
import { CodeConsole } from '../strategies/CodeConsole';

export const BottomPane = () => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [tab, setTab] = useState('logs')

  if (!user) return null

  return (
    <div className='bottom-pane-outer'>
      <div className='handle' />
      <div className='bottom-pane-container'>
        <div className='bottom-pane-header'>
          <div className='row'>
            <div 
              className={tab === 'logs' ? 'header-tab selected' : 'header-tab'}
              onClick={() => setTab('logs')}
            >
              Logs
            </div>
            <div 
              className={tab === 'console' ? 'header-tab selected' : 'header-tab'}
              onClick={() => setTab('console')}
            >
              Console output
            </div>
          </div>
        </div>
        {tab === 'logs' ? <LogsPane /> : <CodeConsole />}
      </div>
      
    </div>
  )
}

