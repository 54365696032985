

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { consoleOutputAtom, deploymentsAtom, functionExecutingAtom, loggedInUserAtom, openModalAtom, strategiesAtom, userLiveDocAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { db } from '../../firebase'
import { run_function_once } from '../../logic/run_function_once'
import { Deployment, Strategy } from '../../types/user_types'
import { INTERVALS } from '../../logic/u'

interface CreateDeploymentModalProps {
  strategiesToDeploy: {[key: Strategy['id']]: boolean}
}

export const CreateDeploymentModal = (props: CreateDeploymentModalProps) => {
  const {strategiesToDeploy} = props
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)
  const [strategies, ] = useAtom(strategiesAtom)
  const [deployments, ] = useAtom(deploymentsAtom)
  const [name, setName] = useState<string>('')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Deployment name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Deployment name must not start with a number'
    }
  }

  useEffect(() => {
    setButtonEnabled(!!name && !errorMessage)
  }, [name, errorMessage]);

  if (!user) return null

  const content = (
    <div className='column width-full'>

      {/* Name input */}
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_').toLowerCase().trim();
          modified = modified.slice(0, 50);
          setName(modified);
        }}
        autoFocus={true}
      />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}      
      
      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title={'Create deployment'}
      contents={[content]}
      yesButtonText={'Create'}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={ async () => {
        try {
          setButtonIsLoading(true)

          // New codepath
          let highestIndex = 0
          deployments.forEach(s => {
            if (s.index > highestIndex) {
              highestIndex = s.index
            }
          })

          interface DeploymentFunctionMetadata {
            strategy_id: string
            function_name: string
            interval: string
          }

          // Get the code, interval, and function_metadata for each strategy
          const liveCodeToDeploy = {}
          const strategiesByMinInterval = {}
          const function_metadata: DeploymentFunctionMetadata[] = []
          const all_intervals: string[] = []
          Object.keys(strategiesToDeploy).forEach(s => {
            const strategy = strategies.find(strat => strat.id === s)
            const code = strategy?.code

            // Build function_metadata
            let strategy_min_interval_ms = 12 * 30 * 24 * 60 * 60 * 1000
            let strategy_min_interval = '12mo'
            strategy?.function_metadata?.forEach((m) => {
              if (m.type === 'on_interval') {
                const interval = m.params?.interval
                if (all_intervals.indexOf(interval) === -1) {
                  all_intervals.push(interval)
                }
                const interval_ms = INTERVALS[interval]
                if (interval_ms < strategy_min_interval_ms) {
                  strategy_min_interval_ms = interval_ms
                  strategy_min_interval = interval
                }
                function_metadata.push({
                  strategy_id: s,
                  function_name: m.name,
                  interval,
                })
              }
            })
            liveCodeToDeploy[s] = code
            strategiesByMinInterval[s] = strategy_min_interval
          })


          // Find the overall minimum interval
          let min_interval = '12mo'
          let min_interval_ms = 12 * 30 * 24 * 60 * 60 * 1000
          Object.keys(strategiesByMinInterval).forEach(s => {
            const interval_ms = INTERVALS[strategiesByMinInterval[s]]
            if (interval_ms < min_interval_ms) {
              min_interval_ms = interval_ms
              min_interval = strategiesByMinInterval[s]
            }
          })

          // Create deployment object
          const newDeployment: Omit<Deployment, 'id'> = { // omit 'id' for now
            userId: user.uid,
            createdAt: new Date(),
            index: highestIndex + 1,
            name: name,
            liveCode: liveCodeToDeploy,
            minInterval: min_interval,
            strategiesByMinInterval,
            functionMetadata: function_metadata
          }
          const newDeploymentRef = await db.collection('users')
            .doc(user.uid)
            .collection('deployments')
            .add(newDeployment)
          const deploymentId = newDeploymentRef.id

          // Now update the strategy object with the generated id
          const deploymentWithId: Deployment = {
            ...newDeployment,
            id: deploymentId,
          }
          await db.collection('users')
            .doc(user.uid)
            .collection('deployments')
            .doc(deploymentId)
            .set(deploymentWithId)

          // Update user's current deployment
          await db.collection('users')
            .doc(user.uid)
            .update({
              liveDeploymentId: deploymentId,
              liveDeploymentMinInterval: min_interval,
              liveDeploymentRunIntervals: all_intervals,
            })

          console.log('created deployment')
          console.log(deploymentWithId)

          updateLiveData(user)
          setButtonIsLoading(false)
        } catch (err) {
          setButtonIsLoading(true)
          setOpenModal(<ErrorModal errorMessage={err}/>)
        }
      }}
    />
  )
}

