import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { db } from '../../firebase'
import { CancelBacktestModal } from '../modals/CancelBacktestModal'
import { DeleteBacktestModal } from '../modals/DeleteBacktestModal'
import { Backtest } from '../../types/backtest_types'

interface BacktestDropdownProps {
  backtestObj: Backtest
  left: number
  top: number
  width: number
  onClose: () => void
}

export const BacktestDropdown = (props: BacktestDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const {backtestObj, left, top, width, onClose} = props

  if (!user) return null

  interface DropdownItem {
    display: JSX.Element
    isDisabled?: boolean
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  contents.push(    
    {
      display: (
        <div className='font-size-10 gray-text'>
          {backtestObj.name}
        </div>
      )
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='ban'
            set='sharp-solid'
            size={10}
            style={{marginRight: 10, marginTop: .5}}
            className={['completed', 'failed'].includes(backtestObj.status) ? 'menu-item-icon-disabled' : ''}
          />
          <div>Cancel</div>
        </div>
      ),
      isDisabled: ['completed', 'failed'].includes(backtestObj.status),
      onClick: () => {
        setOpenModal(<CancelBacktestModal backtestObj={backtestObj} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='trash'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10, marginTop: .5}}
          />
          <div>Delete</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<DeleteBacktestModal backtestObj={backtestObj} />)
      }
    }
  )
  
  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}