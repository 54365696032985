import './RightPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  loggedInUserAtom,
} from '../../types/global_types'
import { PositionsPane } from './PositionsPane'
import { OrdersPane } from './OrdersPane'
import { TransfersPane } from './TransfersPane'
import { RightPaneDropdown } from '../dropdowns/RightPaneDropdown';
import { Icon } from '../reusable';

export const RightPane = () => {
  const [ loggedInUser ] = useAtom(loggedInUserAtom)
  const [ selectedFirstPane, setSelectedFirstPane ] = useState<string>('positions')
  const [ selectedSecondPane, setSelectedSecondPane ] = useState<string>('orders')
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const ellipsesDropdownRef = useRef<HTMLDivElement>(null);
  const ellipsesDropdownBottomPaneRef = useRef<HTMLDivElement>(null);


  if (!loggedInUser) return null


  // Draggable split, set to user
  useEffect(() => {
    const returnSplitToDefault = () => {
      db.collection('users').doc(loggedInUser.uid).update({ rightPaneDividerSplit: [50, 50] });
    };

    const gutterElement = document.querySelector('.right-pane-split .gutter-vertical');

    if (gutterElement) {
      gutterElement.addEventListener('dblclick', returnSplitToDefault);
    }

    return () => {
      if (gutterElement) {
        gutterElement.removeEventListener('dblclick', returnSplitToDefault);
      }
    };
  }, [loggedInUser]);

  let rightPaneDividerSplit = loggedInUser?.rightPaneDividerSplit || [50, 50]

  return (
    <div className={'right-pane'}>
      {openDropdown}
      <Split
        className="right-pane-split"
        direction="vertical"
        sizes={rightPaneDividerSplit}
        minSize={37}
        gutterSize={5}
        gutterAlign='center'
        cursor='row-resize'
        onDragEnd={(val) => {db.collection('users').doc(loggedInUser.uid).update({rightPaneDividerSplit: val})}}
      >
        <div className='section'>
          <div className='header'>
            <div className='row'>
              <div
                className={selectedFirstPane === 'positions' ? 'header-tab selected' : 'header-tab'}
                onClick={() => {setSelectedFirstPane('positions')}}
              >
                Positions
              </div>
            </div>
            <div             
              ref={ellipsesDropdownRef}
              className='pointer'
              onClick={(e) => {
                e.stopPropagation()
                if (ellipsesDropdownRef.current) {
                  const rect = ellipsesDropdownRef.current.getBoundingClientRect(); // Get the position of the element
                  const width = 250
                  setOpenDropdown(
                    <RightPaneDropdown 
                      left={rect.left - width + rect.width}
                      top={rect.bottom + 5}
                      width={width}
                      onClose={() => setOpenDropdown(null)}
                      pane='positions'
                    />
                  );               
                }
              }}
              
            >
              <Icon
                icon='ellipsis'
                set='sharp-solid'
                size={14}
                style={{marginTop: -.5}}
                className='section-icon'
                onClick={() => {
                  // for hover
                }}
              />
            </div>
          </div>
          <PositionsPane />
        </div>
        <div className='section'>
          <div className='header'>
            <div className='handle' />
            <div className='row'>
              <div
                className={selectedSecondPane === 'orders' ? 'header-tab selected' : 'header-tab'}
                onClick={() => {setSelectedSecondPane('orders')}}
              >
                Orders
              </div>
              <div
                className={selectedSecondPane === 'transfers' ? 'header-tab selected' : 'header-tab'}
                onClick={() => {setSelectedSecondPane('transfers')}}
              >
                Transfers
              </div>
            </div>
            <div             
              ref={ellipsesDropdownBottomPaneRef}
              className='pointer'
              onClick={(e) => {
                e.stopPropagation()
                if (ellipsesDropdownBottomPaneRef.current) {
                  const rect = ellipsesDropdownBottomPaneRef.current.getBoundingClientRect(); // Get the position of the element
                  const width = 250
                  setOpenDropdown(
                    <RightPaneDropdown 
                      left={rect.left - width + rect.width}
                      top={rect.bottom + 5}
                      width={width}
                      onClose={() => setOpenDropdown(null)}
                      pane=''
                    />
                  );               
                }
              }}
              
            >
              <Icon
                icon='ellipsis'
                set='sharp-solid'
                size={14}
                style={{marginTop: -.5}}
                className='section-icon'
                onClick={() => {
                  // for hover
                }}
              />
            </div>
          </div>
          {selectedSecondPane === 'orders' ? <OrdersPane /> : <TransfersPane />}
        </div>
      </Split>
    </div>
  )
}






