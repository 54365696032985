import { useAtom } from 'jotai'
import React, { useState } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import {
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  returnPaneContextObj
} from '../../logic/u'
import { Icon } from '../reusable'
import { TickerDropdown } from '../dropdowns/TickerDropdown';
import { TickerModal } from '../modals/TickerModal';

export const PositionsPane = () => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)


  if (!user) return null
  if (!uld) return null

  if (Object.keys(uld.liveData?.POSITIONS).length) {
    return (
      <div className='sect-body'>
        {openDropdown}
        {Object.keys(uld.liveData?.POSITIONS).sort().map(p => {
          const position = uld.liveData?.POSITIONS[p]
          const chart = position.symbol
          const chartIcon = 'square-' + position.symbol.charAt(0)
          const rounded_qty = roundToSigFigs(position.qty, position.avg_entry_price)
          const paneContextObj = returnPaneContextObj(user, chart)
          const paneThingIsVisible = paneContextObj?.paneThingIsVisible
          let divClass = 'entry'
          if (paneThingIsVisible) {
            divClass += ' selected'
          }
          return (
            <div
              className={divClass}
              onClick={() => {
                setOpenModal(<TickerModal symbol={position.symbol}  />)
              }}
              onContextMenu={(event) => {
                event.preventDefault()
                const width = 220
                const clickX = event.clientX - (width + 5)
                const clickY = event.clientY - 10
                setOpenDropdown(
                  <TickerDropdown
                    symbol={position.symbol}
                    left={clickX}
                    top={clickY}
                    width={width}
                    onClose={() => setOpenDropdown(null)}
                    fromRightPane={true}
                  />
                )
              }}
            >
              <div className='column'>
                <div className='row space-between'>
                  <div className='row'>
                    <Icon
                      icon={chartIcon}
                      set='sharp-solid'
                      size={12}
                      className={'key-icon'}
                      style={{marginTop: 2, marginRight: 5}}
                    />
                    <div className='orange-text'>{position.symbol}</div>
                  </div>
                  <div className={position.unrealized_pl >= 0 ? ' green-tag' : ' red-tag'}>{formatNumberForTable(position.unrealized_pl)}{' [' + formatPercent(position.unrealized_pl_pc) + ']'}</div>
                </div>
                <div className='row space-between font-size-11'>
                  <div className='orange-text'>{rounded_qty} @ {formatNumberForTable(position.avg_entry_price)}</div>
                  <div className={'orange-text'}>{formatNumberForTable(position.mkt_value)}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div className='sect-body'>
      <div
        style={{marginTop: 8, marginLeft: 10}}
        className='orange-text font-size-11'
      >
        No positions.
      </div>
    </div>
  )
}