import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
  TEXT_GRAY_LIGHT,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom
} from '../../../types/global_types'
import { getConfig } from '../../../config'
import { TickerDropdown } from '../../dropdowns/TickerDropdown'
import { TickerModal } from '../../modals/TickerModal'
import { Backtest } from '../../../types/backtest_types'
import { Icon } from '../../reusable'
import { CreateOrderModal } from '../CreateOrderModal'
import { ClosePositionModal } from '../ClosePositionModal'
import { CancelOpenOrderModal } from '../CancelOpenOrderModal'
const config = getConfig() as any

interface TickerModalActionBtnsProps {
  symbol: string
  orderId?: string
  backtest?: Backtest | null
}
export const TickerModalActionBtns = (props: TickerModalActionBtnsProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  if (!user || !uld) return null

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData
  const {symbol, orderId} = props

  return (<div className='row' style={{marginTop: 0}}>
    <div
      className='row font-size-10'
      style={{
        marginRight: 15,        
        color: TEXT_GRAY_LIGHT
      }}
    >
      <div className='hover-underline' style={{marginTop: 5}}>
        Open full_chart
      </div>        
    </div>            
    <div className='row' style={{marginTop: -3}}>  
      <Icon
        hoverText='Buy'
        icon='circle-arrow-up'
        set='sharp-solid'
        size={13}
        style={{marginRight: 14, marginTop: 3}}
        onClick={() => {
          setOpenModal(<CreateOrderModal symbol={symbol} showBack={true} orderId={orderId}  />)
        }}
      /> 
      <Icon
        hoverText='Sell'
        icon='circle-arrow-down'
        set='sharp-solid'
        size={13}
        style={{marginRight: 15, marginTop: 3}}
        onClick={() => {
          setOpenModal(<CreateOrderModal symbol={symbol} sell={true} showBack={true} orderId={orderId} />)                  
        }}
      />       
      <Icon
        hoverText='Cancel all open orders'
        icon='octagon-minus'
        set='sharp-solid'
        size={13}
        style={{marginRight: 15, marginTop: 3}}
        onClick={() => {
          setOpenModal(<CancelOpenOrderModal symbol={symbol} showBack={true} />)
        }}
      /> 
      <Icon
        hoverText='Close position'
        icon='folder-closed'
        set='sharp-solid'
        size={13}
        style={{marginRight: 0, marginTop: 3.5}}
        onClick={() => {
          setOpenModal(<ClosePositionModal symbol={symbol} showBack={true}/>)                  
        }}
      />               
    </div>
  </div>
  )
}