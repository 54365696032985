/*
Generated from user_lib.js, to feed Monaco editor's built-in linter.
*/

export const libSource = `
declare module 'tb' {

  // Interfaces

  export interface Position {
    asset_class(): string;
    avg_entry_price(): number;
    cost_basis(): number;
    current_price(): number;
    day_price_change(): number;
    day_unrealized_pl(): number;
    day_unrealized_pl_pc(): number;
    exchange(): string;
    last_price(): number;
    mkt_value(): number;
    qty(): number;
    qty_available(): number;
    symbol(): string;
    unrealized_pl(): number;
    unrealized_pl_pc(): number;
  }

  export interface Chart {
    add_support_line(): string;
    add_resistance_line(): string;
    add_trendline(): string;
    add_ray(): string;
    add_rectangle(): string;
    add_vertical_line(): string;
    add_point(): string;
    reset(): string;
    remove_annotation(): string;
    clear_annotations_by_type(): string;
  }

  export interface TechnicalIndicators {
    sma(
      interval: '1m' | '5m' | '30m' | '1h' | '2h' | '4h' | '1d' | '1w',
      period: number
    ): Promise<any>;
    ema(
      interval: '1m' | '5m' | '30m' | '1h' | '2h' | '4h' | '1d' | '1w',
      period: number
    ): Promise<any>;
    boll(
      interval: '1m' | '5m' | '30m' | '1h' | '2h' | '4h' | '1d' | '1w',
      period: number,
      stddev: number
    ): Promise<any>;
    vwap(
      interval: '1m' | '5m' | '30m' | '1h' | '2h' | '4h' | '1d' | '1w',
      period: number
    ): Promise<any>;
    rsi(
      interval: '1m' | '5m' | '30m' | '1h' | '2h' | '4h' | '1d' | '1w',
      period: number
    ): Promise<any>;
    macd(
      interval: '1m' | '5m' | '30m' | '1h' | '2h' | '4h' | '1d' | '1w',
      fastPeriod: number,
      slowPeriod: number,
      signalPeriod: number
    ): Promise<any>;
  }

  export interface Ticker {
    ticker: string;
    chart(): Chart;
    technical_indicators(): TechnicalIndicators;
    price(): number;
    day_open(): number;
    day_high(): number;
    day_low(): number;
    day_close(): number;
    day_volume(): number;
    last_close(): number;
    get_bars(
      interval: '1m' | '5m' | '30m' | '1h' | '2h' | '4h' | '1d' | '1w',
      limit: number
    ): Promise<any>;
  }

  // Exports

  export const tb: {
    marco(): void;
    log(message: any): void;
    get_market_time(): string;
    send_email(email: string, subject: string, body: string): Promise<void>;
    end_backtest(): string;
    turn_off_strategy(): string;
    alert(message: string): string;
    ticker(ticker: string): Ticker;
    watchlists: {
      create(name: string): Promise<void>;
      delete(name: string): Promise<void>;
      get(name: string): Promise<any[]>;
      add_ticker(watchlist: string, ticker: string): Promise<void>;
      remove_ticker(watchlist: string, ticker: string): Promise<void>;
      reset_tickers(watchlist: string): Promise<void>;
    };
    state: {
      set(key: string, value: any): Promise<void>;
      get(key: string): Promise<any>;
    }
  };

  export const portfolio: {
    balances: {
      cash(): number;
      mkt_value(): number;
      equity(): number;
      day_unrealized_pl(): number;
      day_realized_pl(): number;
      day_pl(): number;
      day_equity_change(): number;
      day_unrealized_pl_pc(): number;
      day_realized_pl_pc(): number;
      day_pl_pc(): number;
      day_equity_change_pc(): number;
      unrealized_pl(): number;
      realized_pl(): number;
      pl(): number;
      unrealized_pl_pc(): number;
      realized_pl_pc(): number;
      pl_pc(): number;
      long_mkt_value(): number;
      net_deposits(): number;
      cost_basis(): number;
      buying_pwr(): number;
      cash_on_orders(): number;
      daytrading_buying_pwr(): number;
      daytrade_count(): number;
    },
    get_positions(): Record<string, any>;
    get_position(ticker: string): any;
    get_open_orders(ticker?: string): Promise<any[]>;
    get_recent_orders(ticker?: string): Promise<any[]>;
    get_transfers(): Promise<any[]>;
    position(ticker: string): Promise<Position>;

    deposit(amount: number): Promise<void>;
    withdraw(amount: number): Promise<void>;

    order(
      ticker: string,
      side: 'buy' | 'sell',
      type: 'market' | 'limit' | 'stop' | 'stop_limit',
      args: {
        qty?: number;
        notional?: number;
        limit_price?: number;
        stop_price?: number;
        time_in_force?: string;
      }
    ): Promise<void>;

    close_position(ticker: string): Promise<void>;
    close_all_positions(): Promise<void>;
    cancel_order(orderId: string): Promise<void>;
    cancel_orders_for_ticker(ticker: string): Promise<void>;
    cancel_all_orders(): Promise<void>;
    cancel_orders_of_type(type: 'market' | 'limit' | 'stop' | 'stop_limit'): Promise<void>;
  };

  export const strategy: {
    on_interval(
      name: string,
      interval: '1m' | '5m' | '30m' | '1h' | 'open',
      callback: () => void
    ): void;
    on_trigger(name: string, callback: () => void): void;
  };

  export const ai: {
    get_tickers(prompt: string, data: any): Promise<string[]>;
    get_likelihood(prompt: string, data: any): Promise<number>;
    get_boolean(prompt: string, data: any): Promise<boolean>;
    get_number(prompt: string, data: any): Promise<number>;
    get_number_array(prompt: string, data: any): Promise<number[]>;
    get_json(prompt: string, data: any): Promise<object>;
    get_string(prompt: string): Promise<string>;
  };

  export const utils: {
    is_market_open(): boolean;
    get_minutes_since_open(): number;
    get_minutes_until_close(): number;
    get_minutes_until_open(): number;
    get_last_market_day(): string;
  };
}
`;

const REMOVED_FROM_STRATEGY = `
state: {
  set(key: string, value: any): Promise<void>;
  get(key: string): Promise<any>;
};
watchlist: {
  add_ticker(ticker: string): Promise<void>;
  remove_ticker(ticker: string): Promise<void>;
  reset_tickers(): Promise<void>;
};
`