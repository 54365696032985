import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  deploymentsAtom,
  strategiesAtom
} from '../../../types/global_types'
import { getConfig } from '../../../config'
import { TickerDropdown } from '../../dropdowns/TickerDropdown'
import { TickerModal } from '../TickerModal'
import { CustomGridOverlay } from '../../tabs/CustomGridOverlay'
import { EELog } from '../../../types/user_types'
import { EvaluationRow } from '../../reusable/LogRow'
const config = getConfig() as any
import { db } from "../../../firebase"

interface LogModalCodeTabProps {
  log: EELog
}

export const LogModalCodeTab = (props: LogModalCodeTabProps) => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ deployments, ] = useAtom(deploymentsAtom)
  const [ strategies, ] = useAtom(strategiesAtom)

  const {log} = props
  const [fullLog, setFullLog] = useState<EELog | null>(null)
  const [code, setCode] = useState<string>('Loading...')

  const deployment = deployments.find(d => d.id === log.deployment_id)

  if (!user || !uld) return null

  console.log(log)

  // Load actual EELog, so as to be able to get the code
  useEffect(() => {

    // Get EELog doc from collection on user
    const logRef = db.collection('users')
      .doc(user.uid)
      .collection('EELogs')
      .doc(log.id)

    logRef.get().then(doc => {
      if (doc.exists) {
        const full_log_data = doc.data() as EELog
        setFullLog(full_log_data)

        // Extract the code that specifically ran
        let _code = full_log_data._code || ''
        if (log.function_name) {
          _code = extract_function(log.function_name, _code)
        }
        setCode(_code)
      }
    })

  }, [log])


  if (!log.deployment_id && !log._code) {
    return <>
      <div className='generic-content-container modal-version'
        style={{
          overflowY: 'scroll'
        }}
      >
        <pre style={{margin: 0}}><code>
          {code || 'N/A'}
        </code></pre>
      </div>
    </>
  }


  return <>
    <div className='generic-content-container modal-version'
      style={{
        overflowY: 'scroll'
      }}
    >
      {log.deployment_id ? <>
        {Object.keys(deployment?.liveCode || []).map(s_id => {
          const strategy = strategies.find(s => s.id === s_id)
          return <>
            <div style={{marginBottom: 7, fontWeight: 'bold'}}>{'Strategy: ' + strategy?.name || '[deleted]'}</div>
            <pre style={{margin: 0}}><code>
              {deployment?.liveCode[s_id]}
            </code></pre>
          </>
        })}
      </> :
        <pre style={{margin: 0}}><code>
          {log._code}
        </code></pre>
      }
    </div>
  </>
}


// NOTE: CONSIDER PUTTING THIS IN THE BACKEND, SAVING ONLY RELEVANT CODE
const extract_function = (function_name, code) => {
  // Patterns to look for
  const patterns = [
    new RegExp(`strategy\\.on_trigger\\s*\\(\\s*['"\`]${function_name}['"\`]\\s*,`, 'g'),
    new RegExp(`strategy\\.on_interval\\s*\\(\\s*['"\`]${function_name}['"\`]\\s*,`, 'g'),
  ];

  let match: any = null;

  // Find the matching pattern in the code
  for (let pattern of patterns) {
    match = pattern.exec(code);
    if (match) {
      break;
    }
  }

  if (!match) {
    // Function not found
    return null;
  }

  const idx = match.index;

  // Helper function to find the start of the function body
  function findFunctionStart(code, startIdx) {
    let pos = startIdx;
    let openParens = 0;
    let inString = false;
    let stringChar = '';

    while (pos < code.length) {
      let ch = code[pos];
      if (inString) {
        if (ch === stringChar) {
          inString = false;
        } else if (ch === '\\') {
          pos++; // Skip escaped character
        }
      } else {
        if (ch === '"' || ch === "'" || ch === '`') {
          inString = true;
          stringChar = ch;
        } else if (ch === '(') {
          openParens++;
        } else if (ch === ')') {
          openParens--;
          if (openParens === 0) {
            break;
          }
        }
      }
      pos++;
    }

    if (openParens !== 0) {
      // Mismatched parentheses
      return -1;
    }

    // Find '=> {'
    const restCode = code.substring(pos);
    const funcDefRegex = /(\s*=>\s*{)/;
    const funcDefMatch = funcDefRegex.exec(restCode);

    if (!funcDefMatch) {
      return -1;
    }

    return pos + funcDefMatch.index + funcDefMatch[0].length - 1; // Position of '{'
  }

  const funcBodyStart = findFunctionStart(code, idx);

  if (funcBodyStart === -1) {
    return null;
  }

  // Scan and count braces to find the end of the function
  let openBraces = 1;
  let pos = funcBodyStart + 1;
  let inString = false;
  let stringChar = '';
  let inSingleLineComment = false;
  let inMultiLineComment = false;

  while (openBraces > 0 && pos < code.length) {
    let ch = code[pos];

    if (inString) {
      if (ch === stringChar) {
        inString = false;
      } else if (ch === '\\') {
        pos++; // Skip escaped character
      }
    } else if (inSingleLineComment) {
      if (ch === '\n') {
        inSingleLineComment = false;
      }
    } else if (inMultiLineComment) {
      if (ch === '*' && code[pos + 1] === '/') {
        inMultiLineComment = false;
        pos++;
      }
    } else {
      if (ch === '"' || ch === "'" || ch === '`') {
        inString = true;
        stringChar = ch;
      } else if (ch === '/' && code[pos + 1] === '/') {
        inSingleLineComment = true;
        pos++;
      } else if (ch === '/' && code[pos + 1] === '*') {
        inMultiLineComment = true;
        pos++;
      } else if (ch === '{') {
        openBraces++;
      } else if (ch === '}') {
        openBraces--;
      }
    }

    pos++;
  }

  if (openBraces === 0) {
    // Extract the function code
    const extractedFunction = code.substring(idx, pos);
    return extractedFunction;
  } else {
    // Matching closing brace not found
    return null;
  }
}
