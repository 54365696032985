import './DividerPane.scss'

import React, { useRef, useEffect, useState } from 'react';
import { TBChart } from '../TBChart/TBChart'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedEmptyPaneAtom,
  strategiesAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { PositionsTable } from '../tables/PositionsTable';
import { CodeEditorWrapper } from '../strategies/CodeEditorWrapper';
import { WatchlistTable } from '../tables/WatchlistTable';
import { OrdersTable } from '../tables/OrdersTable';
import { TransfersTable } from '../tables/TransfersTable';
import { TransactionsTable } from '../tables/TransactionsTable';
import { BalancesTable } from '../tables/BalancesTable';
import { LogsTable } from '../tables/LogsTable';
import { StateTable } from '../tables/StateTable';
import { DeploymentsTable } from '../tables/DeploymentsTable';

export const DividerPane = ()=> {

  const [user] = useAtom(loggedInUserAtom)
  const [selectedPane, setSelectedPane] = useAtom(selectedEmptyPaneAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [strategies, setStrategies] = useAtom(strategiesAtom)


  if (!user) return null
  const configuration = user.paneSettingsConfig || '1'

  const get_placeholder = (paneNo: number) => {
    const sel_msg = 'Choose strategy, chart, or table...'
    const empty_msg = 'Click here'
    return (
      <div
        className={`empty-slot ${selectedPane === paneNo ? 'selected' : ''}`}
        onClick={() => {
          if (selectedPane === paneNo) {
            setSelectedPane(0)
          } else {
            setSelectedPane(paneNo)
          }
        }}
      >
        <div className='empty-slot-header'>
        </div>
        <div className='empty-slot-box'>
          <div>{selectedPane === paneNo ? sel_msg : empty_msg}</div>
        </div>
      </div>
    )
  }

  const get_pane = (paneCode: string, paneNo: number) => {
    if (!paneCode) return get_placeholder(paneNo)      

    // let width = measuredWidth
    let width = 'calc(100% - 2px)'
    let height = measuredHeight + 'px'
    if (['4', '2-one-left-one-right'].includes(configuration)) {
      width = '50%'
    }
    if (['2-one-top-one-bottom', '4'].includes(configuration)) {
      height = `${(measuredHeight / 2) - 5}px`
    }

    if (paneCode.startsWith('strategy.')) {
      const strategyId = paneCode.split('.')[1]
      return <CodeEditorWrapper width={width} height={height} strategy={strategies.find(s => s.id === strategyId)} paneIndex={paneNo} />
    }

    if (paneCode.startsWith('watchlist.')) {
      const tableId = paneCode.split('.')[1]
      return <WatchlistTable width={width} height={height} watchlistId={tableId} paneIndex={paneNo}/>
    }

    if (paneCode.startsWith('table.')) {
      const table = paneCode.split('.')[1]
      if (table === 'positions') {
        return <PositionsTable width={width} height={height} paneIndex={paneNo} />
      }
      if (table === 'orders') {
        return <OrdersTable width={width} height={height} paneIndex={paneNo} />
      }
      if (table === 'transfers') {
        return <TransfersTable width={width} height={height} paneIndex={paneNo} />
      }
      if (table === 'transactions') {
        return <TransactionsTable width={width} height={height} paneIndex={paneNo} />
      }
      if (table === 'balances') {
        return <BalancesTable width={width} height={height} paneIndex={paneNo} />
      }
      if (table === 'logs') {
        return <LogsTable width={width} height={height} paneIndex={paneNo} />
      }
      if (table === 'state') {
        return <StateTable width={width} height={height} paneIndex={paneNo} />
      }
      if (table === 'deployments') {
        return <DeploymentsTable width={width} height={height} paneIndex={paneNo} />
      }
    }

    // TODO: handle things that are not symbols
    return (
      <>
        <TBChart
          symbol={paneCode}
          chartIndex={paneNo}
          width={width}
          height={height}
        />
      </>
    )
  }




  // Figure out how much space we have to work with
  const dividerPaneRef = useRef<HTMLDivElement>(null)
  const [measuredHeight, setMeasuredHeight] = useState(0)
  const [measuredWidth, setMeasuredWidth] = useState(0)
  useEffect(() => {
    const measureSize = () => {
        if (dividerPaneRef.current) {
            const { width, height } = dividerPaneRef.current.getBoundingClientRect()
            setMeasuredHeight(height)
            setMeasuredWidth(width)
        }
    }
    measureSize()

    const resizeObserver = new ResizeObserver(measureSize)
    if (dividerPaneRef.current) {
        resizeObserver.observe(dividerPaneRef.current)
    }

    window.addEventListener("resize", measureSize)

    return () => {
        if (dividerPaneRef.current) {
            resizeObserver.unobserve(dividerPaneRef.current)
        }
        window.removeEventListener("resize", measureSize)
    }
}, [dividerPaneRef.current])


  if (configuration === '1') {
    return (
      <div className='divider-pane' ref={dividerPaneRef}>
        {get_pane(user.paneSettingsCH1, 1)}
      </div>
    )
  } else if (configuration === '2-one-top-one-bottom') {
    return (
    <div className='divider-pane' ref={dividerPaneRef}>
      {get_pane(user.paneSettingsCH1, 1)}
      {get_pane(user.paneSettingsCH2, 2)}
    </div>
    )
  } else if (configuration === '2-one-left-one-right') {
    return (
    <div className='divider-pane' ref={dividerPaneRef}>
      <div className='divider-pane-row'>
        {get_pane(user.paneSettingsCH1, 1)}
        {get_pane(user.paneSettingsCH2, 2)}
      </div>
    </div>
    )
  } else if (configuration === '4') {
    return (
    <div className='divider-pane' ref={dividerPaneRef}>
      <div className='divider-pane-row'>
        {get_pane(user.paneSettingsCH1, 1)}
        {get_pane(user.paneSettingsCH2, 2)}
      </div>
      <div className='divider-pane-row'>
        {get_pane(user.paneSettingsCH3, 3)}
        {get_pane(user.paneSettingsCH4, 4)}
      </div>
    </div>
    )
  } else {
    return null
  }


}

