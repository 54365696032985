import React, { useState } from 'react'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  ordersAtom,
  deploymentsAtom,
  strategiesAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { BORDER_INPUT, SECOND_SURFACE, TEXT_GRAY_LIGHT, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, valueForTable, roundToSigFigs } from '../../logic/u'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { Icon } from '../reusable'
import { CreateOrderModal } from './CreateOrderModal'
import { ClosePositionModal } from './ClosePositionModal'
import { CancelOpenOrderModal } from './CancelOpenOrderModal'
import { Deployment } from '../../types/user_types'
import { TakeDownDeploymentModal } from './TakeDownDeploymentModal'

interface DeploymentDetailsModalProps {
  deployment: Deployment
}

export const DeploymentDetailsModal = (props: DeploymentDetailsModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [deployments, ] = useAtom(deploymentsAtom)
  const [strategies, ] = useAtom(strategiesAtom)

  const { deployment } = props

  const isLive = user?.liveDeploymentId === deployment.id

  if (!user || !uld || !deployment) return null

  return (
    <>
      <div
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />      
      <div
        className='modal'
        style={{
          height: 675,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        {openDropdown}
        <div className='top'>
          <div className='title-row'>
            <div className='row'>
              <div>
                Deployment: {deployment?.name}
              </div>              
            </div>
            <div className='row'>    
              {isLive && <div
                className='text-button gray font-size-10'
                onClick={() => {
                  setOpenModal(<TakeDownDeploymentModal deployment={deployment} />)
                }}
              >
                TAKE DOWN
              </div>}                        
            </div>
          </div>
          <div className='modal-body'>
            <div className='column' style={{width: '100%', gap: 10, height: '100%'}}>
              {Object.keys(deployment?.liveCode).map(s => {
                console.log(deployment)
                const name = strategies.find(strat => strat.id === s)?.name || `[deleted]`
                const code = deployment.liveCode[s]
                return <div className='generic-content-container modal-version min-height'>
                  <div style={{marginBottom: 7, fontWeight: 'bold'}}>{'Strategy: ' + name}</div>
                  <pre style={{margin: 0}}><code>
                    {code.length ? code : '-'}
                  </code></pre>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}