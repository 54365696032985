import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
  selectedEmptyPaneAtom,
  strategiesAtom,
  appTabAtom,
} from '../../types/global_types'
import { valueForTable, GREEN_PARTIAL, RED_PARTIAL, returnPaneContextObj, set_open_pane, formatDateForTable, timeAgo, get_contextmenu_off } from '../../logic/u'
import { Icon } from '../reusable'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Chart, Variable, WatchlistObj } from '../../types/user_types'
import { VariableDropdown } from '../dropdowns/VariableDropdown';
import { CreateVariableModal } from '../modals/CreateVariableModal';
import { WatchlistSelectorDropdown } from '../dropdowns/WatchlistsSelectorDropdown';
import { StateSelectorDropdown } from '../dropdowns/StateSelectorDropdown';

export const NewStateList = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)
  const [variables, setVariables] = useState<Variable[]>([])
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const selectorDropdownRef = useRef<HTMLDivElement>(null);
  const [strategies, setStrategies] = useAtom(strategiesAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)



  // Render tree
  useEffect(() => {
    let variablesToRender: Variable[] = []
    variablesToRender = Object.keys(user?.variables || []).map(v => {
      const variable = user?.variables[v] as Variable
      return variable
    }) || []
    variablesToRender.sort((a, b) => a.index - b.index);
    setVariables(variablesToRender)
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let variablesClone = Array.from(variables)

    // Reorder
    const [reorderedItem] = variablesClone.splice(source.index, 1) // remove dragged item
    variablesClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setVariables(variablesClone) // update local state to smooth transition

    // Turn it back to object
    const variablesObj = {}
    variablesClone.forEach((v, i) => {
      v.index = i
      variablesObj[v.symbol] = v
    })

    db.collection('users').doc(user?.uid).update({ variables: variablesObj })
  }

  if (!user) return null
  if (!uld) return null

  return <>
    {openDropdown}
    <div className='section-body'>
      {Object.keys(variables).length ? <DragDropContext
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
        key={'modal-content'}
      >
        <div className='droppable-container'>
          <Droppable droppableId={'1'} type='bucket'>
            {(provided, snapshot) => (
              <div
                className='draggable-container'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {variables.map((variable, index) => {

                  const chart = 'STATE.' + variable.symbol

                  const paneContextObj = returnPaneContextObj(user, chart)
                  const paneThingIsVisible = paneContextObj?.paneThingIsVisible
                  const date = variable?.last_set?.toDate()
                  const time_ago = timeAgo(date)
                  let parentClass = 'entry'
                  if (paneThingIsVisible) {
                    parentClass += ' selected'
                  }
                  // const raw_value = uld.liveData.STATE[variable.symbol]

                  // Pull directly from variables for responsiveness
                  let full_key = variable.symbol
                  if (variable.strategy_name) {
                    full_key = `${variable.strategy_name}.${variable.symbol}`
                  }
                  const raw_value = user.variables[full_key]?.value
                  const formatted_value = valueForTable(chart, raw_value)
                  // consider doing something about currency vs non-currency format

                  return (
                    <Draggable
                      key={variable.symbol}
                      draggableId={variable.symbol}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={parentClass}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                            }}
                            onClick={ async (event) => {
                              event.stopPropagation();
                              setAppTab('')
                              set_open_pane(user, uld, selectedEmptyPane || 1, chart)
                              return
                            }}
                            onContextMenu={(event) => {
                              if (get_contextmenu_off()) return
                              event.preventDefault()
                              const width = 220
                              const clickX = event.clientX + 5
                              const clickY = event.clientY - 10
                              setOpenDropdown(
                                <VariableDropdown
                                  variableObj={variable}
                                  left={clickX}
                                  top={clickY}
                                  width={width}
                                  onClose={() => setOpenDropdown(null)}
                                />
                              )
                            }}
                          >
                            <div className='row'>
                              <Icon
                                icon={'chart-line'}
                                set='sharp-solid'
                                size={12}
                                className={'key-icon'}
                                style={{marginTop: 1.5, marginRight: 10, marginLeft: 0}}
                              />                              
                              <div
                                style={{
                                  maxWidth: 175,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {full_key}
                              </div>
                            </div>
                            <div className='row'>
                              <div>{formatted_value}</div>
                            </div>                            
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext> : <div className='no-entries'>No variables; create via api.</div>}
    </div>
  </>
}