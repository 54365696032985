import axios  from 'axios'
import { useAtom } from 'jotai'
import {
  Input,
  PhoneInput,
  Button,
  showModal,
} from '../reusable'
import { currentUser } from '../../firebase'
import React, { useState, useEffect } from 'react'
import { getConfig } from '../../config'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from '../modals/ErrorModal'
import { CreatePlaidLinkButtonModal } from '../modals/CreatePlaidLinkButtonModal'
import { DeleteAchRelationshipModal } from '../modals/DeleteAchRelationshipModal'
import { TrustedContactModal } from '../modals/TrustedContactModal'
import { BACKGROUND_HOVER, BACKGROUND_HOVER_ALT, ORANGE } from '../../logic/u'

const config = getConfig() as any

export function SettingsTab() {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!loggedInUser) { return null }
  
  return (
    <div className='tab-contents has-subtab'>
      
      {/* Basic info */}
      <div className='generic-content-container'>
        <div className='generic-title'>Basic info</div>
        <div className='generic-content-column wider'>
          <div className='generic-content-item'>
            <div>full_name:</div>
            <div>{loggedInUser.alpacaObject?.identity?.given_name } {loggedInUser.alpacaObject?.identity?.family_name}</div>
          </div>
          <div className='generic-content-item'>
            <div>email_address:</div>
            <div>{loggedInUser.alpacaObject?.contact?.email_address}</div>
          </div>
          <div className='generic-content-item'>
            <div>street_address:</div>
            <div>{loggedInUser.alpacaObject?.contact?.street_address[0]}</div>
          </div>
          <div className='generic-content-item'>
            <div>phone_number:</div>
            <div>{loggedInUser.alpacaObject?.contact?.phone_number?.replace(/\+(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4')}</div>
          </div>
          <div className='generic-content-item'>
            <div>account_status:</div>
            <div>{loggedInUser.alpacaObject?.status}</div>
          </div>
          <div className='generic-content-item'>
            <div>account_number:</div>
            <div>{loggedInUser.alpacaObject?.account_number}</div>
          </div>
        </div>
      </div>


      {/* Banking */}
      <div className='generic-content-container'>
        <div className='generic-title'>Banking relationship</div>
        <div className='generic-content-column wider'>
          <BankingContent />
        </div>
      </div>
      
      
      {/* Trusted contact */}
      <div className='generic-content-container'>
        <div className='generic-title'>Trusted contact</div>
        <div className='generic-content-column wider'>
          <div className='generic-content-item'>
            <div>first_name:</div>
            <div>{loggedInUser?.alpacaObject?.trusted_contact?.given_name || ''}</div>
          </div>
          <div className='generic-content-item'>
            <div>last_name:</div>
            <div>{loggedInUser?.alpacaObject?.trusted_contact?.family_name || ''}</div>
          </div>
          <div className='generic-content-item'>
            <div>email_address:</div>
            <div>{loggedInUser?.alpacaObject?.trusted_contact?.email_address || ''}</div>
          </div>
          <div className='generic-content-item'>
            <div>phone_number:</div>
            <div>{loggedInUser?.alpacaObject?.trusted_contact?.phone_number?.replace(/\+(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4') || ''}</div>
          </div>
          <Button
            display='Change'
            style={{
              marginTop: 10,
              height: '100%',
              width: 100, 
              backgroundColor: ORANGE, 
              color: BACKGROUND_HOVER, 
            }}
            onClick={(e) => {
              setOpenModal(<TrustedContactModal />)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export const BankingContent = () => {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ bankingRelationship, setBankingRelationship ] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const fetchBankingRelationshipData = async () => {
    try {
      setLoading(true)
      const token = await currentUser()?.getIdToken()
      const endpoint = `${config.api_root_url}getAchRelationship`
      const res = await axios.get(endpoint, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      setBankingRelationship(res.data[0])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error)
    }
  };

  useEffect(() => {
    fetchBankingRelationshipData()
  }, [])

  if (!loggedInUser) { return null }

  if (loading) {
    return <div style={{height: 90.5}}>
      <div>Loading...</div>
    </div>
  }
  if (!bankingRelationship?.id) {
    return <div style={{height: 90.5}}>
      <div style={{marginBottom: 29}}>Connect a bank to make deposits/withdrawals to/from Tickerbot promgramitcally and manually.</div>
      <CreatePlaidLinkButtonModal fetchBankingRelationshipData={fetchBankingRelationshipData}  />
    </div>
  }
  return <>
    <div className='generic-content-item'>
      <div>account_owner_name:</div>
      <div>{bankingRelationship?.account_owner_name}</div>
    </div>
    <div className='generic-content-item'>
      <div>bank_account_number:</div>
      <div>{bankingRelationship?.bank_account_number}</div>
    </div>
    <div className='generic-content-item'>
      <div>bank_account_type:</div>
      <div>{bankingRelationship?.bank_account_type}</div>
    </div>
    <div className='generic-content-item'>
      <div>status:</div>
      <div>{bankingRelationship?.status}</div>
    </div>
    <Button
      display='Delete'
      variant='danger'
      style={{marginTop: 10, width: 100}}
      onClick={(e) => {
        setOpenModal(<DeleteAchRelationshipModal fetchBankingRelationshipData={fetchBankingRelationshipData} />)
      }}
    />
  </>
}