

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { backtestsAtom, deploymentsAtom, loggedInUserAtom, openModalAtom, strategiesAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { currentUser, db } from '../../firebase'
import { Backtest } from '../../types/backtest_types'
import axios from 'axios'
import { getConfig } from '../../config'
import { DeploymentDetailsModal } from './DeploymentDetailsModal'
import { Deployment } from '../../types/user_types'
const config = getConfig() as any

interface TakeDownDeploymentModalProps {
  deployment?: Deployment
}

export const TakeDownDeploymentModal = (props: TakeDownDeploymentModalProps) => {
  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [deployments, ] = useAtom(deploymentsAtom)
  const [strategies, ] = useAtom(strategiesAtom)

  if (!user) return null

  const liveDeployment = deployments.find(d => d.id === user.liveDeploymentId)
  const strategiesStringArr = Object.keys(liveDeployment?.liveCode || []).map(id => {
    return strategies.find(s => s.id === id)?.name || '[deleted]'
  })

  return (
    <Modal
      title={'Take down live deployment?'}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className=''>
            The live deployment <span className='bold'>{liveDeployment?.name}</span> will be taken down and its strategies will stop running. This include(s): <span className='bold'>{strategiesStringArr.join(', ')}</span>.
          </div>
        </div>
      ]}
      yesButtonText={'Take down'}
      isDanger
      isButtonLoading={buttonIsLoading}  
      twoStepConfirmation={true}    
      onYes={async () => {
        try {
          setButtonIsLoading(true)                 
          await db.collection('users').doc(user.uid).update({liveDeploymentId: ''})          
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

