/*
Staging vs prod is determined on the basis of window.location.hostname.

When running locally, we point to the staging environment backend. No provision is made
for running the backend manually, although feel free to develop individual endpoints against
the emulation.
*/

const OVERRIDE_ENV = null     // use this for testing purposes

const STAGING_CONFIG = {
  hostname: 'http://staging.tickerbot.io',
  api_root_url: "https://us-central1-tickerbot-14f5c.cloudfunctions.net/",
  websocket_endpoint: "wss://api-staging.tickerbot.io",
  firebase_config: {
    apiKey: "AIzaSyDAfEAK85rfagUiHkQhqZuP0W03yQ4wW6M",
    authDomain: "tickerbot-14f5c.firebaseapp.com",
    projectId: "tickerbot-14f5c",
    storageBucket: "tickerbot-14f5c.appspot.com",
    messagingSenderId: "1048458062050",
    appId: "1:1048458062050:web:40ee1d0394093b3af9d6bb",
    measurementId: "G-FCX3KMPJNT"
  }
}
const PROD_CONFIG = {
  hostname: 'http://app.tickerbot.io',
  api_root_url: "https://us-central1-tickerbot-prod.cloudfunctions.net/",
  websocket_endpoint: "wss://api.tickerbot.io",
  firebase_config: {
    apiKey: "AIzaSyAQuZ93AIpp664gTsiN5_WBRnhdOUtMdZA",
    authDomain: "tickerbot-prod.firebaseapp.com",
    projectId: "tickerbot-prod",
    storageBucket: "tickerbot-prod.appspot.com",
    messagingSenderId: "639591167993",
    appId: "1:639591167993:web:5bd4434d878aae25f42f28",
    measurementId: "G-70B0Z4SY79"
  }
}

export const getConfig = () => {
  const hostname = window.location.hostname
  let env = ''
  if (hostname === 'app.tickerbot.io') {
    env = 'prod'
  } else if (hostname === 'staging.tickerbot.io') {
    env = 'staging'
  } else if (hostname === 'localhost') {
    env = 'staging'
  }

  if (OVERRIDE_ENV) {
    console.log(`OVERRIDING ENVIRONMENT TO ${OVERRIDE_ENV}`)
    env = OVERRIDE_ENV
  }

  if (env === 'staging') {
    return STAGING_CONFIG
  } else if (env === 'prod') {
    return PROD_CONFIG
  }
}