import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {
  getRecentTradingDayEnd,
  getRecentTradingDayStart,
  getRecentWeekEnd,
  getRecentWeekStart
} from '../../logic/u'
import axios from 'axios'
import { currentUser } from '../../firebase'
import { getConfig } from '../../config'
import { format_date_for_crosshairs } from '../TBChart/ChartOverlay'
const config: any = getConfig()

import { get } from 'lodash'

interface MinichartProps {
  ticker: string
  symbolDataIsLoading: boolean
}

export const Minichart: React.FC<MinichartProps> = ({ ticker, symbolDataIsLoading }) => {
  const [selectedRange, setSelectedRange] = useState('1M')
  const [chartData, setChartData] = useState([])

  // Identify bounding dates, load data for each timeframe
  useEffect(() => {
    let startDate: Date = new Date()
    let endDate: Date = new Date()
    let timeframe = '1m'

    switch (selectedRange) {
      case '1D':
        startDate = getRecentTradingDayStart()
        endDate = getRecentTradingDayEnd()
        timeframe = '1m'
        break
      case '1W':
        // startDate = getRecentWeekStart()
        // endDate = getRecentWeekEnd()
        startDate.setDate(startDate.getDate() - 7)
        timeframe = '1h'
        break
      case '1M':
        startDate.setMonth(startDate.getMonth() - 1)
        timeframe = '1d'
        break
      case '6M':
        startDate.setMonth(startDate.getMonth() - 6)
        timeframe = '1d'
        break
      case 'ytd':
        startDate = new Date(new Date().getFullYear(), 0, 1)
        timeframe = '1w'
        break
      case '5Y':
        startDate.setFullYear(startDate.getFullYear() - 5)
        timeframe = '1w'
        break
      case 'all':
        startDate = new Date(2015, 0, 1)
        endDate = new Date()
        timeframe = '1w'
        break
    }
    const startDateISO = startDate.toISOString()
    const endDateISO = endDate.toISOString()

    console.log(`${startDateISO} - ${endDateISO}`)

    // Load data for selected timeframe
    load_data(ticker, timeframe, startDateISO, endDateISO, selectedRange, setChartData)
  }, [selectedRange, ticker])

  // let days_spanned = 1
  // if (chartData.length > 0) {
  //   days_spanned = Math.round((chartData[chartData.length - 1][0] - chartData[0][0]) / (1000 * 60 * 60 * 24))
  // }

  const chartOptions: Highcharts.Options = {
    title: { text: `` },
    series: [
      {
        name: 'Price',
        data: chartData,
        type: 'area',
        color: '#FF8F0E',
        tooltip: {
          valueDecimals: 2
        },
        fillColor: '#FF8F0E11'
      }
    ],
    xAxis: {
      type: 'datetime',
      title: { text: '' },
      labels: {
        formatter: (obj) => {
          const d = new Date(obj.value)
          let ret = ''
          switch (selectedRange) {
            case '1D':
              // "9:30a" / "2p" / etc.
              ret = `${d.getHours() % 12 || 12}${d.getMinutes() === 0 ? '' : `:${d.getMinutes().toString().padStart(2, '0')}`}${d.getHours() < 12 ? 'a' : 'p'}`
              break;
            case '1W':
              // "Mon" / "Tue" / etc.
              ret = d.toLocaleString('en-US', { weekday: 'short' })
              break;
            case '1M':
              // "Jan 5" / "Jan 12" / etc.
              ret = d.toLocaleString('en-US', { month: 'short', day: 'numeric' })
              break;
            case '6M':
              ret = d.toLocaleString('en-US', { month: 'short', day: 'numeric' })
              break;
            case 'ytd':
              ret = d.toLocaleString('en-US', { month: 'short', day: 'numeric' })
              break;
            case '5Y':
              // "Jan 2019" / "Jan 2020" / etc.
              ret = d.toLocaleString('en-US', { month: 'short', year: 'numeric' })
              break;
            case 'all':
              ret = d.toLocaleString('en-US', { month: 'short', year: 'numeric' })
              break;
          }
          return ret
        },
        // format: '{value:%e %b %Y}',
        style: { color: '#999999' }
      }
    },
    yAxis: {
      title: { text: '' },
      labels: {
        format: '${value}',
        style: { color: '#999999' },
        align: 'right',
        x: 40
      },
      gridLineWidth: 0,
      min: chartData && chartData.length > 0
      ? Math.min(
          ...(chartData as [number, number][]).map(([_, price]) => price).filter(price => price != null)
        ) * 0.95
      : undefined
    },
    tooltip: {
      shared: true,
      formatter: function () {
        // Format as "Wed 10/6/24 1:30PM"
        const d = new Date(this.x as number)
        let ret = format_date_for_crosshairs(d.toISOString())
        ret += '<br>'
        ret += '$' + this.y?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
        return ret
      }
    },
    chart: {
      height: 320,
      width: 715,
      backgroundColor: 'transparent',
      spacingRight: 50
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false }
  }



  return (
    <div
      style={{
        // backgroundColor: '#222222',
        width: '100%',
        height: 'calc(100% - 10px)',
        // marginBottom: 10,
        color: '#999999',
        // padding: '10px',
        borderRadius: '5px'
      }}
    >

      {/* Timeframe selector */}
      <div style={{ marginBottom: '8px' }}>
        <button
          style={{
            fontWeight: selectedRange === '1D' ? 'bold' : 'normal',
            color: selectedRange === '1D' ? 'white' : '#999999'
          }}
          onClick={() => setSelectedRange('1D')}
          >
          1D
        </button>

        <button
          style={{
            fontWeight: selectedRange === '1W' ? 'bold' : 'normal',
            color: selectedRange === '1W' ? 'white' : '#999999'
          }}
          onClick={() => setSelectedRange('1W')}
          >
          1W
        </button>

        <button
          onClick={() => setSelectedRange('1M')}
          style={{
            fontWeight: selectedRange === '1M' ? 'bold' : 'normal',
            color: selectedRange === '1M' ? 'white' : '#999999'
          }}
        >
          1M
        </button>

        <button
          onClick={() => setSelectedRange('6M')}
          style={{
            fontWeight: selectedRange === '6M' ? 'bold' : 'normal',
            color: selectedRange === '6M' ? 'white' : '#999999'
          }}
        >
          6M
        </button>

        <button
          onClick={() => setSelectedRange('ytd')}
          style={{
            fontWeight: selectedRange === 'ytd' ? 'bold' : 'normal',
            color: selectedRange === 'ytd' ? 'white' : '#999999'
          }}
        >
          YTD
        </button>

        <button
          onClick={() => setSelectedRange('5Y')}
          style={{
            fontWeight: selectedRange === '5Y' ? 'bold' : 'normal',
            color: selectedRange === '5Y' ? 'white' : '#999999'
          }}
        >
          5Y
        </button>

        <button
          onClick={() => setSelectedRange('all')}
          style={{
            fontWeight: selectedRange === 'all' ? 'bold' : 'normal',
            color: selectedRange === 'all' ? 'white' : '#999999'
          }}
        >
          All
        </button>
      </div>

      {symbolDataIsLoading ? <div style={{height: 'calc(100% - 52px)'}} className='generic-content-container modal-version'>Loading chart...</div> : <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
        containerProps={{
          style: { border: '2px solid #52535e'}
        }}
      />}
    </div>
  )
}


const load_data = async (
  ticker: string,
  timeframe: string,
  start: string,
  end: string,
  selectedRange: string,
  cb: any
) => {
  const token = await currentUser()?.getIdToken()
  const res = await axios.get(`${config.api_root_url}getBarsV5`, {
    params: {
      symbol: ticker,
      timeframe,
      start,
      end,
    },
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  const rawBars = res.data.bars

  // Output format for chart: [[timestamp, price], ...]
  const output: any[] = []
  rawBars.forEach((bar: any) => {
    output.push([Date.parse(bar.t), bar.c])
  })

  // If 1D, pad with empty minutes until EOD
  if (selectedRange === '1D') {
    const eod = getRecentTradingDayEnd()
    const last_bar_ts = output[output.length - 1][0]
    const empty_minutes_needed = Math.floor((eod.getTime() - last_bar_ts) / (1000 * 60))
    for (let i = 0; i < empty_minutes_needed; i++) {
      output.push([last_bar_ts + (i * 60 * 1000), null])
    }
  }

  cb(output)
}