import { useAtom } from 'jotai';
import { loggedInUserAtom, userLiveDocAtom, openModalAtom, backtestsAtom, appTabAtom } from '../../types/global_types';
import { Icon, Modal, ProgressBar } from '../reusable'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db } from '../../firebase';
import { GREEN_PARTIAL, RED_PARTIAL, BACKGROUND, uuid, formatDateForTable, timeAgo } from '../../logic/u';
import { get_contextmenu_off } from '../../logic/u';
import { Backtest } from '../../types/backtest_types';
import { CreateBacktestModal } from '../modals/CreateBacktestModal';
import { BacktestDropdown } from '../dropdowns/BacktestDropdown';

export const NewBacktestsList = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom);
  const [userLiveDoc] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [backtests, setBacktests] = useAtom(backtestsAtom)
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const [appTab, setAppTab] = useAtom(appTabAtom)


  const onDragUpdate = (update) => {
    const { source, destination } = update
    console.log(update)
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination, type } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let backtestsClone = Array.from(backtests) || []
    const [reorderedItem] = backtestsClone.splice(source.index, 1) // remove dragged item
    backtestsClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setBacktests(backtestsClone) // local state

    // Update index for each backtest
    const backtestsRef = db
      .collection('users')
      .doc(user?.uid)
      .collection('backtests');

    const batch = db.batch(); // Firestore batch for atomic updates

    backtestsClone.forEach((backtest, index) => {
      const docRef = backtestsRef.doc(backtest.id);
      batch.update(docRef, { index }); // Update each document with the index from the array
    });

    await batch.commit(); // Commit all updates in a single batch
  }

  // NOTE: if liveData hasn't loaded, sidebar will be missing
  if (!user) return null
  if (!userLiveDoc || !userLiveDoc.liveData) return null

  return (
    <div className='section-body'>
      {openDropdown}
      {!backtests.length ? <div className='no-entries'>No backtests.</div> : null}
      <DragDropContext
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
        key={'modal-content'}
      >
        <div className='droppable-container'>
          <Droppable droppableId={'1'} type='bucket'>
            {(provided, snapshot) => (
              <div
                className='draggable-container'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {backtests.map((backtestObj: Backtest, index) => {

                  let parentClass = 'entry'
                  if (user.selectedBacktestId === backtestObj.id) {
                    parentClass += ' selected'
                  }

                  return (
                    <Draggable
                      key={backtestObj.id}
                      draggableId={backtestObj.id}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={parentClass}
                            title={backtestObj.name}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                            }}
                            onClick={async () => {
                              setAppTab('backtest')
                              await db.collection('users').doc(user.uid).update({selectedBacktestId: backtestObj.id})
                            }}
                            onContextMenu={(event) => {
                              if (get_contextmenu_off()) return
                              event.preventDefault()
                              const width = 220
                              const clickX = event.clientX + 5
                              const clickY = event.clientY - 10
                              setOpenDropdown(
                                <BacktestDropdown
                                  backtestObj={backtestObj}
                                  left={clickX}
                                  top={clickY}
                                  width={width}
                                  onClose={() => setOpenDropdown(null)}
                                />
                              )
                            }}
                          >
                            <div className='column'>
                              <div className={'top-row'}>
                                <div className='row'>                                  
                                  <Icon
                                    icon={'flask-vial'}
                                    set='sharp-solid'
                                    size={12}
                                    className={'key-icon'}
                                    style={{marginTop: 1, marginRight: 10, marginLeft: 0}}
                                  />
                                  <div className='backtest-title'>
                                    {backtestObj.name}
                                  </div>
                                </div>
                                <div className='row'>
                                  {!['completed', 'failed'].includes(backtestObj.status) ? <ProgressBar
                                    progressPercent={((backtestObj.ticks_processed | 0) / (backtestObj.ticks_total | 100)) * 100}
                                    width={75}
                                    style={{marginTop: 3}}
                                  /> : backtestObj.status === 'failed' ? <Icon
                                    hoverText='Failed to create backtest'
                                    className='key-icon'
                                    icon='triangle-exclamation'
                                    size={14}
                                    style={{marginRight: 0, marginTop: 1}}
                                    // onClick={() => {
                                    //   // for effect
                                    // }}
                                  /> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  )
}