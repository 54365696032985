import React, { useState, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import {
  functionExecutingAtom,
  consoleOutputAtom,
  loggedInUserAtom,
  cmdKAtom,
  appTabAtom,
  openModalAtom,
} from '../../types/global_types';
import { Icon } from '../reusable/Icon';
import { Spinner } from '../reusable/Spinner';
import axios from 'axios';

const ANIMATION_LINE_DELAY = 40;

interface CodeConsoleProps {
  fromModal?: boolean
}

export const CodeConsole = (props: CodeConsoleProps) => {
  const [functionExecuting, setFunctionExecuting] = useAtom(functionExecutingAtom);
  const [user] = useAtom(loggedInUserAtom);
  const [consoleOutput, setConsoleOutput] = useAtom(consoleOutputAtom);
  const [visibleOutput, setVisibleOutput] = useState<any>([]);
  const [cmdK] = useAtom(cmdKAtom);
  const containerRef = useRef<any>(null);
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const lastConsoleOutputLengthRef = useRef(0);
  const animationTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const {fromModal} = props

  const kill_code = () => {
    setFunctionExecuting(false);
    cancelTokenSourceRef.current.cancel('Function execution canceled.');
    const newOutput = [...consoleOutput, {text: 'Function execution killed.', className: 'err'}];
    setConsoleOutput(newOutput);
    // @ts-ignore
    window.functionCanceled = true;
    cancelTokenSourceRef.current = axios.CancelToken.source();
  }

  // Cmd + k to clear console
  useEffect(() => {
    if (!cmdK) return;
    setVisibleOutput([]);
  }, [cmdK]);

  // Scroll to bottom when new content is added
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [visibleOutput]);

  // Handle console output changes, including external resets
  useEffect(() => {
    const handleConsoleOutputChange = () => {
      if (consoleOutput.length === 0 && lastConsoleOutputLengthRef.current !== 0) {
        // Console output was reset
        setVisibleOutput([]);
        lastConsoleOutputLengthRef.current = 0;
        if (animationTimeoutRef.current) {
          clearTimeout(animationTimeoutRef.current);
        }
      } else if (consoleOutput.length > lastConsoleOutputLengthRef.current) {
        if (
          lastConsoleOutputLengthRef.current === 0 &&
          visibleOutput.length === 0
        ) {
          // First render or remount, set visibleOutput directly
          setVisibleOutput(consoleOutput);
          lastConsoleOutputLengthRef.current = consoleOutput.length;
        } else {
          // New lines added, animate them
          const newLines = consoleOutput.slice(lastConsoleOutputLengthRef.current);
          const animateNewLines = async () => {
            for (let i = 0; i < newLines.length; i++) {
              animationTimeoutRef.current = setTimeout(() => {
                setVisibleOutput(prev => [...prev, newLines[i]]);
                if (i === newLines.length - 1) {
                  lastConsoleOutputLengthRef.current = consoleOutput.length;
                }
              }, i * ANIMATION_LINE_DELAY);
            }
          };
          animateNewLines();
        }
      }
    };

    handleConsoleOutputChange();

    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, [consoleOutput]);

  const scrollPositionRef = useRef(0);

  useEffect(() => {
    // Restore scroll position on mount
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, []); // Empty dependency array ensures this runs only on mount

  useEffect(() => {
    return () => {
      // Store scroll position on unmount
      if (containerRef.current) {
        scrollPositionRef.current = containerRef.current.scrollTop;
      }
    };
  }, []);



  // Clear animation timeouts on unmount
  useEffect(() => {
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, []);

  if (!user) return null;

  const processed_output: any[] = [];
  visibleOutput.forEach((x: any, i) => {
    if (typeof x.content === 'object') {
      processed_output.push(
        <pre key={i} style={{margin: 0, padding: 0}}>
          {JSON.stringify(x.content, null, 2)}
        </pre>
      );
    } else {
      processed_output.push(<div key={i}>{x.content}</div>);
    }
  })

  return (
    <div className={!fromModal ? 'code-console-body' : 'code-console-body modal-version'} ref={containerRef}>
      {processed_output.map((x: any, i) => (
        <div key={i}>{x}</div>
      ))}
      {visibleOutput.length === 0 && 'Test or run a function to see output here. Press ⌘k to clear.'}
      {functionExecuting && (
        <div style={{marginTop: 2, marginLeft: 2}}>
          <div className='row'>
            <div style={{marginTop: -2}}>
              <Spinner />
            </div>
            <div style={{marginLeft: 15, marginTop: 0}}>Press ⌘ z to kill.</div>
          </div>
        </div>
      )}
    </div>
  );
};