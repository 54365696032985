import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
  selectedEmptyPaneAtom,
  appTabAtom,
} from '../../types/global_types'
import { valueForTable, GREEN_PARTIAL, RED_PARTIAL, returnPaneContextObj, set_open_pane, formatDateForTable, timeAgo, GREEN, get_contextmenu_off, ORANGE, TEXT_GRAY } from '../../logic/u'
import { Icon } from '../reusable'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Chart, Variable, WatchlistObj } from '../../types/user_types'
import { TickerDropdown } from '../dropdowns/TickerDropdown';

export const NewTickersList = () => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)
  const [tickers, setTickers] = useState<WatchlistObj['tickers']>([])
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const tickerDropdownRef = useRef<HTMLDivElement>(null);
  const [ streamingQuotes ] = useAtom(streamingQuotesAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)



  // Render tree
  useEffect(() => {
    setTickers(user?.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers || [])
    // debugger
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let tickersClone = Array.from(tickers)

    // Reorder
    const [reorderedItem] = tickersClone.splice(source.index, 1) // remove dragged item
    tickersClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setTickers(tickersClone) // update local state to smooth transition
    // debugger
    db.collection('users').doc(user?.uid).update({ watchlists: user?.watchlists.map(w =>
      w.id === user.selectedWatchlistIdHomeTab ? { ...w, tickers: tickersClone } : w
    )});
  }

  if (!user) return null
  if (!uld || !uld.liveData) return null

  return <>
    {openDropdown}    
    <div className='section-body'>
      {user.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers.length ? <DragDropContext
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
        key={'modal-content'}
      >
        <div className='droppable-container'>
          <Droppable droppableId={'1'} type='bucket'>
            {(provided, snapshot) => (
              <div
                className='draggable-container'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {tickers.map((t, index) => {
                  if (!Object.keys(user.charts).includes(t)) {
                    return
                  }
                  const ticker: Chart = user.charts[t]
                  const chart = ticker.symbol
                  const chartIcon = 'square-' + ticker.symbol.charAt(0)
                  const paneContextObj = returnPaneContextObj(user, chart)
                  const chartIsVisible = paneContextObj?.paneThingIsVisible
                  
                  let parentClass = 'entry'
                  if (chartIsVisible) {
                    parentClass += ' selected'
                  }
                  return (
                    <Draggable
                      key={ticker.symbol}
                      draggableId={ticker.symbol}
                      index={index}
                    >
                      {(provided, snapshot) => {

                        // Collect and format price change data
                        let price = uld.liveData[ticker.symbol]?.price
                        let day_price_change = uld.liveData[ticker.symbol]?.day_price_change
                        let day_price_change_pc = uld.liveData[ticker.symbol]?.day_price_change_pc
                        if (streamingQuotes[ticker.symbol]) {
                          price = streamingQuotes[ticker.symbol].price
                          day_price_change = streamingQuotes[ticker.symbol].day_price_change
                          day_price_change_pc = streamingQuotes[ticker.symbol].day_price_change_pc
                        }
                        let day_price_change_fmt = valueForTable('day_price_change', day_price_change)
                        let day_price_change_pc_fmt = valueForTable('day_price_change_pc', day_price_change_pc)
                        let tag_string = day_price_change_fmt
                        // let tag_string = day_price_change_fmt + ' [' + day_price_change_pc_fmt + ']'

                        return (
                          <div
                            className={parentClass}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                            }}
                            onClick={ async (event) => {
                              event.stopPropagation();
                              setAppTab('')
                              set_open_pane(user, uld, selectedEmptyPane || 1, chart)
                              return
                            }}
                            onContextMenu={(event) => {
                              if (get_contextmenu_off()) return
                              event.preventDefault()
                              const width = 220
                              const clickX = event.clientX + 5
                              const clickY = event.clientY - 10
                              if (user.selectedWatchlistIdHomeTab) {
                                setOpenDropdown(
                                  <TickerDropdown
                                    symbol={ticker.symbol}
                                    watchlistId={user.selectedWatchlistIdHomeTab}
                                    left={clickX}
                                    top={clickY}
                                    width={width}
                                    onClose={() => setOpenDropdown(null)}
                                    fromLeftPane={true}
                                  />
                                )
                              }
                            }}
                          >
                            <div className='row'>                                 
                              <Icon
                                icon={chartIcon}
                                set='sharp-solid'
                                size={12}
                                className={'key-icon'}
                                style={{marginTop: 1, marginRight: 10, marginLeft: 0}}
                              />
                              <div>
                                {ticker.symbol}
                              </div>
                              {Object.keys(uld.liveData.POSITIONS).includes(ticker.symbol) ? <div
                                className='tag'
                              >
                                POS
                              </div> : null}
                            </div>
                            <div className='row'>
                              <div>{valueForTable('price', price)}</div>
                              <div
                                className={day_price_change >= 0 ? ' green-tag' : ' red-tag'}
                                style={{marginLeft: 10, width: 50, textAlign: 'right'}}
                              >
                                {tag_string}
                              </div>
                            </div>                            
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext> : <div className='no-entries'>No tickers.</div>}
    </div>
  </>
}