import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { format_date_for_crosshairs } from '../TBChart/ChartOverlay'
import { format_date_for_minichart } from './minichart_formatters'

export const BacktestLineChart = ({ data }) => {

  // Use the timestamps directly without parsing
  // const transformedData = dataArray.map(([value, ts]) => [ts, value]);

  // Calculate minutes per datapoint
  let minutes_per_point = 1
  if (data.length > 1) {
    minutes_per_point = (data[1][0] - data[0][0]) / 60000;
  }

  const min_interval = get_min_interval(data);

  const chartOptions = {
    // title: { text: title },
    series: [
      {
        name: '',
        data: data,
        type: 'area',
        color: '#FF8F0E',
        tooltip: {
          valueDecimals: 2,
        },
        fillColor: '#FF8F0E11',
      },
    ],
    xAxis: {
      type: 'datetime',
      title: { text: '' },
      labels: {
        format: '{value:%e %b %Y}',
        style: { color: 'white' },
        formatter: (obj) => {
          // PROBLEM: data is bad from the start. First records should be
          // Thurs the 5th, 11, 12, 13, 14, 15
          // Except data[0][0] itself is correct

          const d = new Date(obj.value)
          // const ret = `${d.getHours() % 12 || 12}${d.getMinutes() === 0 ? '' : `:${d.getMinutes().toString().padStart(2, '0')}`}${d.getHours() < 12 ? 'a' : 'p'}`


          const timestamp = d.getTime()

          const ret = format_date_for_minichart(timestamp, min_interval || '1h')
          return ret
        },
        tickPositioner: function () {
          // Array to store the tick positions
          const ticks: number[] = [];

          // Loop through data points to find the start of each day
          for (let i = 0; i < data.length; i++) {
            const timestamp = data[i][0];
            const date = new Date(timestamp);

            // Check if it's the first data point of the day (e.g., 11:00 EDT)
            if (date.getHours() === 11) {
              ticks.push(timestamp);
            }
          }

          return ticks;
        }
      },
    },
    yAxis: {
      title: { text: '' },
      labels: {
        style: { color: 'white' },
        x: 40,
      },
      gridLineWidth: 0,
      align: 'right',
      min: data && data.length > 0
      ? Math.min(
          ...(data as [number, number][]).map(([_, price]) => price).filter(price => price != null)
        ) * 0.95
      : undefined
    },
    time: {
      timezone: 'America/New_York'
    },
    tooltip: {
      shared: true,
      formatter: function () {
        // Format as "Wed 10/6/24 1:30PM"
        const d = new Date(this.x as number)
        let ret = format_date_for_crosshairs(d.toISOString())
        ret += '<br>'
        ret += '$' + this.y?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })

        return ret
      }
    },
    chart: {
      height: 400,
      backgroundColor: 'transparent',
      spacingRight: 50,
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
  };

  return (
    <div
      style={{
        height: 400,
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
      />
    </div>
  );
};


function get_min_interval(points) {
  if (points.length < 2) return null; // No interval if there's less than 2 points

  let minInterval = Infinity;

  for (let i = 1; i < points.length; i++) {
    const interval = (points[i][0] - points[i - 1][0]) / 60000; // Difference in minutes
    if (interval < minInterval) minInterval = interval;
  }

  // Return appropriate label based on minInterval
  if (minInterval <= 1) return '1m';
  if (minInterval <= 5) return '5m';
  if (minInterval <= 30) return '30m';
  if (minInterval <= 60) return '1h';
  return '1d';
}