import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { TEXT_GRAY, set_open_pane } from '../../logic/u'

interface ChartSettingsDropdownProps {
  left: number
  top: number
  onClose: () => void
  ticker: string
  autoFitMode: boolean
  setAutoFitMode: () => void
  chartMode: string
  setChartMode: (mode: 'candles' | 'line') => void
  paneIndex: number
  isInternal?: boolean
}

export const ChartSettingsDropdown = (props: ChartSettingsDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom);
  const {left, top, onClose, ticker, autoFitMode, setAutoFitMode, chartMode, setChartMode, paneIndex, isInternal} = props

  if (!user) return null

  const volumeHidden = user.charts[ticker]?.volumeHidden ? false : true;
  const demarcateDays = user.charts[ticker]?.demarcateDays ? true : false;

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  const CONTENTS: DropdownItem[] = [
    {
      display: (
        <div className='left'>
          <div style={{width: 15, marginRight: 15}}>
            {autoFitMode ? <Icon
              icon={'check'}
              set='sharp-solid'
              size={13}
              style={{marginRight: 20, marginTop: 1.5}}
              className={''}
            /> : null}
          </div>
          <div>{'Auto-fit'}</div>
        </div>
      ),
      onClick: () => {
        setAutoFitMode()
      }
    },
    {
      display: (
        <div className='left'>
          <div style={{width: 15, marginRight: 15}}>
            {demarcateDays ? <Icon
              icon={'check'}
              set='sharp-solid'
              size={13}
              style={{marginRight: 20, marginTop: 1.5}}
              className={''}
            /> : null}
          </div>
          <div>{'Demarcate days'}</div>
        </div>
      ),
      onClick: () => {
        db.collection('users').doc(user.uid).update({
          [`charts.${ticker}.demarcateDays`]: !demarcateDays,
        });
      }
    },

  ]

  if (!isInternal) {
    CONTENTS.push(
      {
        display: (
          <div className='left'>
            <div style={{width: 15, marginRight: 15}}>
              {volumeHidden ? <Icon
                icon={'check'}
                set='sharp-solid'
                size={13}
                style={{marginRight: 20, marginTop: 1.5}}
                className={''}
              /> : null}
            </div>
            <div>{'Show volume'}</div>
          </div>
        ),
        onClick: () => {
  
          db.collection('users').doc(user.uid).update({
            [`charts.${ticker}.volumeHidden`]: volumeHidden,
          });
        }
      },
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
      {
        display: (
          <div className='left'>
            <div style={{width: 15, marginRight: 15}}>
              {chartMode === 'candles' ? <Icon
                icon={'circle'}
                set='sharp-solid'
                size={8}
                style={{marginTop: 1, marginLeft: 3}}
                className={''}
              /> : null}
            </div>
            <div>{'Candles'}</div>
          </div>
        ),
        onClick: () => {
          setChartMode('candles')
        }
      },
      {
        display: (
          <div className='left'>
            <div style={{width: 15, marginRight: 15}}>
              {chartMode === 'line' ? <Icon
                icon={'circle'}
                set='sharp-solid'
                size={8}
                style={{marginTop: 1, marginLeft: 3}}
                className={''}
              /> : null}
            </div>
            <div>{'Line'}</div>
          </div>
        ),
        onClick: () => {
          setChartMode('line')
        }
      },
    )
  }


  CONTENTS.push(
    {
      display: (
        <div
          style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
        ></div>
      )
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='circle-x'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10}}
          />
          <div>Clear pane</div>
        </div>
      ),
      onClick: () => {
        set_open_pane(user, uld, paneIndex, null);
      }
    },
  )

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={200}
      height={500}
      onClose={() => {onClose()}}
      noArrowKeys={true}
      contents={CONTENTS}
    />
  )
}