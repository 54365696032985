import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { DividerPaneIcon } from '../reusable/DividerPaneIcon'

interface LayoutDropdownProps {
  top: number
  left: number
  onClose: () => void
}

export const LayoutDropdown = (props: LayoutDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const {left, top, onClose} = props

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={200}
      height={500}
      onClose={() => {onClose()}}
      noArrowKeys={true}
      contents={[
        {
          display: (
            <div className='font-size-10 gray-text'>
              Panes
            </div>
          )
        },
        {
          display: (
            <div className={'left space-between'}>
              <div className=''>1 pane</div>
              <DividerPaneIcon
                configuration={'1'}
                style={{marginRight: 2}}
                isSelected={user?.paneSettingsConfig === '1'}
              />
            </div>
          ),
          onClick: () => {
            db.collection('users')
            .doc(user?.uid)
            .update({paneSettingsConfig: '1'})
          }
        },
        {
          display: (
            <div className={'left space-between'}>
              <div className=''>2 panes</div>
              <DividerPaneIcon
                configuration={'2-one-top-one-bottom'}
                style={{marginRight: 2, width: 19.5}}
                isSelected={user?.paneSettingsConfig === '2-one-top-one-bottom'}
              />
            </div>
          ),
          onClick: () => {
            db.collection('users')
            .doc(user?.uid)
            .update({paneSettingsConfig: '2-one-top-one-bottom'})
          }
        },
        {
          display: (
            <div className={'left space-between'}>
              <div className=''>2 panes</div>
              <DividerPaneIcon
                configuration={'2-one-left-one-right'}
                style={{marginRight: 2, width: 19.5}}
                isSelected={user?.paneSettingsConfig === '2-one-left-one-right'}
              />
            </div>
          ),
          onClick: () => {
            db.collection('users')
            .doc(user?.uid)
            .update({paneSettingsConfig: '2-one-left-one-right'})
          }
        },
        {
          display: (
            <div className={'left space-between'}>
              <div className=''>4 panes</div>
              <DividerPaneIcon
                configuration={'4'}
                style={{marginBottom: 2, height: 21, width: 21}}
                isSelected={user?.paneSettingsConfig === '4'}
              />
            </div>
          ),
          onClick: () => {
            db.collection('users')
            .doc(user?.uid)
            .update({paneSettingsConfig: '4'})
          }
        },        
        {
          display: (
            <div className='font-size-10 gray-text'>
              Sidebars
            </div>
          )
        },
        // {
        //   display: (
        //     <div className='left'>
        //       <Icon
        //         icon={user?.leftPaneClosed ? 'eye-slash' : 'eye'}
        //         set='sharp-solid'
        //         size={13}
        //         style={{marginRight: 10}}
        //         className={user?.leftPaneClosed ? 'dropdown-eye-icon' : ''}
        //       />
        //       <div className={user?.leftPaneClosed ? 'gray-text' : ''}>{'Left pane'}</div>
        //     </div>
        //   ),
        //   onClick: () => {
        //     db.collection('users')
        //     .doc(user?.uid)
        //     .update({leftPaneClosed: !user?.leftPaneClosed})
        //   }
        // },
        {
          display: (
            <div className='left'>
              <Icon
                icon={user?.rightPaneClosed ? 'eye-slash' : 'eye'}
                set='sharp-solid'
                size={13}
                style={{marginRight: 10}}
                className={user?.rightPaneClosed ? 'dropdown-eye-icon' : ''}
              />
              <div className={user?.rightPaneClosed ? 'gray-text' : ''}>{'Right pane'}</div>
            </div>
          ),
          onClick: () => {
            db.collection('users')
            .doc(user?.uid)
            .update({rightPaneClosed: !user?.rightPaneClosed})
          }
        },
        {
          display: (
            <div className='left'>
              <Icon
                icon={user?.bottomPaneClosed ? 'eye-slash' : 'eye'}
                set='sharp-solid'
                size={13}
                style={{marginRight: 10}}
                className={user?.bottomPaneClosed ? 'dropdown-eye-icon' : ''}
              />
              <div className={user?.bottomPaneClosed ? 'gray-text' : ''}>{'Bottom pane'}</div>
            </div>
          ),
          onClick: () => {
            db.collection('users')
            .doc(user?.uid)
            .update({bottomPaneClosed: !user?.bottomPaneClosed})
          }
        },
      ]}
    />
  )
}