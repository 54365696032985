import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react'
import {
  userLiveDocAtom,
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types';
import Split from 'react-split';
import { db } from '../../firebase';
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import { v4 as uuidv4 } from 'uuid';
import { BACKGROUND, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, timeAgo, valueForTable } from '../../logic/u';
import { Icon } from '../reusable';
import { WatchlistDropdown } from '../dropdowns/WatchlistDropdown';
import { AddTickersModal } from '../modals/AddTickersModal';
import { CustomGridOverlay } from '../tabs/CustomGridOverlay';
import { TickerModal } from '../modals/TickerModal';
import { TickerDropdown } from '../dropdowns/TickerDropdown';
import { WatchlistObj } from '../../types/user_types';

interface WatchlistTableProps {
  height?: string
  width?: string
  watchlistId?: WatchlistObj['id']
  paneIndex?: number
}

export const WatchlistTable = (props: WatchlistTableProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'index',
      sort: 'asc' as GridSortDirection
    },
  ]);
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!user || !uld) return null 

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    event.preventDefault()
    const width = 220
    const clickX = openLeft? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    const symbol = params.row.ticker
    setOpenDropdown(
      <TickerDropdown
        symbol={symbol}
        left={clickX}
        top={clickY}
        width={width}                      
        onClose={() => setOpenDropdown(null)}
        fromTable={true}
      />
    )
  }

  const watchlistObj = (user.watchlists || []).find(w => w.id === props.watchlistId)
  
  const watchlistRows = watchlistObj?.tickers.map((t, i) => {    
    return {
      ticker: t,
      price: valueForTable(t, uld.liveData[t]?.price),
      day_price_change: valueForTable(t, uld.liveData[t]?.day_price_change),
      day_price_change_pc: valueForTable('_pc', uld.liveData[t]?.day_price_change_pc),
      open: valueForTable(t, uld.liveData[t]?.o),
      high: valueForTable(t, uld.liveData[t]?.h),
      low: valueForTable(t, uld.liveData[t]?.l),
      close: valueForTable(t, uld.liveData[t]?.c),
      volume: valueForTable(t, uld.liveData[t]?.v).split('.')[0],
    }
  }) || []

  let formatted_date = '-'
  let time_ago = '-'
  if (watchlistObj?.updatedAt.toDate?.()) {
    const date =  watchlistObj.updatedAt.toDate()
    time_ago = timeAgo(date)
    formatted_date = formatDateForTable(date)
  }

  let className = 'table-tab'  

  return (
    <div className={className} style={{width: props.width, height: props.height, minHeight: 0, position: 'relative'}}>
      {openDropdown}
      <div className='tab-header'>
        <div className='row'>
          <div>
            {watchlistObj?.name}
          </div>
          <div 
            className='gray-text font-size-10' 
            title={formatted_date}
            style={{marginTop: 4, marginLeft: 10}}  
          >
            last updated {time_ago}
          </div>
        </div>
        <div className='row'>
          <div
            className={watchlistObj?.name === 'positions' ? 'tab-header-btn disabled' : 'tab-header-btn'}
            onClick={() => {
              if (watchlistObj?.name === 'positions') {
                return
              }
              setOpenModal(<AddTickersModal watchlistId={watchlistObj?.id} />)
            }}
          >
            <Icon
              isDisabled={watchlistObj?.name === 'positions'}
              icon='circle-plus'
              className='watchlists-tab-header-icon'
              set='sharp-solid'
              size={12}
              style={{marginTop: 1, marginRight: 5}}
              onClick={() => {
                // for css
              }}
            />
            <div>Edit watchlist</div>
          </div>
          <Icon 
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12, marginTop: 5.5}}
            onClick={(e) => {      
              const rect = e.target.getBoundingClientRect()
              if (rect) {
                const width= 175
                const left = rect.left - width + 10
                const top = rect.bottom
                if (watchlistObj?.id) {
                  setOpenDropdown(<WatchlistDropdown 
                    left={left}
                    top={top}
                    watchlistId={watchlistObj?.id}
                    width={width}
                    onClose={() => setOpenDropdown(null)}
                    paneIndex={props.paneIndex}
                  />)
                }
              }
            }}
          />
        </div>

      </div>
      <div className='table-container'>
        <DataGridPro
          className='clickable-grid'
          rows={watchlistRows}
          columns={[
            {
              field: 'ticker',
              headerName: 'ticker',                
              width: 100,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',                     
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.ticker}
                </div>
              )
            },
            {
              field: 'price',
              headerName: 'price',
              width: 120,
              type: 'number',
              headerAlign: 'right',
              align: 'right',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.price}
                </div>
              )
            },
            {
              field: 'day_price_change',
              headerName: 'day_price_change',
              width: 170,
              type: 'number',
              headerAlign: 'right',
              align: 'right',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.day_price_change}
                </div>
              )
            },
            {
              field: 'day_price_change_pc',
              headerName: 'day_price_change_pc',
              width: 185,
              type: 'number',
              headerAlign: 'right',
              align: 'right',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    // padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.day_price_change_pc}
                </div>
              )
            },
            {
              field: 'open',
              headerName: 'open',
              width: 120,
              type: 'number',
              headerAlign: 'right',
              align: 'right',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    // padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.open}
                </div>
              )
            },
            {
              field: 'high',
              headerName: 'high',
              width: 120,
              type: 'number',
              headerAlign: 'right',
              align: 'right',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    // padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.high}
                </div>
              )
            },
            {
              field: 'low',
              headerName: 'low',
              width: 120,
              type: 'number',
              headerAlign: 'right',
              align: 'right',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    // padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.low}
                </div>
              )
            },
            {
              field: 'close',
              headerName: 'close',
              width: 120,
              type: 'number',
              headerAlign: 'right',
              align: 'right',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    // padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.close}
                </div>
              )
            },
            {
              field: 'volume',
              headerName: 'volume',
              width: 120,
              type: 'number',
              headerAlign: 'right',
              align: 'right',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div 
                  style={{
                    width: '100%',
                    // padding: 10,
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {                    
                    handleContextMenuClick(event, params)
                  }}                
                >
                  {params.row.volume}
                </div>
              )
            },
          ]}
          getRowId={(row) => uuidv4()}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: () => <CustomGridOverlay text='No tickers.' />,
          }}
          sx={{
            ...tableSX,
          }}
          // pagination
          // paginationMode='client'
          // rowsPerPageOptions={[10, 25, 50, 100, 500]}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total tickers:',
          }}
          onRowClick={(params, event) => {
            setOpenModal(<TickerModal symbol={params.row.ticker} />)            
            // setOpenModal(<PositionDetailsModal symbol={params.row.symbol} />)            
          }}
          hideFooter={true}
        />
      </div>
    </div>
  )
}
