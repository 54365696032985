import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Button } from '../../reusable'
import { db, currentUser } from '../../../firebase'
import * as admin from 'firebase-admin'
import { loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  SignUpStage,
  AlpacaAccountStatus,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import {
  kycDocTable,
} from '../../../logic/u'
import axios from 'axios'
import { getConfig } from '../../../config'
import { ErrorModal } from '../../modals/ErrorModal'
import { FileInput } from '../../reusable/FileInput'
const config: any = getConfig()

export const AlpacaReviewStep = () => {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const [accountStatus, setAccountStatus] = useState<AlpacaAccountStatus | ''>('')
  const [base64Strings, setBase64Strings] = useState<string[]>([])
  const [mimeTypes, setMimeTypes] = useState<string[]>([])
  const [documentType, setDocumentType] = useState<string>('')

  const nextSignUpStage: SignUpStage = 'BankInfo'

  useEffect(() => {
    setButtonEnabled(!!base64Strings.length);
  }, [base64Strings]);

  useEffect(() => {
    const getAccountUrl = `${config.api_root_url}getAccount`
    const getAccount = async () => {
      const token = await currentUser()?.getIdToken()
      const fetchedUser = await axios.get(getAccountUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      
      const alpacaAccountStatus = loggedInUser?.alpacaAccountStatus
      const fetchedStatus = fetchedUser?.data?.status
      const fetchedKYCResult = fetchedUser?.data?.kyc_results
      
      if (fetchedStatus !== alpacaAccountStatus) {
        db.collection('users').doc(loggedInUser?.uid).update({ 
          alpacaAccountStatus: fetchedStatus,
          // alpacaAccountStatusLastUpdated: admin.firestore.FieldValue.serverTimestamp(),
          alpacaKYCResult: fetchedKYCResult
        })
      }
    }

    getAccount()
  }, [])

  useEffect(() => {
    if (!loggedInUser) return
    console.log(loggedInUser.alpacaAccountStatus)
    const status = loggedInUser.alpacaAccountStatus || 'SUBMITTED'
    setAccountStatus(status)

    if (status === 'ACTION_REQUIRED' || status === 'APPROVAL_PENDING') {
      const reason = loggedInUser.alpacaKYCResult?.indeterminate || loggedInUser.alpacaKYCResult?.reject
      const docType = kycDocTable[reason]
      setDocumentType(docType)
    }

    if (status === 'ACTIVE') {
      db.collection('users').doc(loggedInUser?.uid).update({
        signUpStage: nextSignUpStage,
      })
    }
  }, [loggedInUser])

  let submittedStatusClassName = 'gray-text'
  let approvedStatusClassName = 'gray-text'
  let activeStatusClassName = 'gray-text'

  if (accountStatus === 'SUBMITTED') {
    submittedStatusClassName = 'orange-text'
  }
  if (accountStatus === 'APPROVED') {
    submittedStatusClassName = 'orange-text'
    approvedStatusClassName = 'orange-text'
  }
  if (accountStatus === 'ACTIVE') {
    submittedStatusClassName = 'orange-text'
    approvedStatusClassName = 'orange-text'
    activeStatusClassName = 'orange-text'
  }

  if (!loggedInUser) return null

  if (['SUBMISSION_FAILED', 'REJECTED'].includes(accountStatus)) {
    return (
      <div className='signup-container'>
        <div className='signup-form-container'>
          <div className='signup-context-title center'>
            Something went wrong!
          </div>
          <div className='signup-context-subtitle center'>
            Your submission failed or was rejected. Please contact us for help.
          </div>
        </div>
      </div>
    )
  }

  if (accountStatus === 'ACTION_REQUIRED') {
    return (
      <div className='signup-container'>
        <div className='signup-form-container'>
          <div className='signup-context-title center'>
            Action required!
          </div>

          {/* No doc in the lookup table scenario */}
          {!documentType ? <div className='signup-context-subtitle center'>
            Something went wrong. Please contact us for help.
          </div> : <>
          {/* doc in the lookup table scenario */}
            <div className='signup-context-subtitle center'>
              {'Please submit: ' + documentType}
            </div>
            <div className='row width-full'>
              <FileInput
                label={''}
                onChange={async (val) => {
                  const files = val.target.files;                
                  const encodeFileToBase64 = async (file: File): Promise<{ base64String: string; mimeType: string }> => {      
                    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB limit
                    const reader = new FileReader();
                    return new Promise((resolve, reject) => {
                      reader.onload = () => {
                        const base64String = reader.result as string;
                        const fileSizeInBytes = base64String.length * 0.75; // Base64 encoding increases the file size by 1.33 times
            
                        if (fileSizeInBytes > maxSizeInBytes) {
                          reject(new Error(`A file exceeds the 10MB limit.`));
                        } else {
                          const mimeType = file.type;
                          resolve({ base64String: base64String.split(',')[1], mimeType });
                        }
                      };
                      reader.onerror = (error) => reject(error);
                      reader.readAsDataURL(file);
                    });
                  };

                  try {

                    let base64Strings: string[] = []
                    let mimeTypes: string[] = []
                    for (let i = 0; i < files.length; i++) {
                      const file = files[i]
                      const { base64String, mimeType } = await encodeFileToBase64(file)     
                      base64Strings.push(base64String)
                      mimeTypes.push(mimeType)
                    }       
                    setBase64Strings(base64Strings)
                    setMimeTypes(mimeTypes)
                  } catch (error) {
                    console.error('Error encoding files to base64:', error);
                  }
                }}
              />
            </div>
            <Button
              display={'Submit'}
              className={'margin-top-25'}
              style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
              isDisabled={!buttonEnabled || buttonIsLoading}
              isLoading={buttonIsLoading}
              onClick= {async () => {
                try {
                  setButtonIsLoading(true)

                  for (let i = 0; i < base64Strings.length; i++) {
                    const data = {
                      documentType: documentType,
                      content: base64Strings[i],
                      mimeType: mimeTypes[i],
                    }
                    const token = await currentUser()?.getIdToken()
                    const url = `${config.api_root_url}uploadDocument`
                    await axios.post(url, data, {
                      headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                      }
                    })
                  }

                  setButtonIsLoading(false)
                  setAccountStatus('SUBMITTED')
                } catch (err) {
                  setButtonIsLoading(false)
                  setOpenModal( <ErrorModal errorMessage={err.response.data}/> )
                }
              }}
            />
            <div className='signup-context-subtitle center gray-text margin-top-40' style={{fontSize: 11, fontStyle: 'italic'}}>
              Our team will review your documents, which typically takes one business day. Please check here for status updates.
            </div>
          </>}
        </div>
      </div>
    )
  }

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title center'>
          Verifying your identity...
        </div>
        <div className='signup-context-subtitle center'>
          This may take several minutes. Please refresh the page if you are not automatically redirected.
        </div>
        <div className='row width-full space-between'>
          <div className={submittedStatusClassName}>
            Submitted
          </div>
          <div className={submittedStatusClassName}>
            {'->'}
          </div>
          <div className={approvedStatusClassName}>
            Approved
          </div>
          <div className={approvedStatusClassName}>
            {'->'}
          </div>
          <div className={activeStatusClassName}>
            Active
          </div>
        </div>
      </div>
    </div>
  )
}