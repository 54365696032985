import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
  selectedEmptyPaneAtom,
  appTabAtom,
} from '../../types/global_types'
import { valueForTable, GREEN_PARTIAL, RED_PARTIAL, returnPaneContextObj, set_open_pane, formatDateForTable, timeAgo } from '../../logic/u'
import { Icon } from '../reusable';

interface NewBalancesListProps {
  category?: 'unrealized_return' | 'realized_return' | 'misc'
}

export const NewBalancesList = (props: NewBalancesListProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)


  if (!user) return null
  if (!uld) return null

  let balancesArr = [
    {
      path: 'cash',  // from alpaca
      description: 'Cash balance',
    },
    // {
    //   path: 'long_mkt_value',  // from alpaca
    //   description: 'Porftfolio value of all long positions',
    // },
    {
      path: 'cost_basis', // from alpaca
      description: 'Portfolio cost basis',
    },
    {
      path: 'mkt_value', // tb
      description: 'Portfolio value of all positions',
    },
    {
      path: 'equity', // from alpaca
      description: 'Total account value (cash + mkt_value)',
    },
    {
      path: 'day_equity_change', // from alpaca
      description: 'Equity change since yesterday',
    },
    {
      path: 'day_equity_change_pc', // from alpaca
      description: 'Equity percent change since yesterday',
    },
    {
      path: 'pl', // tb
      description: 'Unrealized + realized profit/loss',
    },
    {
      path: 'pl_pc', // tb
      description: 'Unrealized + realized profit/loss judged against net deposits',
    },
    {
      path: 'day_pl', // tb
      description: 'Unrealized + realized profit/loss since yesterday',
    },
    {
      path: 'day_pl_pc', // tb
      description: 'Unrealized + realized profit/loss percent change since yesterday',
    },
  ]

  if (props.category === 'unrealized_return') {
    balancesArr = [
      {
        path: 'unrealized_pl', // from alpaca
        description: 'Portfolio unrealized profit/loss',
      },
      {
        path: 'unrealized_pl_pc', // from alpaca
        description: 'Portfolio unrealized profit/loss percent change',
      },
      {
        path: 'day_unrealized_pl', // from alpaca
        description: 'Portfolio unrealized profit/loss since yesterday',
      },
      {
        path: 'day_unrealized_pl_pc', // tb
        description: 'Portfolio unrealized profit/loss percent change since yesterday',
      },
    ]
  }
  
  if (props.category === 'realized_return') {
    balancesArr = [
      {
        path: 'realized_pl',  // tb
        description: 'Realized profit/loss',
      },
      {
        path: 'realized_pl_pc', // tb
        description: 'Realized profit/loss percent change',
      },
      {
        path: 'day_realized_pl',  // tb
        description: 'Realized profit/loss since yesterday',
      },
      {
        path: 'day_realized_pl_pc',  // tb
        description: 'Realized profit/loss percent change since yesterday',
      },
    ]
  }
  
  if (props.category === 'misc') {
    balancesArr = [      
      {
        path: 'net_deposits',  // tb
        description: 'Total deposits minus total withdrawals',
      },      
      {
        path: 'cash_on_orders',
        description: 'Cash tied up in open orders',
      },
      {
        path: 'buying_pwr',  // from alpaca
        description: 'Cash buying power',
      },
      {
        path: 'daytrading_buying_pwr', // from alpaca
        description: 'Buying power for day trades',
      },
      {
        path: 'daytrade_count', // alpaca
        description: 'The current number of daytrades that have been made in the last 5 trading days inclusive of today',
      },
    ]
  }

  return (
    <div className='section-body'>
      {balancesArr.map(balance => {

        const chart = 'BALANCES.' +  balance.path
        const paneContextObj = returnPaneContextObj(user, chart)
        const chartIsVisible = paneContextObj?.paneThingIsVisible
        let divClass = 'entry'
        if (chartIsVisible) {
          divClass += ' selected'
        }
       
        return (
          <div
            className={divClass}
            onClick={() => {
              setAppTab('')
              set_open_pane(user, uld, selectedEmptyPane || 1, chart)
            }}
          >
            <div className='row'>
              <Icon
                icon={'chart-line'}
                set='sharp-solid'
                size={12}
                className={'key-icon'}
                style={{marginTop: 1.5, marginRight: 10, marginLeft: 0}}
              />
              <div title={balance.description}>
                {balance.path}
              </div>            
            </div>
            <div className='row'>
              <div>{valueForTable(balance.path, uld.liveData.BALANCES[balance.path])}</div>
            </div>            
          </div>
        )
      })}
    </div>
  )
}