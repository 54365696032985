import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  strategiesAtom
} from '../../types/global_types'
import { getConfig } from '../../config'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { TickerModal } from '../modals/TickerModal'
import { Backtest } from '../../types/backtest_types'
import { CustomGridOverlay } from '../tabs/CustomGridOverlay'
const config = getConfig() as any

interface BacktestStrategyTabProps {
  backtest: Backtest | null
}
export const BacktestStrategyTab = (props: BacktestStrategyTabProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const { backtest } = props
  
  if (!user || !uld || !backtest) return null
  
  const strategy = backtest.strategy || {}
  const name = strategy?.name || '-'
  const code = strategy?.code || '-'
  
  return (
    <div className='tab-contents has-subtab'>
      <div className='generic-content-container min-height'>
        <div style={{marginBottom: 7, fontWeight: 'bold'}}>{name}</div>
        <pre style={{margin: 0}}><code>
          {code}
        </code></pre>
      </div>
    </div>
  )
}