import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react'
import {
  userLiveDocAtom,
  loggedInUserAtom,
  appTabAtom,
} from '../../types/global_types';
import { db } from '../../firebase';
import { formatDateForTable } from '../../logic/u';
import { Icon } from '../reusable';
import { BacktestDropdown } from '../dropdowns/BacktestDropdown';
import { Backtest } from '../../types/backtest_types';
import { BacktestSummaryTab } from './BacktestSummaryTab';
import { BacktestStrategyTab } from './BacktestStrategyTab';
import { BacktestWatchlistsTab } from './BacktestWatchlistsTab';
import { PositionsTable } from '../tables/PositionsTable';
import { BalancesTable } from '../tables/BalancesTable';
import { OrdersTable } from '../tables/OrdersTable';
import { TransfersTable } from '../tables/TransfersTable';
import { LogsTable } from '../tables/LogsTable';

const SUBTABS = [
  {display: 'Summary', value: 'summary'},
  {display: 'Strategy', value: 'strategy'},
  {display: 'Watchlists', value: 'watchlists'},
  {display: 'Positions', value: 'positions'},
  // {display: 'Balances', value: 'balances'},
  {display: 'Orders', value: 'orders'},
  {display: 'Transfers', value: 'transfers'},
  {display: 'Logs', value: 'logs'},
]

export const BacktestTab = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [backtest, setBacktest] = useState<Backtest | null>(null); // Initialize state
  const [ subTab, setSubTab ] = useState('summary')
  const [appTab, setAppTab] = useAtom(appTabAtom)

  let subTabContent: any = null

  switch (subTab) {
    case 'summary':
      subTabContent = <BacktestSummaryTab backtest={backtest} />
      break
    case 'watchlists':
      subTabContent = <BacktestWatchlistsTab backtest={backtest} />
      break
    case 'strategy':
      subTabContent = <BacktestStrategyTab backtest={backtest} />
      break
    case 'positions':
      subTabContent = <PositionsTable backtest={backtest} />
      break
    case 'balances':
      subTabContent = <BalancesTable backtest={backtest} />
      break
    case 'orders':
      subTabContent = <OrdersTable backtest={backtest}/>
      break
    case 'transfers':
      subTabContent = <TransfersTable backtest={backtest}/>
      break
    case 'logs':
      subTabContent = <LogsTable backtest={backtest}/>
      break
  }

  useEffect(() => {
    if (!user || !user.selectedBacktestId) return; // Ensure user and backtest ID are present

    const backtestRef = db
      .collection('users')
      .doc(user.uid)
      .collection('backtests')
      .doc(user.selectedBacktestId);

    const unsubscribe = backtestRef.onSnapshot(doc => {
      if (doc.exists) {
        const backtestObj = { id: doc.id, ...doc.data() } as Backtest;
        setBacktest(backtestObj); // Update state with backtest data
      } else {
        console.log("No such backtest found!");
        setBacktest(null); // Set state to null if the document does not exist
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [user]);

  if (!user || !uld) return null

  let backtestsColClass = 'backtests-col' 

  if (!backtest) {
    return <div className={backtestsColClass}>
      <div className='tab-header'>
        <div className='row'>
          <Icon
            icon='arrow-left'
            set='sharp-solid'
            size={16}
            style={{marginTop: 0}}
            className='margin-right-10'
            onClick={() => {
              setAppTab('')
            }}
          />
          <div className='backtest-tab-name'>
            Create or choose a backtest...
          </div>
        </div>
      </div>
    </div>
  }

  const startDate = formatDateForTable(backtest?.start_time_iso).split(' ')[0]
  const endDate = formatDateForTable(backtest?.end_time_iso).split(' ')[0]
  const dateString = startDate + ' - ' + endDate

  return (
    <div className={backtestsColClass} >
      {openDropdown}
      <div className='tab-header'>
        <div className='row'>
          <Icon
            icon='arrow-left'
            set='sharp-solid'
            size={16}
            style={{marginTop: 0}}
            className='margin-right-10'
            onClick={() => {
              setAppTab('')
            }}
          />
          <div className='backtest-tab-name'>
            {backtest?.name}
          </div>
          {backtest.status === 'completed' ? <div
            className='gray-text font-size-10'
            title={dateString}
            style={{marginTop: 3, marginRight: 10}}
          >
            {dateString} • viewing data as of {endDate} (final output)
          </div> : null}
        </div>
        <div className='row'>
          <Icon
            icon='gear'
            set='regular'
            size={14}
            style={{marginLeft: 12, marginTop: 2}}
            onClick={(e) => {
              const rect = e.target.getBoundingClientRect()
              if (rect) {
                const width= 175
                const left = rect.left - width + 10
                const top = rect.bottom
                setOpenDropdown(<BacktestDropdown
                  left={left}
                  top={top}
                  backtestObj={backtest}
                  width={width}
                  onClose={() => setOpenDropdown(null)}
                />)
              }
            }}
          />
        </div>

      </div>
      {backtest.status === 'completed' ? <>
        <div className='subtab-header'>
          {SUBTABS.map((s) => {
            let className = 'subtab'
            if (s.value === subTab) {
              className += ' selected'
            }
            return (
              <div
                className={className}
                onClick={() => setSubTab(s.value)}
              >
                {s.display}
              </div>
            )
          })}
        </div>
        {subTabContent}
      </> : <NoResultsComponent backtest={backtest} />}
    </div>
  )
}

interface NoResultsComponentProps {
  backtest: Backtest
}
const NoResultsComponent = (props: NoResultsComponentProps) => {
  let explanation = ''
  if (props.backtest.status === 'failed') {
    explanation = 'has failed.'
  } else {
    explanation = `is in progress.`
  }

  return (
  <div className='tab-contents'>
    {props.backtest.status !== 'running' ? <div className='gray-text' style={{textAlign: 'center', marginTop: 100}}>
      No results. This backtest {explanation}
    </div> : null}

    {/* Running state */}
    {props.backtest.status === 'running' ? <div
      className='gray-text'
      style={{textAlign: 'center', marginTop: 100}}
    >
      {`Running backtest, ${props.backtest.ticks_processed} of ${props.backtest.ticks_total} ticks processed...`}
    </div>
    : null}

  </div>
  )
}
